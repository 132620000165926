import React, { useState, useEffect } from 'react';
import { Modal, Button, FormControl } from 'react-bootstrap';
import './Imagemodel.css'; 

const ImageModel = ({ showModal, imagePreview, Imagemessagesend, setShowmodel }) => {
  const [fileType, setFileType] = useState(null); // Store file type (image or PDF)
  const [fileUrl, setFileUrl] = useState(null);   // Store file URL for preview
  const [textMessage, setTextMessage] = useState(''); // Store text message

  useEffect(() => {
    if (imagePreview instanceof File) {
      const objectURL = URL.createObjectURL(imagePreview);
      setFileType(imagePreview.type); // Get the file type from the file
      setFileUrl(objectURL);
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [imagePreview]);

  const handleImageSend = async () => {
    Imagemessagesend();  // Send the image or file with text
  };

  return (
    <Modal 
      show={showModal} 
      centered 
      onHide={() => setShowmodel(false)} 
      className="image-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body className="image-modal-body">
        {fileUrl ? (
          <div className="file-preview-container text-center">
            {fileType.includes('image') ? (
              <img 
                src={fileUrl} 
                alt="Selected" 
                className="image-preview" 
                width={200} 
                height={200} 
              />
            ) : fileType.includes('pdf') ? (
              <iframe 
                src={fileUrl} 
                width="100%" 
                height="300px"
                title="PDF Preview"
                className="pdf-preview-iframe"
              />
            ) : (
              <div className="upload-prompt">
                <p>Unsupported file format.</p>
              </div>
            )}
          </div>
        ) : (
          <div className="upload-prompt">
            <p>No file selected.</p>
          </div>
        )}
      </Modal.Body>



      <Modal.Footer className="image-modal-footer text-end d-flex">
  
        <Button 
          style={{
            backgroundColor: '#7e66bc',
            color: 'white',
            borderRadius: '50%',
            padding: '10px',
            fontSize: '20px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'background-color 0.3s'
          }}
          onClick={handleImageSend} 
          className="btn" 
          disabled={!fileUrl}
        >
          <i className="fa fa-paper-plane"></i>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModel;
