import React, { useEffect, useState, useContext } from 'react';
import FetchData from '../../fetchapi/Apifetch';
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Utills/UserContext';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton CSS
import "../Screenerpage/screenerstock.css"
import { ToastContainer, toast } from 'react-toastify';
const Iposcreen = () => {
  const navigate = useNavigate();
  const profiledetails = JSON.parse(localStorage.getItem("email"));
  const { theme, setTheme } = useContext(UserContext);
  const [Stockscreener, setStockscreener] = useState('');
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [filterdata, setFilterdata] = useState(false); // Add loading state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isTab, setisTab] = useState(window.innerWidth <= 900);
  const [industry, setindustry] = useState('');
  const [overallindustry, setoverallindustry] = useState('');
  const [apistockId, setApistockId] = useState('');

  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const [selectedsector, setSelectedsector] = useState([]);
  const [selectedexchange, setSelectedexchange] = useState([]);
  const [selectedstatus, setSelectedstatus] = useState([]);




  const NextPage = () => {
    setPage(page + 1);
  };
  const PreviousPage = () => {
    setPage(page - 1);
  };
  const NextPageall = () => {
    setPage(paginationInfo?.totalPages);  // Go to the last page
  };

  const PreviousPageall = () => {
    setPage(1);  // Go to the first page
  };

  useEffect(() => {
      Stockscreenerlist();
  }, [page]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);  // Adjust the width threshold as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the handler on mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);




  useEffect(() => {
    const handleSelectedSector = async () => {
      if (selectedsector.length == 0) {
        setSelectedIndustries('');
        await setindustry(overallindustry); // Assuming setindustry is a state setter
      }
    };

    handleSelectedSector();
  }, [selectedsector]);
  useEffect(() => {
    console.log('selectedexchange', selectedexchange)
  }, [selectedexchange]);



  const Stockscreenerlist = async () => {
    
    window.scrollTo(0, 0);
    setLoading(true); // Set loading to true before fetching
    const res = await FetchData(`getIopscreen?page=${page}&limit=10`, 'GET', null, true, false);
    if (res) {
      setStockscreener(res.data.data);
      setPaginationInfo(res.data.pagination);
    }
    setLoading(false); // Set loading to false after fetching
  };


  const Imageview = ({ item }) => {
    const [imageError, setImageError] = useState(false);
    const stock = item?.company_name;

    const splitStock = (stock) => {
      if (!stock) return '';
      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase();
    };

    return (
      <div>
        <div
          style={{
            height: "40px",
            width: "40px",
            // borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: theme ? "1.5px solid #7E66BC" : "1.5px solid #5b298c",
            backgroundColor: "#f0edf7",
            color: 'black',
            fontSize: "16px",
            fontWeight: 500,
         
          }}
        >
          {splitStock(item?.company_name)}
        </div>
      </div>
    );
  };



  const styles = {
    pageLink: {
      borderRadius: "50%",
      width: "35px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: theme ? "2px solid #ddd" : '1px solid #949393',
      color: theme ? "#fff" : 'black',
      backgroundColor: "transparent",
      marginRight: "5px",
      cursor: "pointer",
    },
    activePage: {
      borderRadius: "50%",
      width: "35px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#7E66BC", // Active background color
      color: "#fff", // Active text color
      border: "none",
      marginRight: "5px",
    },
  };


  const PaginationIndex = () => {
    const totalPageNumbersToShow = 5; // Number of pagination numbers to show

    const getPaginationNumbers = () => {
      const totalPages = paginationInfo?.totalPages || 1;
      const paginationNumbers = [];

      if (totalPages <= totalPageNumbersToShow) {
        // Case 1: Show all pages if total pages <= 5
        for (let i = 1; i <= totalPages; i++) {
          paginationNumbers.push(i);
        }
      } else {
        // Always show the first page (1)
        paginationNumbers.push(1);

        const halfWindow = Math.floor((totalPageNumbersToShow - 3) / 2); // Adjust for first and last pages

        let startPage = Math.max(2, page - halfWindow);  // Middle page range starts from 2
        let endPage = Math.min(totalPages - 1, page + halfWindow);  // Exclude the last page initially

        // Adjust start/end if too close to the boundaries
        if (page <= halfWindow + 2) {
          startPage = 2;
          endPage = 4;
        } else if (page >= totalPages - halfWindow - 2) {
          startPage = totalPages - 3;
          endPage = totalPages - 1;
        }

        // Show ellipsis if not adjacent to page 2
        if (startPage > 2) {
          paginationNumbers.push("...");
        }

        // Add middle pages
        for (let i = startPage; i <= endPage; i++) {
          paginationNumbers.push(i);
        }

        // Show ellipsis if not adjacent to the second last page
        if (endPage < totalPages - 1) {
          paginationNumbers.push("...");
        }

        // Always show the last page
        paginationNumbers.push(totalPages);
      }

      return paginationNumbers;
    };

    return (
      <div className="mx-auto my-4 text-white pagination-container">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center" style={{ marginLeft: "10px" }}>
            {/* Previous Button */}
            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <img
                onClick={PreviousPageall}
                className="pagination-img"
                src={theme ? "/images/icons/leftdouble.png" : "/images/icons/darkleftdouble.png"}
                alt="Previous All"
              />
            </li>

            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <img
                onClick={PreviousPage}
                className="pagination-img"
                src={theme ? "/images/icons/left.png" : "/images/icons/darkleft.png"}
                alt="Previous"
              />
            </li>

            {/* Page Number Buttons */}
            {getPaginationNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${page === pageNumber ? "active" : ""}`}
              >
                {pageNumber === "..." ? (
                  <span className="me-1 ms-1" style={{ backgroundColor: "none", color: theme ? "#fff" : "#000" }}>...</span>
                ) : (
                  <button
                    className="page-link"
                    onClick={() => setPage(pageNumber)}
                    style={page === pageNumber ? styles.activePage : styles.pageLink}
                  >
                    {pageNumber}
                  </button>
                )}
              </li>
            ))}

            {/* Next Button */}
            <li className={`page-item ${page === paginationInfo?.totalPages ? "disabled" : ""}`}>
              <img
                onClick={NextPage}
                className="pagination-img"
                src={theme ? "/images/icons/right1.png" : "/images/icons/darkright.png"}
                alt="Next"
              />
            </li>

            <li className={`page-item ${page === paginationInfo?.totalPages ? "disabled" : ""}`}>
              <img
                onClick={NextPageall}
                className="pagination-img"
                src={theme ? "/images/icons/rightdouble.png" : "/images/icons/darkrightdouble.png"}
                alt="Next All"
              />
            </li>
          </ul>
        </nav>

        {/* Pagination Info */}

      </div>
    );
  };


  return (
    <>
      <Navbar />
      <ToastContainer />
      <div className='container-fluid' style={{ width: '95%', minHeight: "85vh" }}>
        <div className='row justify-content-center'>
          <div className='card col-lg-12 col-md-12 col-sm-12' style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
            <div className='mt-2 p-3'>
              <div>
                <h5 className={`fs-4  ${theme ? 'text-white' : 'text-dark'}`}>IPO<span style={{ color: theme ? '#7E66BC' : '#5b298c'  }}>(Initial Public Offer)</span></h5>
              </div>
              <div className='d-flex justify-content-between align-items-center pt-3 pb-3' style={{ borderRadius: '10px', flexDirection: isTab ? 'column' : 'row' }}>

                <div className="d-flex" style={{ gap: '5px', flexDirection: isMobile ? 'column' : 'row' }}>


                  {/* Exchange Dropdown */}
             
                  <div className="">
                      <div
                        className=" d-flex justify-content-between align-items-center text-center"
                        style={{
                          backgroundColor: '#5b298c',
                          color:"#fff",
                          border: `1px solid ${theme ? "#333" : "#ddd"}`,
                          padding: '6px 10px',
                          borderRadius: '8px',
                          cursor: 'pointer'
                        }}
                      >
                        <span style={{fontSize:"12px"}}>Open Now</span>
                      </div>
                  
                    </div>
                    <div className="">
                      <div
                        className=" d-flex justify-content-between align-items-center"
                        style={{
                          backgroundColor: theme ? "#2C2C2C" : "#fff",
                          color: theme ? "#fff" : "#333",
                          border: `1px solid ${theme ? "#333" : "#ddd"}`,
                          padding: '6px 10px',
                          borderRadius: '8px',
                          cursor: 'pointer'
                        }}
                    
                      >
                        <span style={{fontSize:"12px"}}>Upcoming</span>
                      </div>
                  
                    </div>
                    <div className="">
                      <div
                        className=" d-flex justify-content-between align-items-center"
                        style={{
                          backgroundColor:theme ? "#2C2C2C" : "#fff",
                          color: theme ? "#fff" : "#333",
                          border: `1px solid ${theme ? "#333" : "#ddd"}`,
                          padding: '6px 10px',
                          borderRadius: '8px',
                          cursor: 'pointer'
                        }}
                     
                     
                      >
                        <span style={{fontSize:"12px"}}>Listed IPOs</span>
                      </div>
                  
                    </div>
                    <div className="">
                      <div
                        className=" d-flex justify-content-between align-items-center"
                        style={{
                          backgroundColor: theme ? "#2C2C2C" : "#fff",
                          color: theme ? "#fff" : "#333",
                          border: `1px solid ${theme ? "#333" : "#ddd"}`,
                          padding: '6px 10px',
                          borderRadius: '8px',
                          cursor: 'pointer'
                        }}
              
                      >
                        <span style={{fontSize:"12px"}}>Hisory</span>
                      </div>
                  
                    </div>
                    <div className="">
                      <div
                        className=" d-flex justify-content-between align-items-center"
                        style={{
                          backgroundColor: theme ? "#2C2C2C" : "#fff",
                          color: theme ? "#fff" : "#333",
                          border: `1px solid ${theme ? "#333" : "#ddd"}`,
                          padding: '6px 10px',
                          borderRadius: '8px',
                          cursor: 'pointer'
                        }}
                    
                      >
                        <span style={{fontSize:"12px"}}>Earnings & Revenue</span>
                      </div>
                  
                    </div>
                  
                </div>
                

              </div>
              <div>
                <h6 className={`fs-5 mt-3 mb-3  ${theme ? 'text-white' : 'text-dark'}`}>IPOs now open for investing</h6>
              </div>

              <div className="responsive-container">
  {loading ? (
    Array.from({ length: 10 }).map((_, index) => (
      <div key={index} className="row-skeleton">
        <Skeleton height={40} style={{ width: '100%' }} />
      </div>
    ))
  ) : (
    Array.isArray(Stockscreener) &&
    Stockscreener
        
      .map((item, index) => (
        <div key={index} className="row-item">
          <div className="column w-100" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
            <div className="d-flex align-items-center">
              <Imageview item={item} />
              <h3 className="company-name" style={{ color: theme ? '#fff' : 'black' }}>
                {item?.company_name}
              </h3>
            </div>
            <div className="align-items-center text-center  mt-5">
              <h3 className="company-name fw-bold" style={{ color:  theme ? '#7E66BC' : '#5b298c'  }}>
              Business Compliance
               
              </h3>
              <h3 className="company-name mt-3 " style={{ color: theme ? '#fff' : 'black' }}>
              {item?.business_compliance}
              </h3>
     
            </div>
            
          </div>

          <div className="column text-center w-100" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black' }}>
          <div className="align-items-center">
              <h3 className="company-name fw-bold" style={{ color:  theme ? '#7E66BC' : '#5b298c'  }}>
              Start Date

               
              </h3>
              <h3 className="company-name mt-3" style={{ color: theme ? '#fff' : 'black' }}>
              {item?.start_date}
              
              </h3>
     
            </div>
        
            <div className="align-items-center  mt-5">
              <h3 className="company-name fw-bold" style={{ color:  theme ? '#7E66BC' : '#5b298c'  }}>
              Financial Compliance AssetPost

               
              </h3>
              <h3 className="company-name mt-3" style={{ color: theme ? '#fff' : 'black' }}>
              {item?.financial_compliance_AssetPost}
              
              </h3>
     
            </div>
          </div>

          <div className="column text-center w-100" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black' }}>
            <div className="align-items-center">
              <h3 className="company-name fw-bold" style={{ color:  theme ? '#7E66BC' : '#5b298c'  }}>
              End Date
              </h3>
              <h3 className="company-name mt-3" style={{ color: theme ? '#fff' : 'black' }}>
              {item?.end_date}
              
              </h3>
     
            </div>
            <div className="align-items-center mt-5">
              <h3 className="company-name fw-bold " style={{ color:  theme ? '#7E66BC' : '#5b298c' }}>
              Financial Compliance DebtPost

               
              </h3>
              <h3 className="company-name mt-3" style={{ color: theme ? '#fff' : 'black' }}>
              {item?.financial_compliance_debtPost}
              </h3>
     
            </div>
          </div>
          <div className="column text-center w-100" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black' }}>
            <div className="align-items-center">
              <h3 className="company-name fw-bold" style={{ color:  theme ? '#7E66BC' : '#5b298c'  }}>
             Min Bit
              </h3>
              <h3 className="company-name mt-3" style={{ color: theme ? '#fff' : 'black' }}>
              {item?.min_bid
              }
              
              </h3>
     
            </div>
            <div className="align-items-center  mt-5 ">
              <h3 className="company-name fw-bold " style={{ color:  theme ? '#7E66BC' : '#5b298c' }}>
              Max Bid

               
              </h3>
              <h3 className="company-name mt-3" style={{ color: theme ? '#fff' : 'black' }}>
              {item?.max_bid}
              </h3>
     
            </div>
          </div>

          <div className="column compliance-status text-center w-100" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
          <div className="align-items-center">
              <h3 className="company-name text-center fw-bold" style={{ color:  theme ? '#7E66BC' : '#5b298c'  }}>
              Financial Compliance Pre
               
              </h3>
              <h3 className="company-name text-center mt-3" style={{ color: theme ? '#fff' : 'black' }}>
              {item?.financial_compliance_pre}
              </h3>
     
            </div>
            <div className="status-box mt-5" style={{ backgroundColor: item?.shariah_status === "Non Shariah Compliant" ? "#E02B1F" : "#218303" }}>
              <span className='pt-1 pb-1' style={{ color: '#fff', fontWeight: "500",fontSize:"13px" }}>{item?.shariah_status}</span>
            </div>
           
          </div>
        </div>
      ))
  )}
</div>




           
              {/* <div className='d-flex justify-content-center'>
                <PaginationIndex />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Iposcreen
