import React, { useEffect, useState, useContext, useRef } from "react";
import { json, useLocation } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import FetchData from "../../fetchapi/Apifetch";
import config from '../../Utills/ApiRoute';
import EmojiPicker from 'emoji-picker-react';
import { UserContext } from "../../Utills/UserContext";
import Navbar from "../Navbar/Navbar";
import '../Community/Chartscreen.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faXmark } from '@fortawesome/free-solid-svg-icons';
import io from "socket.io-client";
import Footer from "../footer/Footer";
import ImageModel from "./ImageModel";
import { Modal } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
// const socket = io("http://localhost:4001")

// const socket = io("http://3.108.189.162")
const socket = io("https://islamicapi.qcodesinfotech.com")





const Chart = () => {
  const [loading, setLoading] = useState(false);
  const uservalue = JSON.parse(localStorage.getItem("email"));
  const location = useLocation();
  const { state } = location;
  const queryString = location.search;
  const nodeId = queryString ? queryString.substring(1) : '';
  const { theme, setTheme } = useContext(UserContext);
  const [roomvalue, SetRoomvalue] = useState('');
  const [message, setMessage] = useState('');
  const [Showmodel, setShowmodel] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [messages, setMessages] = useState('');
  const [messageText, setMessageText] = useState("");
  const [AllImage, setAllImage] = useState([]);
  const [room, setRoom] = useState('');
  const [groupInfo, setGroupinfo] = useState(false);
  const [GroupId1, setGroupID1] = useState('');
  const [groupInformation, SetgroupInformation] = useState('');
  const [userlist, setUserlist] = useState('');
  const [connection, setConnection] = useState('');
  const [chartmessages, setchartmessages] = useState('');
  const [chartarea, setchartarea] = useState(false);
  const [typing, setTyping] = useState(false);
  const [userTyping, setuserTyping] = useState(false);
  const [chartProfile, setchartProfile] = useState("");
  const [userchartmessage, setuserchartmessage] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [openmodel, setOpenmodel] = useState(false);
  const [formdata1, setFormdata1] = useState({
    userId: uservalue?._id,
    nodeId: "",
    GroupId: "",
    text: '',
    image: "",
  });

  const [connectiondata, setconnectiondata] = useState({
    userId: uservalue?._id,
    GroupId: "",
  });

  function formatTimeWithAmPm(isoString) {
    const date = new Date(isoString);
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      const res = await FetchData(
        `clientuser`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
        setUserlist(res.data);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    fetchDatagroupinfo();
  }, []);
  const fetchDatagroupinfo = async () => {
    const res = await FetchData(
      `getconnection?groupID=66e924e9a4f3508714819590`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      SetgroupInformation(res.data);
    }
  };


  useEffect(() => {
    const fetchroom = async () => {
      const res = await FetchData(
        `getroom`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
        SetRoomvalue(res.data);
      }
    };
    fetchroom();
  }, []);

  const fetchConnection = async (groupID) => {
    try {
      setLoading(true)
      const res = await FetchData(
        `getconnectiongroupwise?groupID=${groupID}&userId=${uservalue?._id}`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
        const nodeID = res?.data?.[0]?.nodeID;
        if (nodeID) {
          fetchDatagroupinfo();
          setFormdata1((prevState) => ({
            ...prevState,
            nodeId: nodeID,
          }));
          setLoading(false)

        } else {
          console.warn("NodeID is undefined or not found in response.");
        }
        setConnection(res.data);
        setchartarea(true);
      } else {
        console.warn("No data found in the response or res.data is empty.");
      }
    } catch (error) {
      console.error("Error fetching connection data:", error);
    }
  };


  useEffect(() => {
    console.log('Updated formdata1:', userchartmessage);
  }, [userchartmessage]);

  // Ensure the picker and formdata1 are initialized before accessing them
  const handleEmojiClick = (event, emojiObject) => {
    if (!formdata1) return;  // Ensure formdata1 exists

    const updatedMessage = (formdata1?.text || '') + event?.emoji;
    setFormdata1(prevFormData => ({
      ...prevFormData,
      text: updatedMessage,
    }));
  };

  useEffect(() => {
    socket.on("data", (data) => {
      listmessage(formdata1?.GroupId);
    });
  }, [formdata1?.GroupId]);


  useEffect(() => {
    let typingTimeout;
    socket.on("receivedtyping", (data) => {
      if (data === GroupId1) {
        setTyping(true);
        if (typingTimeout) clearTimeout(typingTimeout);
        typingTimeout = setTimeout(() => {
          setTyping(false);
        }, 2000);
      }
    });

    return () => {
      socket.off("receivedtyping");
      if (typingTimeout) clearTimeout(typingTimeout);
    };
  }, [formdata1?.GroupId, GroupId1]);



  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    await setImagePreview(file);
    await setShowmodel(true);
    setOpenmodel(false);

  };

  const handleClose = () => {
    setShowmodel(false)
  }

  const Imagemessagesend = async () => {
    const formData = new FormData();
    formData.append('mediaUrls', imagePreview); // Append image to FormData
    try {
      const res = await FetchData("uploadimages", 'POST', formData, true, true); // Send the image to the server
      if (res.success) {
        console.log('res.url', res.url);
        // Set the image URL in formdata1
        await setFormdata1({ ...formdata1, image: res.url });
        await sendMessage(res.url); // Pass the image URL to sendMessage
        setImagePreview(null); // Clear the image preview
      } else {
        console.error("Please Check Credentials");
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };



  const sendMessage = async (imageUrl) => {
    const messageData = { ...formdata1, image: imageUrl }; // Ensure messageData includes the image
    socket.emit("sendMSG", messageData); // Use messageData with the image URL
    listmessage(formdata1?.GroupId);
    setFormdata1(prevState => ({
      ...prevState,
      text: "",
      image: ""
    }));
    setShowmodel(false);
    setShowPicker(false);
  };


  const listmessage = async (Groupvalue1) => {
    if (!Groupvalue1) {
      return;
    }
    try {
      const res = await FetchData(
        `getmessage?GroupId=${Groupvalue1}`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
        setchartmessages(res?.data);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      setMessageText("");
    }
  };



  const handleInputChange = (e) => {
    socket.emit("typing", formdata1?.GroupId);
    setuserTyping(true);
    const { name, value } = e.target;
    setFormdata1({ ...formdata1, [name]: value });
  };


  const handleClick = (user) => {
    setGroupID1(user?._id);
    fetchConnection(user?._id);
    setFormdata1((prevState) => ({
      ...prevState,
      GroupId: user?._id, // Set GroupId
    }));
    Addconnection(user?._id);
    setchartProfile(user);
    listmessage(user?._id);

  };


  function extractTime(dateTimeString) {
    // Split the string by the comma to separate date and time
    const timeString = dateTimeString.split(', ')[1];

    if (!timeString) return '';  // Return an empty string if no time is found
    const [hours, minutes, period] = timeString.split(':');

    // Reconstruct the time without the seconds
    const formattedTime = `${hours}:${minutes} ${period.split(' ')[1]}`;

    return formattedTime;
  }



  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelection = (itemType) => {
    setSelectedItem(itemType); // Set the clicked item as selected
  };


  const Addconnection = async (item) => {
    setconnectiondata((prevFormdata) => ({
      ...prevFormdata,
      GroupId: item,
    }));

    const dataObject = {
      ...connectiondata,
      GroupId: item,
    };
    try {
      const res = await FetchData("addconnection", 'POST', JSON.stringify(dataObject), true, false);
      if (res.success) {
        console.log('User connected successfully')
      } else {
        console.log(res.error);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chartmessages, typing]);




  const userList = [
    { profilePicture: '/images/user.png', name: 'Islamic Tijarat', status: 'Online' },
  ];

  const chatMessages = [
    { text: 'Hello!', time: '10:00 AM', senderId: 1 },
    { text: 'Hi there!', time: '10:05 AM', senderId: 2 },
  ];

  const profiledetails = {
    first_name: 'John',
    last_name: 'Doe',
    profilePicture: '/images/profilePicture.png',
    id: 1,
  };

  const DropdownImage2 = ({ item, width, height, font, value }) => {
    const [imageError2, setImageError2] = useState(false);
    const stock1 = `${item?.first_name || ''} ${item?.last_name || ''}`;
    const splitStock1 = (stock1) => {
      if (!stock1) return '';

      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock1.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase(); // Convert to uppercase if needed
    };

    // Determine if we should show the fallback
    const shouldShowFallback = imageError2 || !item?.img;



    return (
      <div className="me-2" >
        {!shouldShowFallback ? (
          <img
            className=""
            src={item.img}
            alt="Stock Image"
            style={{
              height: height,
              width: width,
              borderRadius: '50%',
              marginLeft: '10%',
              border: '0.5px solid #fff',
              objectFit: 'cover', // Ensures the image covers the element without distortion
            }}
            onError={() => setImageError2(true)}

          />
        ) : (
          <div
            style={{
              height: height,
              width: width,
              borderRadius: '50%',
              border: theme ? '0.5px solid #fff' : '0.5px solid #7f66bc',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: '10%',
              fontWeight: 500,
              backgroundColor: '#e6e6e6b0',
              fontSize: font,
              color: '#333',
            }}

          >
            {splitStock1(stock1)}
          </div>
        )}
      </div>
    );
  };


  const extractDate = (datetime) => {

    const [datePart, timePart] = datetime.split(', ');
    const [day, month, year] = datePart.split('/').map(Number);
    const date = new Date(year, month - 1, day);

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);

    if (date.toDateString() === today.toDateString()) {
      return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    } else if (date >= oneWeekAgo) {
      return date.toLocaleDateString('en-US', { weekday: 'long' });
    } else {
      return date.toLocaleDateString();
    }
  };

  // Example Usage:
  const datetime = "11/10/2024, 9:55:24 PM";


  const containsEmoji = (text) => {
    const emojiRegex = /[\p{Emoji}]/u;
    return emojiRegex.test(text);
  };

  const countEmojis = (text) => {
    const emojiRegex = /(?:\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/gu;
    const matches = text.match(emojiRegex);
    return matches ? matches.length : 0;
  };


  const [selectedImage, setSelectedImage] = useState(null); // State for selected image
  const [showModal, setShowModal] = useState(false); // State to toggle modal visibility

  // Function to open modal with the clicked image
  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  // Function to close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
  };
  const profiledetails1 = JSON.parse(localStorage.getItem("email"));

  return (
    <div>
      <Navbar />

      <div className="container-fluid padding-header " style={{ width: '95%' }}>
        <ImageModel imagePreview={imagePreview} showModal={Showmodel} setShowmodel={setShowmodel} Imagemessagesend={Imagemessagesend} />
        <div className="row justify-content-center" >

          <div className="col-lg-4 col-md-4">
            <div className="card p-3" style={{
              backgroundColor: theme ? '#2c3e50' : '#f9f9f9',
              borderRadius: '15px',
              boxShadow: theme ? 'none' : '0 4px 10px rgba(0, 0, 0, 0.1)',
              transition: 'all 0.3s ease'
            }}>
              <h5 className={`text-start mb-4 ${theme ? 'text-white' : 'text-dark'}`}>Chat</h5>

              {roomvalue && roomvalue.map((user, index) => (
                <div key={index} className="card mb-3 p-2 group-card" style={{
                  backgroundColor: theme ? chartarea ? "#7f66bc" : '#34495e' : chartarea ? "#7f66bc" : '#ffffff',
                  borderRadius: '12px',
                  boxShadow: chartarea ? '0 8px 15px rgba(127, 102, 188, 0.5)' : theme ? 'none' : '0 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'all 0.3s ease',
                  cursor: 'pointer'
                }}
                  onClick={() => { handleClick(user); }}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src="/logonew.png"
                      alt="User"
                      className="rounded-circle"
                      style={{
                        width: 40,
                        height: 40,
                        border: `2px solid ${chartarea ? '#fff' : theme ? '#7f66bc' : '#3498db'}`,
                        marginRight: '12px'
                      }}
                    />
                    <div className="ms-3">
                      <h6 className="mb-0" style={{ color: chartarea ? '#fff' : theme ? '#fff' : '#333', fontWeight: 'bold' }}>
                        {user.title}
                      </h6>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {chartarea ?
            <div className={`${groupInfo ? "col-lg-4" : 'col-lg-8'} ${groupInfo ? "col-md-5" : ' col-md-8'} `}>
              <div className="card chat-area shadow-sm" style={{
                backgroundColor: theme ? '#34495e' : '#ffffff',
                borderRadius: '15px',
                boxShadow: theme ? 'none' : '0 4px 10px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.4s ease'
              }}>
                <div className="card-header d-flex justify-content-between align-items-center" style={{
                  backgroundColor: theme ? '#2c3e50' : '#f9f9f9',
                  borderBottom: theme ? '1px solid #34495e' : '1px solid #ddd',
                  borderTopRightRadius: '15px',
                  borderTopLeftRadius: '15px',
                }}>
                  <div className="d-flex align-items-center">
                    {loading ? (
                      <Skeleton circle={true} height={40} width={40} />
                    ) : (
                      <img
                        src={chartProfile?.img ? chartProfile.img : '/images/user.png'}
                        alt="Profile"
                        className="rounded-circle"
                        style={{ width: 40, height: 40 }}
                        onClick={() => { setGroupinfo(!groupInfo) }}
                      />
                    )}
                    <div className="ms-3">
                      <h6 className={`mb-0 ${theme ? 'text-white' : 'text-dark'}`}>{loading ? <Skeleton width={100} /> : chartProfile.title}</h6>
                      <small className={`text-muted ${theme ? 'text-light' : 'text-secondary'}`}>{loading ? <Skeleton width={50} /> : 'Online'}</small>
                    </div>
                  </div>
                  <div>
                    <img src="images/zoom.png" alt="Zoom" />
                    <i className={`fa fa-info-circle ms-2 ${theme ? 'text-light' : 'text-secondary'}`} aria-hidden="true"></i>
                  </div>
                </div>

                {/* Chat Messages */}
                <div className="card-body chat-body" style={{ backgroundColor: theme ? '#2c3e50' : '#f9f9f9', padding: '20px', overflowY: 'scroll' }}>

                  {loading ? (
                    <Skeleton count={5} height={30} style={{ marginBottom: '10px' }} />
                  ) : (
                    chartmessages && chartmessages.map((message, index) => {
                      const isSender = message?.userId?._id === uservalue._id;
                      const isImageMessage = message?.image && message?.image.length > 0 && (message?.image.includes('.jpg') || message?.image.includes('.png')); // Check if message contains an image
                      const isPdfMessage = message?.image && message?.image.includes('.pdf'); // Check if message contains a PDF
                      const emojiCount = countEmojis(message.text);
                      const messageDate = extractDate(message.time);
                      const showDateHeader = index === 0 || extractDate(chartmessages[index - 1].time) !== messageDate;
                      const getFileSize = (fileUrl) => {
                        const fileSizeInBytes = message?.fileSize || 0; // You can get file size from backend if available
                        const fileSizeInKB = (fileSizeInBytes / 1024).toFixed(2); // Convert to KB
                        return `${fileSizeInKB} KB`;
                      };

                      return (
                        <div key={index}>
                          {showDateHeader && (
                            <div className="text-center my-4">
                              <span className="badge bg-light card p-2" style={{ color: "gray" }}>
                                {messageDate}
                              </span>
                            </div>
                          )}

                          <div className={`d-flex mb-2 ${isSender ? 'justify-content-end' : 'justify-content-start'}`}>
                            {!isSender && (

                              <DropdownImage2 item={message?.userId} width={'30px'} height={'30px'} font={'12px'} />
                              // <img
                              //   src={message?.userId?.img ? message?.userId?.img : '/images/user.png'}
                              //   alt="User"
                              //   className="rounded-circle me-2"
                              //   style={{ width: 25, height: 25 }}
                              // />
                            )}


                            <div className={`message ${isSender ? 'sent' : 'received'} p-2 rounded`} style={{
                              backgroundColor: isSender ? '#8066bc' : '#f1f0f0',
                              maxWidth: '60%',
                              color: theme ? 'white' : 'black',
                              transition: 'all 0.4s ease'
                            }}>
                              <p className="mb-1" style={{ fontSize: '12px', color: isSender ? "white" : 'rgb(127, 102, 188)' }}>
                                {isSender ? 'You' : `${message?.userId?.first_name} ${message?.userId?.last_name}`}
                              </p>
                              {isImageMessage ? (
                                <img
                                  src={message.image.startsWith('http') ? message.image : config.BASE_URL + '/' + message.image}
                                  alt="Sent Image"
                                  style={{ width: '200px', height: 'auto', borderRadius: '8px' }}
                                  onClick={() => handleImageClick(message.image)}
                                />
                              ) : isPdfMessage ? (
                                <div className="pdf-message d-flex align-items-center p-2" style={{
                                  backgroundColor: isSender ? '#ffefd5' : '#f1f0f0', // Slightly different background for PDF messages
                                  borderRadius: '8px',
                                  border: '1px solid #d9534f'
                                }}>
                                  <i className="fa fa-file-pdf-o" style={{ fontSize: '32px', color: '#d9534f' }}></i>
                                  <div className="ms-3">
                                    <a
                                      href={config.BASE_URL + '/' + message.image}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="text-decoration-none"
                                      style={{
                                        color: isSender ? 'white' : '#007bff',
                                        fontSize: '14px',
                                        fontWeight: '500'
                                      }}
                                    >
                                      {message.image.split('/').pop()}
                                    </a>
                                    <p className="mb-0" style={{ fontSize: '12px', color: 'gray' }}>
                                      {getFileSize(message.image)}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <p className={`mb-1 ${theme ? isSender ? 'text-white' : 'text-dark' : isSender ? "text-white" : "text-dark"}  fs-7`}
                                  style={{
                                    display: 'flex',
                                    fontSize: emojiCount ? emojiCount == 1 ? '30px' : '14px' : "",  // Larger size for emojis
                                    justifyContent: emojiCount ? "center" : "start",
                                    lineHeight: emojiCount ? '1.5' : 'normal' // Adjust line height for emojis
                                  }}>
                                  {message.text}
                                </p>
                              )}

                              <small className="text-muted">{extractTime(message.time)}</small>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                  {typing && (
                    <div className="ms-1">
                      <p
                        className="mb-5 mt-4  ms-5 p-2 typing-indicator"
                        style={{
                          backgroundColor: '#f1f0f0',
                          fontSize: '16px',
                          borderTopLeftRadius: "15px",
                          borderBottomRightRadius: "15px",
                          borderBottomLeftRadius: "15px",
                          width: 'fit-content',
                          color: '#7E66BC'
                        }}
                      >
                        typing<span className="dot1">.</span><span className="dot2">.</span><span className="dot3">.</span>
                      </p>
                    </div>
                  )}

                  <div ref={chatEndRef} className="mb-1" />

                </div>

                <div className="card-footer d-flex align-items-center p-2" style={{
                  backgroundColor: theme ? '#34495e' : '#f9f9f9',
                  borderTop: theme ? '1px solid #34495e' : '1px solid #ddd',
                  borderBottomLeftRadius: '15px',
                  borderBottomRightRadius: '15px',
                  position: 'relative',
                }}>
                  <button
                    onClick={() => setShowPicker(val => !val)}
                    className={`btn ${theme ? 'text-white' : 'text-dark'} text-center`}
                    style={{
                      borderRadius: '50%', // Make the button circular
                      border: 'none',
                      padding: '5px',
                      width: "50px",
                      marginRight: '5px',
                      fontSize: "28px",
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = theme ? '#6c57a3' : '#d4d4d4')}
                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = theme ? '#7e66bc' : '#e5e5e5')}
                  >
                    😀
                  </button>

                  {/* Show Emoji Picker */}
                  {showPicker && (
                    <div className="emoji-picker-container"
                      style={{
                        position: 'absolute',
                        bottom: '65px',
                        left: '10px',
                        zIndex: 1000,
                        width: "95%",
                        backgroundColor: theme ? '#34495e' : 'white',
                        borderRadius: '8px',
                        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                        padding: '10px',
                      }}>
                      {/* Ensure correct props for EmojiPicker */}
                      <EmojiPicker
                        onEmojiClick={(event, emojiObject) => handleEmojiClick(event, emojiObject)}
                        style={{ width: '100%', height: '400px' }}
                      />
                    </div>
                  )}

                  <div onClick={() => { setOpenmodel(!openmodel) }}>
                    <i className="fa fa-plus" style={{ fontSize: '22px', color: theme ? '#7e66bc' : '#34495e' }}></i>
                  </div>
                  <>
                    {openmodel &&
                      <div style={{
                        position: "absolute",
                        bottom: "65px",
                        left: "50px",
                        zIndex: '1000',
                        backgroundColor: theme ? '#2c3e50' : '#f8f9fa',
                        padding: '15px',
                        borderRadius: '10px',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                        width: '200px',
                        transition: 'all 0.3s ease-in-out'
                      }}>
                        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>

                          {/* Image Upload */}
                          <li
                            style={{
                              marginBottom: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: selectedItem === 'Image' ? (theme ? '#7e66bc' : '#ccc') : 'transparent', // Apply background if selected
                              padding: '5px',
                              borderRadius: '5px',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleSelection('Image')}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme ? '#7e66bc' : '#ccc'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = selectedItem === 'Image' ? (theme ? '#7e66bc' : '#ccc') : 'transparent'}
                          >
                            <label htmlFor="imageUpload" className={`icon-upload ${theme ? 'text-white' : 'text-dark'}`}>
                              <i className="fa fa-image" style={{ fontSize: '24px', color: theme ? '#fff' : '#34495e', marginRight: '10px' }}></i>
                              <span style={{ fontSize: '14px' }}>Image</span>
                            </label>
                            <input
                              type="file"
                              id="imageUpload"
                              accept="image/*"
                              onChange={handleImageUpload}
                              style={{ display: 'none' }}
                            />
                          </li>

                          {/* Document Upload */}
                          <li
                            style={{
                              marginBottom: '15px',
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: selectedItem === 'Document' ? (theme ? '#7e66bc' : '#ccc') : 'transparent',
                              padding: '5px',
                              borderRadius: '5px',
                              cursor: 'pointer'
                            }}
                            onClick={() => handleSelection('Document')}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme ? '#7e66bc' : '#ccc'}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = selectedItem === 'Document' ? (theme ? '#7e66bc' : '#ccc') : 'transparent'}
                          >
                            <label htmlFor="documentUpload" className={`icon-upload ${theme ? 'text-white' : 'text-dark'}`}>
                              <i className="fa fa-file" style={{ fontSize: '24px', color: theme ? '#fff' : '#34495e', marginRight: '10px' }}></i>
                              <span style={{ fontSize: '14px' }}>Document</span>
                            </label>

                            <input
                              type="file"
                              id="documentUpload"
                              accept=".pdf,.doc,.docx,.txt"
                              onChange={handleImageUpload}
                              style={{ display: 'none' }}
                            />
                          </li>
                        </ul>
                      </div>
                    }
                  </>
                  <input
                    type="text"
                    className={`form-control ${theme ? 'text-white bg-dark' : 'text-dark bg-light'}`}
                    placeholder="Type a message..."
                    name="text"
                    value={formdata1.text}

                    onChange={handleInputChange}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && formdata1.text.trim() !== '') {
                        sendMessage();
                      }
                    }}
                    style={{
                      borderRadius: '30px',
                      padding: '10px',
                      flex: 1,
                      margin: '0 10px'
                    }}
                  />

                  {/* Send Button */}
                  <button
                    className="btn"
                    style={{
                      backgroundColor: '#7e66bc',
                      color: 'white',
                      borderRadius: '50%',
                      width: "50px",
                      padding: '10px',
                      fontSize: '20px',
                      boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                      transition: 'background-color 0.3s'
                    }}
                    onClick={() => { if (formdata1.text.trim()) sendMessage(); }}
                    disabled={!formdata1.text.trim()}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#6c57a3'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = '#7e66bc'}
                  >
                    <i className="fa fa-paper-plane"></i>
                  </button>
                </div>
              </div>
            </div>
            : <div className={`${groupInfo ? "col-lg-4" : 'col-lg-8'} ${groupInfo ? "col-md-5" : ' col-md-8'} `}>
              <div className="card chat-area shadow-sm" style={{
                backgroundColor: theme ? '#34495e' : '#ffffff',
                borderRadius: '15px',
                boxShadow: theme ? 'none' : '0 4px 10px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.4s ease'
              }}>
                {/* Welcome Banner */}
                <div className="card-body text-center" style={{
                  backgroundColor: theme ? '#2c3e50' : '#f9f9f9',
                  padding: '40px 20px',
                  borderRadius: '15px',
                  minHeight: '350px',
                  transition: 'all 0.4s ease',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                  <img src="/logonew.png" alt="Trading Icon" style={{ width: '100px', marginBottom: '20px' }} />
                  <h2 className={theme ? 'text-white' : 'text-dark'}>Welcome to Islamic Tijarath Chat</h2>
                  <p className={theme ? 'text-light' : 'text-secondary'}>Stay updated with the latest market trends and insights tailored to your needs.</p>
                  <p className={theme ? 'text-light' : 'text-secondary'}>Explore Halal trading options and make informed decisions with real-time data.</p>

                  {/* Highlighted message */}
                  <p className={`${theme ? 'text-light' : 'text-secondary'} mt-4`} style={{
                    fontSize: '1.2rem',
                    fontWeight: 'bold',
                    color: theme ? '#f39c12' : '#e74c3c',
                    backgroundColor: theme ? 'rgba(243, 156, 18, 0.2)' : 'rgba(231, 76, 60, 0.2)',
                    padding: '10px 15px',
                    borderRadius: '8px',
                    transition: 'all 0.4s ease'
                  }}>
                    Click group for chat
                  </p>
                </div>
                {/* Footer */}
              </div>
            </div>
          }
          <div className={`${groupInfo ? "d-block" : "d-none"} col-md-4 col-lg-4`}
            style={{
              height: '85vh',
              overflowY: 'auto',
              paddingBottom: '20px',
              scrollbarWidth: 'thin',
            }}>
            <div className="card text-center justify-content-center shadow-sm" style={{
              backgroundColor: theme ? '#2c3e50' : '#f8f9fa',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              boxShadow: theme ? 'none' : '0 4px 12px rgba(0, 0, 0, 0.15)',
              transition: 'all 0.4s ease',
            }}>
              {/* Header Section */}
              <div className="d-flex align-items-center p-3" style={{
                backgroundColor: 'rgb(126, 102, 188)',
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                position: 'relative',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
              }}>
                {/* Close Button */}
                <span onClick={() => setGroupinfo(false)} style={{
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  cursor: 'pointer'
                }}>
                  <FontAwesomeIcon
                    icon={faXmark}
                    style={{ color: 'white', fontSize: '18px' }}
                  />
                </span>

                {/* Title */}
                <h6 className="text-white mx-auto" style={{
                  fontSize: '18px',
                  fontWeight: '500',
                  margin: 0
                }}>
                  Group Info
                </h6>
              </div>


              {/* Profile Section */}
              <div className="p-3">
                <img src="/logonew.png" alt="Profile" className="rounded-circle" style={{
                  width: 120, height: 120, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', objectFit: 'cover'
                }} />
              </div>
              <div className="mt-2 mb-3">
                <h5 className="mb-0" style={{ fontWeight: '500', color: theme ? '#ecf0f1' : '#34495e' }}>{chartProfile.title}
                </h5>
                <span className="text-muted" style={{ fontSize: '14px' }}>Group.{groupInformation.length} members</span>
              </div>
            </div>
            <div className="card shadow-sm mt-2" style={{
              backgroundColor: theme ? '#2c3e50' : '#f8f9fa',
              boxShadow: theme ? 'none' : '0 4px 12px rgba(0, 0, 0, 0.15)',
              padding: '15px',
              transition: 'all 0.4s ease'
            }}>
              <div className="d-flex justify-content-between mb-2" style={{ color: theme ? '#ecf0f1' : '#34495e' }}>
                <span style={{ fontWeight: '500', color: 'rgb(126, 102, 188)', fontSize: '16px' }}>Add group description</span>
              </div>
              <p className="text-muted" style={{ fontSize: '14px', margin: 0 }}>{chartProfile.description}</p>
            </div>
            <div className="card shadow-sm" style={{
              backgroundColor: theme ? '#2c3e50' : '#fff',
              padding: '15px',
              width: '100%',
              overflowX: 'hidden',
              boxShadow: theme ? 'none' : '0 4px 12px rgba(0, 0, 0, 0.15)',
            }}>
              <div className="media-list d-flex" style={{
                display: 'flex',
                overflowX: 'scroll', // Enable horizontal scrolling
                gap: '10px',
                paddingBottom: '10px',
                width: '100%', // Full width of the container
                scrollSnapType: 'x mandatory', // Snap scrolling for smooth image display
              }}>
                {chartmessages && chartmessages
                  .filter(message1 => message1?.image && message1?.image.length > 0)
                  .reverse()
                  .slice(0)
                  .map((message1, index) => (
                    <div key={index} className="media-item p-2" style={{
                      flex: '0 0 30%', // Ensures 3 images fit in view at 30% width each with gaps
                      maxWidth: '30%',
                      height: 'auto',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      backgroundColor: "#f2f2f2",
                      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
                      cursor: 'pointer',
                      scrollSnapAlign: 'start', // Snap to each image
                    }}
                      onClick={() => handleImageClick(message1.image)} // Open modal on click
                    >
                      <img
                        src={message1?.image.startsWith('http') ? message1?.image : config.BASE_URL + '/' + message1?.image}
                        alt={`media${index}`}
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                      />
                    </div>
                  ))
                }
              </div>

              <Modal
                show={showModal}
                onHide={handleCloseModal}
                centered
                size="lg"
                className="image-viewer-modal"
                style={{
                  backgroundColor: "rgb(0 0 0 / 20%)", // Semi-transparent black background
                  zIndex: 1050,
                  transition: 'opacity 0.3s ease-in-out', // Smooth transition
                }}
              >
                <Modal.Body style={{ padding: '0', position: 'relative' }}>
                  {/* Close button */}
                  <FaTimes
                    onClick={handleCloseModal}
                    style={{
                      position: 'absolute',
                      top: '20px',
                      right: '20px',
                      fontSize: '24px',
                      color: theme ? '#fff' : "gray",
                      cursor: 'pointer',
                      zIndex: 10,
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.color = '#ff4757')} // Change color on hover
                    onMouseLeave={(e) => (e.currentTarget.style.color = '#fff')} // Revert color
                  />

                  {/* Image displayed in the modal */}
                  {selectedImage && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '80vh',
                        backgroundColor: theme ? 'rgba(128, 128, 128, 0.9)' : "#fff", // Semi-transparent gray
                        borderRadius: '8px', // Slightly rounded corners for background
                        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.5)', // Shadow effect
                        overflow: 'hidden', // Prevent overflow of content
                      }}
                    >
                      <img
                        src={selectedImage.startsWith('http') ? selectedImage : config.BASE_URL + '/' + selectedImage}
                        alt="Selected media"
                        style={{
                          maxWidth: '90%',
                          maxHeight: '90%',
                          objectFit: 'contain',
                          transition: 'transform 0.3s', // Smooth scale effect
                        }}
                        onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')} // Scale on hover
                        onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')} // Revert scale
                      />
                    </div>
                  )}
                </Modal.Body>
              </Modal>
            </div>

            <div className="card shadow-sm mt-2" style={{
              backgroundColor: theme ? '#2c3e50' : '#f8f9fa',
              boxShadow: theme ? 'none' : '0 4px 12px rgba(0, 0, 0, 0.15)',
              transition: 'all 0.4s ease',
              height: '40vh',
              overflowY: 'auto'
            }}>
              <div className="p-3">
                <div className="d-flex justify-content-start mb-2">
                  <span style={{ fontSize: '14px', fontWeight: '500', color: 'rgb(126, 102, 188)' }}>{groupInformation.length} members</span>
                </div>
                {groupInformation && groupInformation.map((member, index) => (
                  <div key={index} className="d-flex align-items-center my-2 p-2" style={{
                    backgroundColor: theme ? '#34495e' : '#ffffff',
                    borderRadius: "10px",
                    border: '1px solid rgba(0, 0, 0, 0.1)',
                    boxShadow: theme ? 'none' : '0 2px 6px rgba(0, 0, 0, 0.1)'
                  }}>
                    <DropdownImage2 item={member?.userDetails} width={'40px'} height={'40px'} font={'16px'} />

                    {/* <img src={member?.userDetails?.img || "/images/user.png"} alt={member?.userDetails?.name} className="rounded-circle" style={{
                      width: 40, height: 40, objectFit: 'cover'
                    }} /> */}
                    <span className="ms-3" style={{ color: theme ? '#ecf0f1' : '#34495e', fontWeight: '500' }}>{member?.userDetails?.first_name} {member?.userDetails?.last_name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>
      </div>
      {/* <Footer /> */}

    </div>
  );
};


export default Chart;