import React, { useEffect, useRef } from 'react';


const Techenical = ({ symbol , Themes2 }) => {
    const stock_code =  symbol
const widgetRef = useRef(null);
  useEffect(() => {
    // Create and configure the TradingView widget script
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      "interval": "1m",
      "width": "100%",
      "isTransparent":true,
      "height": 450,
      "backgroundColor": Themes2 ?"#1B1B1B" :'#fff',
      "symbol": stock_code,
      "showIntervalTabs": true,
      "displayMode": "single",
      "locale": "en",
      "colorTheme":Themes2? "dark":"light"
    });

    // Append the script to the widget container
    if (widgetRef.current) {
      widgetRef.current.appendChild(script);
    }

    // Clean up the script when the component unmounts or symbol changes
    return () => {
      if (widgetRef.current) {
        widgetRef.current.innerHTML = '';
      }
    };
  }, [symbol]);

  return (
    <div className="tradingview-widget-container" ref={widgetRef}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          {/* <span className="blue-text">Track all markets on TradingView</span> */}
        </a>
      </div>
    </div>
  );
}

export default Techenical