import "./App.css";
import Dashboard from "./components/pages/zakat/Dashboard";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import Login from "./components/pages/Register/Login";
import {
  BrowserRouter as Router,Routes,
  Route,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import Screener from "./components/pages/Screenerpage/Screener";
import Plan from "./components/pages/Plans/Plan";
import Watchpage from "./components/pages/watchpage/watchpage";
import Setting from "./components/pages/Settingpage/Setting";
import Register from "./components/pages/Register/Register";
import Purificationcaluculator from "./components/pages/Purificationcalculator/Purificationcaluculator";
import Newdashboard from "./components/pages/Darkdashboard/Newdashboard";
import Newstock from "./components/pages/Stockdetail/Newstock";
import ZakatCalculator from "./components/pages/zakat/ZakatCalculator";
import Bestscreenner from "./components/pages/Bestscreenner/Bestscreenner";
import { UserProvider, UserContext } from './components/Utills/UserContext';
import Forgotpassword from "./components/pages/Register/Forgotpassword";
import Otpverification from "./components/pages/Register/Otpverification";
import Newsettingpage from "./components/pages/Settingpage/Newsettingpage";
// import Iopscreen from "./components/pages/commingsoon/Iopscreen";
import Iopscreen from "./components/pages/Iposcreen/Iposcreen";
import Zakathcomingsoon from "./components/pages/commingsoon/ZakatCalculator";
import Chart from "./components/pages/Community/Chart";
import Industrybasestock from "./components/pages/Industrybasestock/Industrybasestock";
import Similarstock from "./components/pages/Similarstocks/Similarstocks";
import Premium from "./components/pages/commingsoon/perimum"; 
import Passwordscreen from "./components/pages/Register/Passwordscreen";
import Privacypolicy from "./components/pages/policys/Privacypolicy";
import Refundpolicy from "./components/pages/policys/Refundpolicy";
import Termsandcondition from "./components/pages/policys/Teamsandcondition";

function App() {
  // const token = JSON.parse(localStorage.getItem("login"));

  const Secure = (Component) => (
  <UserContext.Consumer>
    {({ user,theme }) => (user ?( 
      <div style={{backgroundColor:theme?'#171717':'#fafafa'}}>
        <Component /> 
      </div>
  ): <Navigate to="/" />)}
  </UserContext.Consumer>
  );  

  const InSecure = (Component) => (
  <UserContext.Consumer>
    {({ user,theme }) => (user ? <Navigate to="/dashboard" />: (
      <div style={{backgroundColor:theme?'#171717':'#fafafa'}}>
      <Component /> 
    </div>
    ))}
  </UserContext.Consumer>
  );

  const routes = [

    { path: "/", element: InSecure(Login) },
    { path: "/forgotpassword", element:InSecure(Forgotpassword)},
    { path: "/otpverification", element:InSecure(Otpverification)},
    { path: "/password", element:InSecure(Passwordscreen)},
    { path: "/dashboard1", element: Secure(Dashboard) },
    { path: "/purification", element: Secure(Purificationcaluculator) },
    { path: "/Zakat", element: Secure(Zakathcomingsoon) },
    // { path: "/Zakat", element: Secure(ZakatCalculator) },
    { path: "/screener", element: Secure(Screener) },
    { path: "/plan", element: Secure(Plan) },
    { path: "/ipo", element: Secure(Bestscreenner) },
    { path: "/iposcreen", element: Secure(Iopscreen) },
    { path: "/watchlist", element: Secure(Watchpage) },
    { path: "/profile", element: Secure(Setting) },
    { path: "/register", element: InSecure(Register) },
    { path: "/dashboard", element: Secure(Newdashboard) },
    { path: "/stock", element:Secure(Newstock)},
    { path: "/userprofile", element:Secure(Newsettingpage)},
    { path: "/community", element:Secure(Chart)},
    { path: "/chat2", element:Secure(Chart)},
    { path: "/industry", element:Secure(Industrybasestock)},
    { path: "/similarstock", element:Secure(Similarstock)},
    { path: "/premium", element:Secure(Premium)},
    { path: "/privacy-policy", element:Secure(Privacypolicy)},
    { path: "/refund-policy", element:Secure(Refundpolicy)},
    { path: "/terms_conditions", element:Secure(Termsandcondition)},

    
  ];
  return (
    <Router>
      <UserProvider>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      </UserProvider>
    </Router>
  );
}

export default App;
