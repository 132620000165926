import React from 'react'
import Navbar from '../Navbar/Navbar'

const Bestscreenner = () => {


    const tabledata = [
        {
          COMPANY: "APPLE INC",
          Exchange: "NASDAQ",
          Industry: "Consumer Electronics",
          StockScore: "55",
          MarketCap: "$2.43T",
          Price: "$146.92",
          D: "0.06%",
          EBITDA: "111.86B",
          PE: "28.43x",
          DE: "4.13",
        },
      ];
      const repeatedItems = Array(15).fill(tabledata[0]).map((item, index) => (
  <tr className='text-center' key={index} style={{backgroundColor:"#1B1B1B",color:'#fff'}}>
    <td  style={{backgroundColor:"#1B1B1B",color:'#fff'}}>{item.COMPANY}</td>
    <td  style={{backgroundColor:"#1B1B1B",color:'#fff'}}>{item.Exchange}</td>
    <td style={{backgroundColor:"#1B1B1B",color:'#fff'}}>{item.Industry}</td>
    <td  style={{backgroundColor:"#1B1B1B",color:'#fff'}}>{item.StockScore}</td>
    <td  style={{backgroundColor:"#1B1B1B",color:'#fff'}}>{item.MarketCap}</td>
    <td  style={{backgroundColor:"#1B1B1B",color:'#fff'}}>{item.Price}</td>
    <td  style={{backgroundColor:"#1B1B1B",color:'#fff'}}>{item.D}</td>
    <td  style={{backgroundColor:"#1B1B1B",color:'#fff'}}>{item.EBITDA}</td>
    <td  style={{backgroundColor:"#1B1B1B",color:'#fff'}}>{item.PE}</td>
    <td  style={{backgroundColor:"#1B1B1B",color:'#fff'}}>{item.DE}</td>
  </tr>
));

  return (
    <div>
        <Navbar />

        <div className='row mt-5 d-flex justify-content-center'>
        <div className="col-lg-12 col-md-12 herocard card mt-5 p-3" style={{backgroundColor:'#1B1B1B'}} >
                  <div className="mt-3 d-flex justify-content-between mb-3">
                    <div className="mt-2">
                    <h5 className="ms-2"><span style={{color:'#7E66BC'}}>Best</span><span style={{color:'#fff'}}> Stock Screener</span></h5>
                    </div>
                    <div className="d-flex ">
                        <div>
                    <input
                className="form-control ms-2 me-2 navsearch1"
                type="search"
                placeholder="Search"
                aria-label="Search"
                style={{ border: "0.5px solid #333333" ,backgroundColor:'#333333',borderRadius:'20px',color:'#fff' }}
              />
              </div>
              <div>
              <img src="/images/icons/setting.png" width={32} />
              </div>
              <div className="ms-2">
              <img src="/images/icons/notification.png" width={32} />
              </div>
                    </div>
                  </div>
                  <div className="row">
                    
                    <div className='ms-5 d-flex gap-2'>
                    <div>
                        <img src="/refresh.png" alt="refresh" height={25} width={25} />
                    </div>
                    {['overview', 'stock score', 'valuation', 'financials', 'Earnings & Revenue', 'Performance', 'Ownership', 'Forecast', 'Dividends'].map((item, index) => (
    <div key={index} className='text-white text-center' style={{border: "1.3px solid #464646", width: "fit-content", borderRadius: "10px", padding: '8px 20px',fontSize:"12px"}}>
        {item}
    </div>
))}
</div>
<div>
</div>

                  <div className="col-lg-12  col-md-12 mt-4 ">
               
       <div style={{border:'2px solid #242424',borderRadius:'10px'}}>

                    <table
                      class="table mt-2 table-responsive"
                      style={{
                        textAlign: "start",
                        backgroundColor: "#1B1B1B",
                        borderRadius: "10px",
                        overflow: "hidden", 
                        border:'1.5px solid #484848',
                        fontSize:"12px"
                      }}
                    >
                      <thead style={{ backgroundColor: "#1B1B1B" }}>
                        <tr className='text-white' >
                          <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B"}}>TICKER/COMPANY</th>
                          <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B"}}>Exchange</th>
                          <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B"}}>Industry</th>
                          <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B"}}>Stock Score</th>
                          <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B"}}>Market Cap</th>
                          <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B"}}>Price</th>
                          <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B"}}>1D%</th>
                          <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B"}}>EBITDA</th>
                          <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B"}}>P/E</th>
                          <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B"}}>D/E</th>
                        </tr>
                      </thead>
                      <tbody>
                      {repeatedItems}
                      </tbody>
                     
                    </table>
                    </div>
                  </div>
                </div>
                
                </div>
                </div>
            
    </div>
  )
}

export default Bestscreenner
