import React ,{useState ,useEffect,useContext } from 'react';
import "./css/login.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import FetchData from '../../fetchapi/Apifetch';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../Utills/UserContext';
import { signInWithEmailAndPassword,signInWithPopup } from 'firebase/auth';
import { auth ,googleAuthProvider} from "../../../firebase";



const Forgotpassword = () => {
    const { theme, setTheme } = useContext(UserContext);

  const [buttonclick, setbuttonclick] = useState(true);
  const [email, setEmail] = useState('');

  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [formdata1, setFormdata1] = useState({
    email: "",  
  });


  useEffect(() => {
    if (formdata1.email > 0 ) {
      setbuttonclick(false); // Enable button when both fields have input
    } else {
      setbuttonclick(true); // Disable button if any field is empty
    }
  }, [formdata1.email]);




  const handeforgotpassword = async() => {
    try {
      const token = false;
      const res = await FetchData("resendotp", 'POST', JSON.stringify(formdata1), token, false);
      if (res.data) {
         navigate("/otpverification", { state: { data:res?.data?.email,user:true} });
      }
  } catch (error) {
      toast.error(error.message);
  }
  
  
  }






  return (
    <>
    <div className={`container-fluid mt-5 ${theme?'text-white':'text-black'}`} style={{width:"80%",border:"1px solid #ededed"}}>
    <ToastContainer />
        <div className='row'>
        <div className='col-lg-6 col-md-6 col-sm-12  d-flex align-items-center bg-white'
        style={{
          background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(/images/islamic/webdesign.png) no-repeat center center /cover"
       ,borderLeft:'2px solid #5b298c',borderTop:'2px solid #5b298c',borderBottom:'2px solid #5b298c'}}
       >
        <div className='p-4 w-100'>
        <h3 className='' style={{marginTop:"-10%"}}>Forgot Your Password? </h3>
        <p className='mb-5'>Don’t worry, happens to all of us. Enter your email below 
        to recover your password</p>
    <div class="mb-3 mt-3 ">
    <label for="exampleInputEmail1" class="form-label">Email</label>
     <input
     type="email"
     class="form-control"
     id="exampleInputEmail1"
     aria-describedby="emailHelp"
    onChange={(e) => {
    setFormdata1((prevData) => ({
      ...prevData, // Keep existing values
      email: e.target.value, // Update the email field
    }));
  }}
/>
  </div>  
  <button class="btn btn-primary w-100 mt-5"   style={{backgroundColor:"#7E66BC"}}  onClick={()=>{handeforgotpassword()}}>SUBMIT</button>
 
    </div>
        </div>
        <div className='col-lg-6 col-md-6 col-sm-12 p-0 img-layout' >
        <img src="images/loginpage.png" alt='logo' style={{width:"100%",height:"100%"}} /> 
          <div className='text-overlay'>
          <img src="logonew.png" alt='logo' className='mt-1' style={{width:"130px",height:"130px"}} /> 
             <img src={"images/app/itg-logo1.png"}  alt="Logo" style={{width:"280px",height:"60px"}} />
          <p className='paratext px-4'>
          At Islamic Tijarat , we redefine what it means to invest with faith. Our industry-leading Shariah screening technology and uncompromising compliance standards make your investment journey seamless and precise, so you never have to choose between profits and principles.
Pure Profits. Absolute Peace of Mind.
Invest the Right Way—Shariah Compliant, Always. </p>
          <div className='mt-3'>
          <div className={`${theme ? "social-icons-dark" : "social-icons"} mb-4  mt-0`}>
          <a href="https://www.facebook.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/facebook.png' className='p-2 ' style={{backgroundColor:"white",borderRadius:'50%'}}  width={38} height={38} />
            </a>
            <a href="https://www.instagram.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/insta.png' className=' p-2 ' style={{backgroundColor:"white",borderRadius:'50%'}}    width={40} height={40} />
            </a>
            <a href="https://x.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/twitter.png'  className=' p-2 '  style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
            <a href="https://www.youtube.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/youtube.png' className='  p-2 '  style={{backgroundColor:"white",borderRadius:'50%'}}   width={35} height={35} />
            </a>
            <a href="https://www.linkedin.com/in/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/linkedin.png'   className='p-2' style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
            <a href="https://whatsapp.com/channel/0029VakHKaFJENy4EzxloA13" target="_blank" rel="noopener noreferrer">
            <img src='images/whatsapp.png' className='p-2'   style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
            <img src='images/islamic/telegram.png' className='p-2'   style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
          </div>
          <p className='paratext mt-3'>Don’t have an account</p>
          <a className='paratext btn-card text-white' onClick={()=>{window.location.href="/register"}} >Sign Up?</a>
          </div>
          </div>
        </div>
        </div>
    </div>


    
    </>
  )
}

export default Forgotpassword