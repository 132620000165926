import React, { useState, useEffect, useRef, useContext } from "react";
import FetchData from "../../fetchapi/Apifetch";
import "../style.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import { json, useLocation, useNavigate } from "react-router-dom";
import News from "../Detailpage/News";
import Fundemental from "../Detailpage/Fundemental";
import Techenical from "../Detailpage/Techenical";
import Swot from "../Detailpage/Swot";
import Advancegraph from "../Detailpage/Advancegraph";
import Profilechart from "../Detailpage/Profilechart";
import Chart from "../Detailpage/Chart";
import GaugeChart from 'react-gauge-chart';
import { UserContext } from "../../Utills/UserContext";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Newspage from "../Detailpage/News"
import Linelist from "../Detailpage/Linelist";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Qvtstocks from "../Detailpage/Qvtstocks";
import Overview from "../Detailpage/Overview";
import TechnicalsPage from "../Detailpage/Techinicalpage2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StarRating from "../Detailpage/StarRating";
import "./stock.css"
import Premium from "../commingsoon/perimum";
import Premiumplus from "../Premiumplus/Premiumplus";
const Newstock = () => {
  const navigate = useNavigate();
  const [buttonclick, setbuttonclick] = useState(true);
  const [buttonclickcreate, setbuttonclickcreate] = useState(true);
  const { theme, setTheme } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [startvalue, setStartvalue] = useState(false);
  const [information, setinformation] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [istab, settab] = useState(window.innerWidth <= 900);
  const OpenmodalBtn = useRef(null)
  const [compliancedata, setcompliancedata] = useState('');
  const profiledetails = JSON.parse(localStorage.getItem("email"));
  const [watchlistcategory, setwatchlistcategory] = useState('');
  const location = useLocation();
  const widgetRef = useRef(null);
  const [loader, setloader] = useState(false);
  const [data, setData] = useState();
  const [liveprice, setliveprice] = useState(0);
  const [Stockdata1, setStockdata1] = useState([]);
  const [doubtfull, setDoubtfull] = useState(0);
  const [halal, setHalal] = useState(0);
  const [haram, setHaram] = useState(0);
  const [isOpen, setIsOpen] = useState(true);
  const [watchlist, setwatchlist] = useState(false);
  const [screen, setScreen] = useState("OVERALL");
  const [Stockscreener, setStockscreener] = useState("");
  const [chartvalue, setchartvalue] = useState("Business");
  const [page, setPage] = useState(1);
  const [scrollTop, setScrollTop] = useState(false);
  const [scrollBottom, setScrollBottom] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [categorymodel, setCategorymodel] = useState(false);
  const [createcategorymodel, setcreatecategorymodel] = useState(false);
  const [formdata, setFormdata] = useState({
    user_id: profiledetails._id,
    stock_id: '',
    description: '',
    date_time: '',
  });
  const [formdata2, setFormdata2] = useState({
    user_id: profiledetails._id,
    stock_id: ""
  });
  const [formdata4, setFormdata4] = useState({
    user_id: profiledetails._id,
    title: "",
    status: 1
  });
  const [formdata1, setFormdata1] = useState({
    user_id: profiledetails._id,
    watchlist_cat_id: "",
    stock_symbol: "",
    status: 1,
  });
  const [selectedCategories, setSelectedCategories] = useState([]);




  useEffect(() => {
    if (formdata4?.title.length > 0 ) { 
      setbuttonclickcreate(false); // Enable button when both fields have input
    } else {
      setbuttonclickcreate(true); // Disable button if any field is empty
    }
  }, [formdata4?.title]);
  

  useEffect(() => {
    if (selectedCategories.length > 0 ) { 
      setbuttonclick(false); // Enable button when both fields have input
    } else {
      setbuttonclick(true); // Disable button if any field is empty
    }
  }, [selectedCategories]);

  

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const Handlelocal = (item) => {
    let watchlistStored = JSON.parse(localStorage.getItem("watchlist")) || [];
    if (!Array.isArray(watchlistStored)) {
      watchlistStored = [];
    }
    const foundItemIndex = watchlistStored.findIndex(
      (storedItem) => storedItem.screenerData.stock === item?.screenerData.stock
    );
    if (foundItemIndex === -1) {
      watchlistStored.push(item);
      localStorage.setItem("watchlist", JSON.stringify(watchlistStored));
      Getvalue(item);
    } else {
      watchlistStored.splice(foundItemIndex, 1);
      localStorage.setItem("watchlist", JSON.stringify(watchlistStored));
      Getvalue();
    }
  };
  const Compliancefield2 = (value) => {
    return (
      <section _ngcontent-qal-c148="" className={`section mt-2 mb-2 card p-2 ${theme ? "text-white " : "text-black"} text-center`} style={{ backgroundColor: theme ? '#1B1B1B' : '#fff', borderRadius: '10px' }} >
        {chartvalue == "Business" && (
          <div>
            <header _ngcontent-qal-c165="" className="align-items-center justify-content-between header p-1" >
              <div>
                <h5 _ngcontent-qal-c165="" className="mt-2 mb-0 section-heading mobile-font"
                >
                  Compliance Status
                </h5>
              </div>
              <div className="mt-4">
                {value?.screenerData?.status == 1 ? (
                  <button className="btn btn-success" style={{ fontSize: "16px" }}>
                    Shariah compliant
                  </button>
                ) : value?.screenerData?.status == 2 || value?.screenerData?.status == 4 || value?.screenerData?.status == 6 || value?.screenerData?.status == 7 ? (
                  <button className="btn btn-danger" style={{ fontSize: "16px" }}>
                    Non  Shariah compliant
                  </button>
                ) :
                  value?.screenerData?.status == 3 ?
                    (<button className="btn" style={{ fontSize: "16px", backgroundColor: 'darkblue', color: 'white' }}>
                      Unknown
                    </button>
                    )

                    :
                    <button className="btn btn-warning" style={{ fontSize: "16px" }}>
                      Under Review
                    </button>}
              </div>
            </header>
            {compliancedata.length !== 0 ? (
              <div className="text-center mt-1 p-2" style={{ fontSize: "14px" }}>
                <p className="fw-bold fs-4">Thank you!</p>
                <p className="fw-bold fs-5">
                  your request has been submitted successfully
                </p>
              </div>
            ) : (
              <div className="text-center mt-1 p-2" style={{ fontSize: "14px" }}>
                {value?.screenerData?.command}
              </div>
            )}
            <div className="text-center mt-1 p-2" style={{ fontSize: "16px" }}>
              ITG Compliance Ratings <span onMouseEnter={() => { setinformation(true) }} onMouseLeave={() => { setinformation(false) }} >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
              </span>
              {information &&
                <div className="p-2 card" style={{ position: 'absolute', color: theme ? "white" : 'black', fontWeight: '500', fontSize: '14px', width: "300px", zIndex: '9999', backgroundColor: theme ? "#6c757d" : "#dee2e6" }} >
                  A proprietary feature of ITG, our compliance rating offers an accurate measure of a company’s adherence to Shariah principles. Each stock is meticulously evaluated using ITG’s unique criteria, ensuring complete alignment with Islamic values. Invest confidently, knowing every rating reflects true Shariah compliance.
                </div>
              }

              <div className="d-flex justify-content-center text-center mt-3"
                onMouseEnter={() => { setStartvalue(true) }}
                onMouseLeave={() => { setStartvalue(false) }}>
                <StarRating value={value?.screenerData?.rating} theme={theme} />
                {startvalue &&
                  <div className={`mt-5 pt-1`} style={{ position: 'absolute', color:theme? "#7E66BC":'#5b298c', fontWeight: '600' }} >
                    {(Math.round(value?.screenerData?.rating * 100) / 100).toFixed(1)} <span style={{ fontWeight: '400', color: 'gray' }}>/ 5</span>
                  </div>
                }

              </div>
            </div>
            {compliancedata.length !== 0 ? (
              <div className="text-center mt-1 p-2" style={{ fontSize: "14px" }}>
              </div>
            ) : screen === 'COMPLIANCE' ? (
              <div className="mt-5 mb-2 d-flex justify-content-center" data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{ cursor: "pointer" }}>
              </div>
            ) : value?.screenerData?.status == 3 ? (
              <div className="mt-5 mb-2 d-flex justify-content-center" data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{ cursor: "pointer" }}>
                <p className={`p-1 ${theme ? "text-white" : "text-black"}`} style={{ border: theme ? "1px solid #fff" : "1px solid black", width: "70%", borderRadius: "5px", fontSize: isMobile ? "14px" : '' }}>
                  Report a stock
                </p>
              </div>
            ) :
              (
                <div className="mt-5 mb-2 d-flex justify-content-center" onClick={() => { setScreen('COMPLIANCE') }} style={{ cursor: "pointer" }}>
                  <p className={`p-1 ${theme ? "text-white" : "text-black"}`} style={{ border: theme ? "1px solid #fff" : '1px solid black', width: '70%', borderRadius: "5px", fontSize: isMobile ? "14px" : '' }}>
                    View Report
                  </p>
                </div>
              )}
          </div>
        )}
        {chartvalue == "Debt" && (
          <div className='' style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
            <div
              className="accordion"
              id="accordionExample"
              style={{
                backgroundColor: theme ? "#1B1B1B" : "#fff",
                borderColor: theme ? "#1B1B1B" : "",
              }}
            >
              <div
                className="accordion-item"
                style={{
                  backgroundColor: theme ? "#1B1B1B" : "#fff",
                  borderColor: theme ? "#1B1B1B" : "",
                }}
              >
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className={`accordion-button ${isOpen ? "" : "collapsed"}`}
                    type="button"
                    onClick={toggleAccordion}
                    aria-expanded={isOpen}
                    aria-controls="collapseTwo"
                    style={{
                      backgroundColor: theme ? "#7E66BC" : isOpen ? "rgb(126, 102, 188)" : "#fff",
                      color: theme ? "#fff" : isOpen ? '#fff' : "black",
                      borderColor: theme ? "#1B1B1B" : "",
                    }}
                  >
                    Calculation
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    className="accordion-body p-1"
                    style={{
                      backgroundColor: theme ? "#1B1B1B" : "#fff",
                      color: theme ? "#fff" : "black",
                    }}
                  >
                    <div className="d-flex justify-content-center mt-4">
                      <div
                        className="p-1"

                      >

                        <img src={theme ? "images/debt-dark.png" : "images/debt-light.png"} style={{ width: '100%' }} />

                      </div>
                    </div>

                    <div className="text-center mt-4 p-1" style={{ fontSize: "14px" }}>
                      The total interest-bearing debt—including bank loans, bonds, debentures, mortgages, convertible debt, and any other interest-based borrowings—must not exceed 25% of the total assets. This restriction ensures that the company maintains limited exposure to all forms of interest-based liabilities and complies with Shariah principles by avoiding excessive reliance on riba-based financing.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {chartvalue == "Total" && (
          <div className='' style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
            <div
              className="accordion"
              id="accordionExample"
              style={{
                backgroundColor: theme ? "#1B1B1B" : "#fff",
                borderColor: theme ? "#1B1B1B" : "",
              }}
            >
              <div
                className="accordion-item"
                style={{
                  backgroundColor: theme ? "#1B1B1B" : "#fff",
                  borderColor: theme ? "#1B1B1B" : "",
                }}
              >
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className={`accordion-button ${isOpen ? "" : "collapsed"}`}
                    type="button"
                    onClick={toggleAccordion}
                    aria-expanded={isOpen}
                    aria-controls="collapseTwo"
                    style={{
                      backgroundColor: theme ? "#7E66BC" : isOpen ? "rgb(126, 102, 188)" : "#fff",
                      color: theme ? "#fff" : isOpen ? '#fff' : "black",
                      borderColor: theme ? "#7E66BC" : "",
                    }}
                  >
                    Calculation
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    className="accordion-body p-1"
                    style={{
                      backgroundColor: theme ? "#1B1B1B" : "#fff",
                      color: theme ? "#fff" : "black",
                    }}
                  >
                    <div className="d-flex justify-content-center mt-4">
                      <div
                        className="p-1"

                      >

                        <img src={theme ? "images/income-dark.png" : "images/income-light.png"} style={{ width: '100%' }} />
                      </div>
                    </div>

                    <div className="text-center mt-4 p-1" style={{ fontSize: "14px" }}>
                      Total interest income, which includes earnings from bank deposits, bonds, interest-bearing sukuk, lending interest, dividends from non-compliant investments, interest from bill discounting, lease-related interest, and any other form of interest-based income, must not exceed 2.5% of the total income.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        )}
        {chartvalue == "Liquid" && (
          <div className='' style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
            <div
              className="accordion"
              id="accordionExample"
              style={{
                backgroundColor: theme ? "#1B1B1B" : "#fff",
                borderColor: theme ? "#1B1B1B" : "",
              }}
            >
              <div
                className="accordion-item"
                style={{
                  backgroundColor: theme ? "#1B1B1B" : "#fff",
                  borderColor: theme ? "#1B1B1B" : "",
                }}
              >
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className={`accordion-button ${isOpen ? "" : "collapsed"}`}
                    type="button"
                    onClick={toggleAccordion}
                    aria-expanded={isOpen}
                    aria-controls="collapseTwo"
                    style={{
                      backgroundColor: theme ? "#7E66BC" : isOpen ? "rgb(126, 102, 188)" : "#fff",
                      color: theme ? "#fff" : isOpen ? '#fff' : "black",
                      borderColor: theme ? "#7E66BC" : "",
                    }}
                  >
                    Calculation
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div
                    className="accordion-body p-1"
                    style={{
                      backgroundColor: theme ? "#1B1B1B" : "#fff",
                      color: theme ? "#fff" : "black",
                    }}
                  >
                    <div className="d-flex justify-content-center mt-4">
                      <div
                        className="p-1"

                      >

                        <img src={theme ? "images/recive-dark.png" : "images/recive-light.png"} style={{ width: '90%' }} />
                      </div>
                    </div>

                    <div className="text-center mt-4 p-1" style={{ fontSize: "14px" }}>
                      Liquid assets—including cash, bank balances, accounts receivable, short-term investments, and any other easily convertible assets—must not exceed 90% of total assets. This condition prevents excessive reliance on liquid holdings and speculative activities, ensuring that the company’s capital is used productively and in alignment with Shariah principles.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    );
  }
  const Compliancefield = (value) => {
    return (
      <section _ngcontent-qal-c148="" className={`section mt-2 mb-2 card p-2 ${theme ? "text-white " : "text-black"} text-center`} style={{ backgroundColor: theme ? '#1B1B1B' : '#fff', borderRadius: '10px' }} >
        <header _ngcontent-qal-c165="" className="align-items-center justify-content-between header p-1" >
          <div>
            <h5 _ngcontent-qal-c165="" className="mt-2 mb-0 section-heading mobile-font"
            >
              Compliance Status
            </h5>
          </div>
          <div className="mt-4">

            {value?.screenerData?.status == 1 ? (
              <button className="btn btn-success" style={{ fontSize: "16px" }}>
                Shariah compliant
              </button>
            ) : value?.screenerData?.status == 2 || value?.screenerData?.status == 4 || value?.screenerData?.status == 6 || value?.screenerData?.status == 7 ? (
              <button className="btn btn-danger" style={{ fontSize: "16px" }}>
                Non  Shariah compliant
              </button>
            ) :
              value?.screenerData?.status == 3 ?
                (<button className="btn" style={{ fontSize: "16px", backgroundColor: 'darkblue', color: 'white' }}>
                  Unknown
                </button>
                )

                :
                <button className="btn btn-warning" style={{ fontSize: "16px" }}>
                  Under Review
                </button>}
          </div>
        </header>

        {compliancedata.length !== 0 ? (
          <div className="text-center mt-1 p-2" style={{ fontSize: "14px" }}>
            <p className="fw-bold fs-4">Thank you!</p>
            <p className="fw-bold fs-5">
              your request has been submitted successfully
            </p>
          </div>
        ) : (
          <div className="text-center mt-1 p-2" style={{ fontSize: "14px" }}>
            {value?.screenerData?.command?.replace("string_to_remove", "")}
          </div>
        )}
{/* <div style={{ 
    position: 'relative', 
    justifyContent: 'center', 
    alignItems: 'center',
    backgroundColor: theme ? '#1B1B1B59' : '#fff',
}}>
   <div style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(13px)',
      zIndex: 1,
  }}></div>
  <div className="pointer" style={{
      position: 'relative', 
      zIndex: 2, 
      top:80,
      color: theme ? 'white' : 'black',
      fontSize: '14px', 
      textAlign: 'center',
  }}>
    <i className="fa fa-lock me-2"/>
    Upgrade & Unlock
  </div> */}
  
        <div className="text-center mt-1 p-2" style={{ fontSize: "16px" }}>
          ITG Compliance Ratings <span onMouseEnter={() => { setinformation(true) }} onMouseLeave={() => { setinformation(false) }}
          > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
            </svg>
          </span>
          {information &&
            <div className="p-2 card" style={{ position: 'absolute', color: theme ? "white" : 'black', fontWeight: '500', fontSize: '14px', width: "300px", zIndex: '9999', backgroundColor: theme ? "#6c757d" : "#f0edf7" }} >
              A proprietary feature of ITG, our compliance rating offers an accurate measure of a company’s adherence to Shariah principles. Each stock is meticulously evaluated using ITG’s unique criteria, ensuring complete alignment with Islamic values. Invest confidently, knowing every rating reflects true Shariah compliance.
            </div>
          }
          <div className="d-flex justify-content-center text-center mt-3 pointer"
            onMouseEnter={() => { setStartvalue(true) }}
            onMouseLeave={() => { setStartvalue(false) }}
          >
            <StarRating value={value?.screenerData?.rating}  theme={theme} />
            {startvalue &&
              <div className={`mt-5 pt-2`} style={{ position: 'absolute', color:theme? "#7E66BC":'#5b298c', fontWeight: '600' }} >
                {(Math.round(value?.screenerData?.rating * 100) / 100).toFixed(1)} <span style={{ fontWeight: '400', color: 'gray' }}>/ 5</span>
              </div>
            }
          </div>
        </div>

        {compliancedata.length !== 0 ? (
          <div className="text-center mt-1 p-2" style={{ fontSize: "14px" }}>
          </div>
        ) : screen === 'COMPLIANCE' ? (
          <div className="mt-5 mb-2 d-flex justify-content-center" data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{ cursor: "pointer" }}>
          </div>
        ) : value?.screenerData?.status == 3 ? (

          <div className="mt-5 mb-2 d-flex justify-content-center" data-bs-toggle="modal" data-bs-target="#exampleModal1" style={{ cursor: "pointer" }}>
            <p className={`p-1 custom-button  ${theme ? "text-white" : "text-black"}`} style={{ border: theme ? "1px solid #7E66BC" : '1px solid #5b298c', width: '50%', borderRadius: "5px", fontSize: isMobile ? "14px" : '' }}>
            Report a stock
            </p>
          </div>
        ) : (
          <div className="mt-5 mb-2 d-flex justify-content-center" onClick={() => { setScreen('COMPLIANCE') }} style={{ cursor: "pointer" }}>
            <p className={`p-1 custom-button  ${theme ? "text-white" : "text-black"}`} style={{ border: theme ? "1px solid #7E66BC" : '1px solid #5b298c', width: '50%', borderRadius: "5px", fontSize: isMobile ? "14px" : '' }}>
            View Report
            </p>
          </div>
        )}
        {/* </div> */}
      </section>
    );
  }
  useEffect(() => {
    setloader(false);
    setLoading(false)
    window.scrollTo(0, 0);
    setData(location?.state?.value);
  }, [location?.state?.value]);
  useEffect(() => {
    setHalal(0);
    const fetchData = async () => {
      setloader(true)
      setLoading(true)
      window.scrollTo(0, 0);
      const res = await FetchData(
        `stockbasedlist?q=${location?.state?.data}`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
        setData(res.data.data);
        setloader(false);
        setLoading(false)
      }
    };
    if (location?.state?.data) {
      fetchData();
    }

  }, [location?.state?.data]);

  const fetchDatastock = async (item) => {
    setloader(true);
    setLoading(true);

    // Scroll to the top of the page
    window.scrollTo(0, 0);

    try {
      const res = await FetchData(
        `stockbasedlist?q=${item}`,
        "GET",
        null,
        true,
        false
      );

      if (res) {
        setData(res.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setloader(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    const encodedQuery = encodeURIComponent(data?.screenerData?.industry_group);
    const fetchData1 = async () => {
      const res = await FetchData(
        `industrybasestock?q=${encodedQuery}`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
        setStockdata1(res.data.data);
        setLoading2(false)
      }
    };
    if (data?.screenerData?.industry_group) {
      fetchData1();
    }
  }, [data]);

  useEffect(() => {
    const stockdetails = async () => {
      const res = await FetchData(
        `stockdetail/${data?.stockData.stock_code}`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
        if (res.data?.close) {
          // setliveprice(res.data?.performance?.prev_close);
          setliveprice(res.data?.close);
          // setStockdata1(res.data.data);
        } else {
          setliveprice('');
        }
      }
    };
    if (data?.stockData?.stock_code) {
      stockdetails();
    }
  }, [data]);




  const FormatDateVALUE = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;

  };


  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad it with a leading zero if necessary
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the month (0-based index, so we add 1) and pad it
    const year = today.getFullYear(); 
  
    return `${day}-${month}-${year}`; 
  };

  const handleScroll = () => {
    const isAtTop = window.scrollY < 220;
    // const isAtBottom = window.innerHeight + window.scrollY >= document.documentElement.scrollHeight;
    const isAtBottom = window.scrollY >= 235;
    setScrollTop(isAtTop);
    setScrollBottom(isAtBottom);
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const stockvalue = data?.stockData?.stock_code

  useEffect(() => {
    if (data) {
      const stockValue = data?.stockData?.stock_code;
      if (stockValue && widgetRef.current) {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-profile.js';
        script.async = true;
        script.innerHTML = JSON.stringify({
          "width": "100%",
          "height": "auto",
          "isTransparent": true,
          "colorTheme": "dark",
          "symbol": stockValue,
          "locale": "en"
        });
        widgetRef.current.appendChild(script);
        return () => {
          widgetRef.current.innerHTML = '';
        };
      }
    }
  }, [data]);
  const Imageview = ({ item }) => {
    const [imageError, setImageError] = useState(false);
    const stock = item?.screenerData?.stock;
    const splitStock = (stock) => {
      if (!stock) return '';
      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');
      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';
      return `${firstChar}${secondChar}`.toUpperCase(); // Convert to uppercase if needed
    };
    return (
      <div>
        {/* {!imageError ? (
          <img
            src={item?.stockData?.img || ""}
            alt="img"
            className='img-fluid rounded-circle bg-light stock-image'
            style={{
              height: isMobile ? "50px" : "65px",
              width: isMobile ? "50px" : "65px",
              borderRadius: "50%",
              border: "0.5px solid #fff",
              marginTop: '-5%'
            }}
            onError={() => setImageError(true)}
          />
        ) : ( */}
          <div
            style={{
              marginTop: '-5%',
              height: isMobile ? "50px" : "60px",
              width: isMobile ? "50px" : "60px",
              borderRadius: "50%",
              border:  theme ? "2px solid #7E66BC":"2px solid #5b298c",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: 'black',
              // fontWeight:500,
              backgroundColor: "#f0edf7",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {splitStock(item?.screenerData?.stock)}
          </div>
        {/* )} */}
      </div>
    );
  };
  const Cardimage = ({ value }) => {
    const stockvalue = value?.screenerData.stock;
    const [Errorimage, setErrorImage] = useState(false);
    const splitStockname = (stockvalue) => {
      if (!stockvalue) return '';
      const lettersOnlyRegexvalue = /^[A-Za-z]/;
      const wordsvalue = stockvalue.split(' ');
      const firstletter = wordsvalue[0]?.charAt(0).match(lettersOnlyRegexvalue) ? wordsvalue[0].charAt(0) : '';
      const secondletter = wordsvalue[1]?.charAt(0).match(lettersOnlyRegexvalue) ? wordsvalue[1].charAt(0) : '';
      return `${firstletter}${secondletter}`.toUpperCase(); // Convert to uppercase if needed
    };
    return (
      <div className="d-flex justify-content-center mt-3 mb-2">
        {/* {Errorimage ? ( */}
            {/* <div
            style={{
              height: "40px",
              width: "43px",
              borderRadius: "5px",
              border: theme ? "" : "0.5px solid #E9E9E9",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              // fontWeight:500,
              backgroundColor: theme ? "#D9D9D9" : "#e6e6e6b0",
            }}>
            <img
              src={value?.stockData?.img || ""}
              alt="img"
              className='img-fluid rounded-circle bg-light stock-image'
              style={{
                height: "26px",
                width: "26px",
              }}
              onError={() => setErrorImage(true)}
            />
          </div>
       */}
        {/* ) : ( */}
          <div
          style={{
            height: "40px",
            // marginTop:'-5%',
            width: "43px",
            borderRadius: "5px",
            border: theme ? "1.5px solid #7E66BC" : "1.5px solid #5b298c",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: 'black',
            // fontWeight:500,
            backgroundColor:"#f0edf7",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {splitStockname(value?.screenerData?.stock)}
        </div>
        
         
        {/* ) */}
        {/* } */}
      </div>
    );
  };

  useEffect(() => {
    Getvalue(data);
  }, [data]);
  const Getvalue = (data) => {
    const watchlistStored = JSON.parse(localStorage.getItem("watchlist")) || [];
    const foundItem = watchlistStored.find(item => item.screenerData.stock === data?.screenerData.stock);
    if (foundItem) {
      setwatchlist(true)
    } else {
      setwatchlist(false)
    }
  }

  useEffect(() => {
   
    const nsiNumber = data?.screenerData?.intIncInv == null
    ? 0
    : isNaN(parseFloat(data?.screenerData?.intIncInv))
    ? -1
    : parseFloat(data?.screenerData?.intIncInv);
      console.log('nsiNumber',nsiNumber)
    if (nsiNumber >= 0 && nsiNumber < 2.5) { // Ensure nsiNumber is between 0 and 2.5
      const valuefilter = parseFloat((2.5 - nsiNumber).toFixed(2));
      setDoubtfull(valuefilter);
      const haramValue = parseFloat(nsiNumber.toFixed(2));
      setHaram(haramValue);
      const addValueHalal = parseFloat((valuefilter + nsiNumber).toFixed(2));
      setHalal(parseFloat((100 - addValueHalal).toFixed(2)));
    } 
    else if (nsiNumber > 2.5) {
      setDoubtfull(2.5);
      const valuefilter = parseFloat((nsiNumber - 2.5).toFixed(2));
      setHaram(valuefilter);
      const valueHalal = parseFloat((100 - (2.5 + valuefilter)).toFixed(2));
      setHalal(valueHalal);
    }
    else if (nsiNumber === -1) {
  setHalal(96.5)
      // Handle the invalid case if needed
    }
  }, [data, location?.state?.data, location?.state?.value]);
  

  console.log('halal',halal)
  console.log('doubtfull',doubtfull)
  console.log('haram',haram)
  const filteredItems = Array.isArray(Stockdata1) ? Stockdata1?.filter((item) => item?.screenerData?.industry_group === data?.screenerData?.industry_group)
    .filter((item) => item?.screenerData?.stock !== data?.screenerData?.stock)
    .filter((item) => item?.screenerData?.status == 1)
    .slice(0, 20)
    : [];

  const Listcompliant = async (item) => {
    const valuestockid = item?.stockData?._id
    var ObjectValue = { ...formdata2, ["stock_id"]: valuestockid ? valuestockid : data?.stockData?._id }
    try {
      const res = await FetchData("getcompliant", 'POST', JSON.stringify(ObjectValue), true, false);
      if (res.success) {
        setcompliancedata(res.data)

      } else {
        console.error("Please Check Crediential")
      }
      return null;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  const getCurrentDateTime = () => {
    const now = new Date();
    const formattedDateTime = now.toLocaleString();
    setCurrentDateTime(formattedDateTime);
  };

  useEffect(() => {
    getCurrentDateTime();
  }, []);


  const stockcodeall = data?.stockData?._id || '';
  useEffect(() => {
    setFormdata((prevFormData) => ({
      ...prevFormData,
      stock_id: stockcodeall,
      date_time: currentDateTime,
    }));
  }, [currentDateTime, stockcodeall]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;;
    setFormdata({ ...formdata, [name]: value });
  };
  const Handelsetlocaldata = (item) => {
    setcompliancedata('')
    // setData(item)
    Getvalue(item)
    Listcompliant(item)
    fetchDatastock(item?.screenerData?.isin)
    // window.location.reload();
  }

  function formatNumber(value) {
    // Check if the input is a number and not NaN
    if (typeof value !== 'number' || isNaN(value)) {
      return '0'; // Handle non-number inputs
    }
    if (value >= 1e9) {
      // If the value is a billion or more
      return (value / 1e9).toFixed(1) + " B";
    } else if (value >= 1e6) {
      // If the value is a million or more
      return (value / 1e6).toFixed(1) + " M";
    } else if (value >= 1e3) {
      // If the value is a thousand or more
      return (value / 1e3).toFixed(1) + " K";
    } else {
      // If the value is less than a thousand
      return value.toFixed(2);
    }
  }
  const Handlesendreport = async () => {
    try {
      const res = await FetchData("addcompliant", 'POST', JSON.stringify(formdata), true, false);
      if (res.success) {
        Listcompliant(formdata.stock_id);
        setFormdata({
          stock_id: '',
          description: '',
          date_time: '',
        });
        toast.success("Compliant send successfully");
      } else {
        console.error("Please Check Crediential")
      }
      return null;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  useEffect(() => {
    Listcompliant(data);
  }, [data]);

  const handlepagevalue = (item) => {
    setScreen(item);

  }
  const openCreateCategoryModal = () => {
    setCategorymodel(false);
    setcreatecategorymodel(true);
  };

  const handleInputChangevalue = (e) => {

    const { name, value } = e.target;
    setFormdata4({ ...formdata4, [name]: value });
  };

  const Handlewatchlistcategory = async () => {
    try {
      setcreatecategorymodel(false);
      setCategorymodel(true);
      const res = await FetchData("addwatchlistcat", 'POST', JSON.stringify(formdata4), true, false);
      if (res.success) {
        fetchData();
        setFormdata4(prevState => ({
          ...prevState,
          title: ""
        }));
        toast.success("Watchlist category created");
      } else {
        fetchData();
        setFormdata4(prevState => ({
          ...prevState,
          title: ""
        }));
        toast.error("Watchlist category  already exist");
      }
      return null;
    } catch (error) {
      toast.error("Watchlist category already exist");
      setFormdata4(prevState => ({
        ...prevState,
        title: ""
      }));
      console.error('Error:', error);
      return null;
    }
  }


  useEffect(() => {
    if (watchlistcategory) {
      const initialSelected = watchlistcategory
        .filter(category => category.stock_exists)
        .map(category => category._id);

      setSelectedCategories(initialSelected);
      const hasStock = watchlistcategory.some(category => category.stock_exists);
      if (hasStock) {
        setwatchlist(true);
      } else {
        setwatchlist(false);
      }
    }
  }, [watchlistcategory]);



  const handleCheckboxChange = (categoryId) => {
    setSelectedCategories(prevSelectedCategories => {
      if (prevSelectedCategories.includes(categoryId)) {
        return prevSelectedCategories.filter(id => id !== categoryId);
      } else {
        return [...prevSelectedCategories, categoryId];
      }
    });
  };

  const Stock_id = data?.stockData?._id
  const fetchData = async (c) => {
    const res = await FetchData(
      `watchlists/${profiledetails._id}/${Stock_id}`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      setwatchlistcategory(res.data)
    }
  };
  useEffect(() => {
    if (Stock_id) {
      fetchData()
    }
  }, [data]);
  const handleviewall = (item) => {
    navigate('/similarstock', { state: { value: item } });
  };

  const handlestockcategory = () => {
    fetchData()
    setCategorymodel(true)
  }


  const handleSubmit = async () => {
    setCategorymodel(false)
    const updatedFormData = {
      ...formdata1,
      watchlist_cat_id: selectedCategories,
      stock_symbol: data?.stockData?._id,
    };
    setFormdata1(updatedFormData);
    const dataObject = {
      ...updatedFormData,
    };
    try {
      const res = await FetchData("addmultiplewatchlist", 'POST', JSON.stringify(dataObject), true, false);
      if (res.success) {
        toast.success("Watchlist Updated");
        fetchData()
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error("Watchlist Already Entered");
      console.error('Error:', error);
    }
  };


  const headertop = {
    border: theme ? '2px solid #1B1B1B' : '',
    position: scrollBottom ? "sticky" : "relative",
    backgroundColor: theme ? '#1b1b1b' : '',
    // display: scrollTop ? "none" : 'block',
    height: '40px',
    top: isMobile ? scrollBottom ? '50px' : '0px' : scrollBottom ? '65px' : '0px',
    width: scrollBottom ? '100%' : '100%',
    marginBottom: '5px',
    zIndex: '99',
    // transition: 'top 1s cubic-bezier(0.4, 0.0, 0.2, 1)'
  }

  return (
    <>
      <Navbar />
      <ToastContainer />
      <div className="">
        {/* <Premiumplus  showModal={true} /> */}
        <div
          _ngcontent-qal-c183=""
          className=""
          style={{ width: "95%", margin: 'auto' }}
        >
          <header
            _ngcontent-qal-c183=""
            className="stock-details-container overflow-hidden marginmobile"
            style={{
              border: theme ? '2px solid #1B1B1B' : '',
              position: 'none',
              backgroundColor: theme ? '#1b1b1b' : '#fff',
              height: "210px",
              // top: scrollTop ? '12%' : '65px',
              width: '100%',
              zIndex: '99',
              transition: 'top 0.50s cubic-bezier(0.4, 0.0, 0.2, 1)'
            }}
          >
            <app-details-header _ngcontent-qal-c183="" _nghost-qal-c141="">
              <div
                _ngcontent-qal-c141=""
                className="container-fluid details-wrapper"
                style={{ backgroundColor: theme ? "#1B1B1B" : "#fff" }}
              >
                <div
                  _ngcontent-qal-c141=""
                  className={`d-flex align-items-start mobile-display justify-content-between mb-0 inner-wrapper mb-4`}
                  style={{ transition: 'opacity 0.5s ease-out, transform 0.5s ease-out' }} // Add CSS transition
                >
                  <div
                    _ngcontent-qal-c141=""
                    className="intro-wrapper d-flex align-items-start"
                  >
                    <div _ngcontent-qal-c141="" className="stock-logo-container flex-shrink-0 ratio ratio-1x1 me-2 me-md-2" >
                      <Imageview item={data} />
                    </div>
                    <div _ngcontent-qal-c141="" className="company-width">
                      {loading ? (
                        <div  _ngcontent-qal-c141="" className="company-name-wrapper  align-items-end mb-2 mb-lg-3 flex-wrap ms-1" >
                          <Skeleton height={24} width={150} />
                          <Skeleton height={16} width={100} style={{ marginTop: '8px' }} />
                          <Skeleton height={28} width={200} style={{ marginTop: '8px' }} />
                        </div>
                      ) : (
                        <div  _ngcontent-qal-c141="" className="company-name-wrapper leftmargin align-items-end mb-2 mb-lg-3 flex-wrap ms-1" >
                          <h5 _ngcontent-qal-c141="" className={`mb-0 stock-name lh-1 me-2 ${theme ? "text-white" : 'text-black'}`}
                            style={{ fontSize: "24px", fontWeight: "500" }}
                          >
                            {data?.stockData?.stock_code}
                          </h5>
                          <h6 _ngcontent-qal-c141="" className="mb-0 ticker-name stock-code-mobile lh-l mt-2" style={{ color: "#ACACAC", fontWeight: "400", fontSize: '16px' }} >
                            {data?.stockData?.stock ? (
                            <span>{data.stockData.stock}</span>
                            ) : (
                              <span>{data?.screenerData?.stock}</span>
                            )}

                          </h6>
                          <h6 _ngcontent-qal-c141="" className="mb-0 ticker-name  lh-l mt-2" style={{ color: theme ? "#FFFFFF" : 'black', fontWeight: "600", fontSize: '28px' }} >
                            {liveprice ? ( <span style={{ fontFamily: "sans-serif" }}>₹{liveprice}</span>
                            ) : (
                             data?.stockData?.BSE_NSE
                            )}                          </h6>
                        </div>
                      )}
                      <div>
                      </div>
                      <div
                        _ngcontent-qal-c141=""
                        className="d-none d-lg-block ms-1"
                      >
                        {loading ? (
                          <Skeleton width={150} height={20} style={{ marginTop: '10px' }} />
                        ) : (
                          <p
                            className="mb-0 d-flex update-date align-items-center"
                            style={{ color: theme ? '#FBFBFB' : 'black', fontSize: '12px' }}
                          >
                            <span className="p-2" style={{ backgroundColor: theme ? "#7e66bc75" : '#f0edf7', borderRadius: '5px' }}>Market cap : {formatNumber(data?.TechinicalData?.data[0].market_cap)} </span>
                            <span className="p-2 ms-2" style={{ backgroundColor: theme ? "#7e66bc75" : '#f0edf7', borderRadius: '5px' }}>Average Volume 10 days :  {formatNumber(data?.TechinicalData?.data[0].Average_Volume_10_days)} </span>
                            <span className="p-2 ms-2" style={{ backgroundColor: theme ? "#7e66bc75" : '#f0edf7', borderRadius: '5px' }}>Beta 1 year : {formatNumber(data?.TechinicalData?.data[0].Beta_1_year)} </span>
                          </p>
                        )}
                        {loading ? (
                          <Skeleton width={150} height={20} style={{ marginTop: '10px' }} />
                        ) : (
                          <p
                            className="mb-0 d-flex update-date align-items-center mt-2"
                            style={{ color: theme ? '#FBFBFB' : 'black', fontSize: '14px' }}
                          >
                            <span>Last Updated: <span className="ms-1">{getCurrentDate()}</span></span>
                          </p>
                        )}
                      </div>
                      <div
                        _ngcontent-qal-c141=""
                        className="align-items-end gap-2 stock-price-details resp-slider d-lg-none"
                      >
                        <div className="d-flex">
                          <span
                            _ngcontent-qal-c141=""
                            className="d-flex align-items-center  resp-slider-item"
                          >
                            <span
                              _ngcontent-qal-c141=""
                              className="stock-price-changes lh-1 fs-5 ticker-name  positive text-green"
                            >
                              {" "}
                            </span>
                          </span>
                          <span
                            _ngcontent-qal-c141=""
                            className="d-flex align-items-center percent-change ticker-name  resp-slider-item "
                          >
                            <span
                              _ngcontent-qal-c141=""
                              className="stock-price-changes  lh-1 positive"
                            >
                              <p
                                className="d-flex update-date align-items-center"
                                style={{ color: theme ? '#FBFBFB' : 'black', fontSize: isMobile ? "10px" : '11px' }}
                              >
                                <span className="p-1" style={{ backgroundColor: theme ? "#7e66bc75" : '#0000ff26', borderRadius: '5px' }}>Market cap : {formatNumber(data?.TechinicalData?.data[0].market_cap)} </span>
                                <span className="p-1 " style={{ backgroundColor: theme ? "#7e66bc75" : '#0000ff26', borderRadius: '5px' }}>Beta 1 year : {formatNumber(data?.TechinicalData?.data[0].Beta_1_year)} </span>
                              </p>
                            </span>
                          </span>
                        </div>
                        <div>
                          <span className="p-1 " style={{ backgroundColor: theme ? "#7e66bc75" : '#0000ff26', borderRadius: '5px', fontSize: '10px', color: theme ? '#FBFBFB' : 'black' }}>Average Volume  10 days :  {formatNumber(data?.TechinicalData?.data[0].Average_Volume_10_days)} </span>
                        </div>
                      </div>
                    </div>
                    <div className="ms-3 d-lg-none tabview">
                      <i
                        className="fa fa-star me-2 sizestart"
                        onClick={() => {
                          handlestockcategory()
                        }}
                        style={{
                          color: watchlist
                            ? theme
                              ? 'rgb(126, 102, 188)'
                              : 'rgb(126, 102, 188)'
                            : theme
                              ? 'white'
                              : 'black',

                          display: 'block',
                          margin: 'auto',
                        }}
                      ></i>
                    </div>
                  </div>
                  <div
                    _ngcontent-qal-c141=""
                    className="watchlist-wrapper d-none d-lg-block"
                  >
                    <span
                      _ngcontent-qal-c141=""
                      className="d-flex fonttitle justify-content-end lh-1 positive stock-price-changes"
                    >
                      {" "}
                      <div className="">
                        {loading ? (
                          <Skeleton width={120} height={40} />
                        ) : (
                          <button
                            className="btn d-flex"
                            onClick={() => {
                              handlestockcategory()
                            }}
                            style={{
                              border: theme ? '1px solid #fff' : watchlist ? '' : '1px solid black',
                              backgroundColor: watchlist ? (theme ? '#7E66BC' : '#5b298c') : '',
                            }}
                          >
                            <i
                              className="fa fa-star me-2"
                              style={{
                                color: watchlist
                                  ? theme
                                    ? 'white'
                                    : 'white'
                                  : theme
                                    ? 'white'
                                    : 'black',
                                fontSize: '20px',
                                display: 'block',
                                margin: 'auto',
                              }}
                            ></i>
                            <span
                              style={{
                                color: watchlist
                                  ? theme
                                    ? 'white'
                                    : 'white'
                                  : theme
                                    ? 'white'
                                    : 'black',
                              }}
                            >
                              Watchlist
                            </span>
                          </button>
                        )}
                      </div>
                    </span>
                    <span className="d-flex align-items-center percent-change mt-5">

                      <img src="/images/up1.png" width="16px" className="m-1" />
                      <span
                        className="stock-price-changes lh-1 positive"
                        style={{
                          color: theme ? "#fff" : 'black',
                          fontSize: '14px',
                        }}
                      >52 Weeks High:
                        <span className="ms-2" style={{ fontSize: '16px', fontWeight: '500' }} >
                          {data?.TechinicalData?.data[0].Weeks_52_High?.toFixed(2)}
                        </span>
                      </span>

                      <img src="/images/icons/down.png" width="16px" className="m-1 ms-4  " />
                      <span
                        className="stock-price-changes lh-1 positive"
                        style={{
                          color: theme ? "#fff" : 'black',
                          fontSize: '14px',
                        }}
                      >
                        52 Weeks Low :
                        <span className="ms-2" style={{ fontSize: '16px', fontWeight: '500' }} >{data?.TechinicalData?.data[0].Weeks_52_low?.toFixed(2)}</span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </app-details-header>
          </header>
          <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header text-white"
                  style={{
                    background: "linear-gradient(90deg, rgba(126,102,188,1) 0%, rgba(154,123,187,1) 100%)",
                    borderBottom: "none",
                    borderRadius: "0.3rem 0.3rem 0 0",
                  }}
                >
                  <h5 className="modal-title" id="exampleModalLabel">
                    Confirmation
                  </h5>
                  <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" ></button>
                </div>
                <div
                  className="modal-body text-center"
                  style={{
                    padding: "2rem",
                    backgroundColor: "#F9F9F9",
                  }}
                >
                  <p style={{ fontSize: "1.1rem", marginBottom: "1rem", color: "#333", lineHeight: '30px' }}>Are you sure you want to delete this in your Watchlist ?</p>
                </div>
                <div className="modal-footer" style={{ borderTop: "none" }}>
                  <div className="d-flex justify-content-end gap-2 w-100">
                    <button
                      type="button"
                      className="btn btn-outline-secondary"
                      data-bs-dismiss="modal"
                      style={{ borderRadius: "0.3rem" }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-dismiss="modal"
                      style={{
                        background: "rgb(126, 102, 188)",
                        border: "none",
                        color: "#fff",
                        borderRadius: "0.3rem",
                        transition: "background 0.3s",
                      }}
                      onClick={() => {
                        Handlelocal(data);
                      }}
                    > Delete </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div >
            {/* style={{ marginTop: isMobile ? scrollTop ? "320px" : '80px' : scrollTop ? "500px" : '80px' }} */}
            <header
              className="stock-details-container card overflow-hidden marginmobile"
              style={headertop}
            >
              <app-details-header>
                <div
                  style={{
                    backgroundColor: theme ? "#1B1B1B" : "#fff",
                  }}
                >
                  <div
                    className={`d-flex ${theme ? "text-white" : "text-black"} flex-md-row-reverse`}
                    style={{
                      marginTop: scrollTop ? "1px" : "0px",
                    }}
                  >
                    {loading ? (
                      <ul className="d-flex resp-slider btns-wrapper align-items-center justify-content-end">
                        <Skeleton height={30} width={80} style={{ marginRight: "10px" }} />
                        <Skeleton height={30} width={80} style={{ marginRight: "10px" }} />
                        <Skeleton height={30} width={80} style={{ marginRight: "10px" }} />
                        <Skeleton height={30} width={80} style={{ marginRight: "10px" }} />
                      </ul>
                    ) : (
                      <ul
                        className="resp-slider btns-wrapper align-items-center justify-content-start"
                        style={{
                          overflowX: "auto", // Enable horizontal scroll
                          display: "flex",
                          whiteSpace: "nowrap", // Prevent items from wrapping to the next line
                          // padding: "10px 0",
                          scrollBehavior: "smooth", // Smooth scrolling for mobile experience
                        }}
                      >
                        {[
                          { value: "OVERALL", label: "OVERVIEW" },
                          { value: "COMPLIANCE", label: "COMPLIANCE" },
                          { value: "CHART", label: "CHART" },
                          { value: "TECHENICAL", label: "TECHNICAL" },
                          { value: "FUNDAMENTAL", label: "FUNDAMENTAL" },
                          { value: "SWOT", label: "SWOT" },
                          { value: "PROFILE-INFO", label: "PROFILE INFO" },
                        ].map((item, index) => (
                          <li
                            key={index}
                            className="resp-slider-item"
                            onClick={() => handlepagevalue(item.value)}
                            style={{
                              borderBottom:
                                screen === item.value ? "2px solid #7E66BC" : "",
                              padding: "0 10px", // Spacing between tabs
                              cursor: "pointer",
                              transition: "transform 0.2s ease", // Hover animation
                            }}
                          >
                            <button
                              className={`btn tab-button shadow-none ${theme ? "text-white" : "text-black"
                                }`}
                              style={{
                                fontSize: "14px",
                                fontWeight: 400,
                                backgroundColor: "transparent",
                              }}
                            >
                              {item.label}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </app-details-header>
            </header>
            <div
              className={`modal fade ${categorymodel ? "show" : ''}`}
              id="exampleModal2"
              tabIndex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
              style={{
                display: categorymodel ? "block" : 'none',
                backgroundColor: categorymodel ? 'rgba(0, 0, 0, 0.5)' : '',
                transition: 'opacity 0.3s ease'
              }}
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content border-0 rounded-lg shadow-lg">
                  <div
                    className="modal-header text-center "
                    style={{
                      background:theme?"#7E66BC":"#5b298c",
                      borderBottom: "none",
                      borderRadius: "0.5rem 0.5rem 0 0",
                      padding: "1rem 1.5rem",
                    }}
                  >
                    <h5 className="modal-title w-100 " id="exampleModalLabel" style={{ color:"#fff"}}>
                      Add to Your Watchlist
                    </h5>
                    <button
                      type="button"
                      className="btn-close btn-close-white text-white"
                      onClick={() => setCategorymodel(false)}
                      aria-label="Close"
                    ></button>
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      padding: "1.5rem 4rem",
                      backgroundColor: "#F1F1F1",
                    }}
                  >
                    <div className="mb-4">
                      <div className="d-flex justify-content-end align-items-center mb-3">
                        <label className={`form-label mb-0 ${theme ? "text-dark" : ''} me-2`} style={{ fontSize: '14px' }}>Create Watchlist:</label>
                        <button
                          type="button"
                          className="btn btn-sm "
                          onClick={openCreateCategoryModal}
                          style={{
                            background: theme?"#7E66BC":"#5b298c",
                            color: '#fff',
                            borderRadius: '0.3rem',
                            padding: '0.5rem 1rem'
                          }}
                        >
                          + Create
                        </button>
                      </div>
                      <div className="d-flex flex-column gap-2" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                        {watchlistcategory && watchlistcategory.map((category) => (
                          <div
                            key={category._id}
                            className="d-flex align-items-center p-2 rounded shadow-sm"
                            style={{ border: theme? '1px solid #7E66BC':'1px solid #5b298c',backgroundColor:'#f0edf7' }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              style={{width:'20px',height:'20px',backgroundColor: selectedCategories.includes(category._id)?"#5b298c":"#fff", border:"0.5px solid #5b298c"}}
                              id={`category-${category._id}`}
                              checked={selectedCategories.includes(category._id)}
                              onChange={() => handleCheckboxChange(category._id)}
                            />
                            <div>
                              <label htmlFor={`category-${category._id}`} className="form-check-label" style={{ fontSize: '12px' }}>
                                {category.title}
                              </label>
                              <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                {category.stock_count} Stocks
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer border-0" style={{ backgroundColor: '#f1f1f1' }}>
                    <div className="d-flex justify-content-end gap-3 w-100">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => setCategorymodel(false)}
                        style={{
                          borderRadius: "0.3rem",
                          padding: '0.5rem 1.5rem',
                          borderColor: '#ccc',
                          color: '#333',
                          transition: "background 0.3s, border-color 0.3s",
                        }}
                        onMouseEnter={(e) => e.target.style.borderColor = '#999'}
                        onMouseLeave={(e) => e.target.style.borderColor = '#ccc'}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSubmit}
                        className="btn btn-primary"
                        // disabled={buttonclick}
                        style={{
                          backgroundColor:theme?"#7E66BC":"#5b298c",
                          border: "none",
                          color: "#fff",
                          borderRadius: "0.3rem",
                          padding: '0.5rem 1.5rem',
                          transition: "background 0.3s, transform 0.2s",
                        }}
                        onMouseEnter={(e) => e.target.style.backgroundColor = theme?"#7E66BC":"#5b298c"}
                        onMouseLeave={(e) => e.target.style.backgroundColor = 'rgb(126, 102, 188)'}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`modal fade ${createcategorymodel ? "show" : ''}`} id="createCategoryModal" tabIndex="-1" aria-labelledby="createCategoryModalLabel" aria-hidden="true"
              style={{ display: createcategorymodel ? "block" : 'none', backgroundColor: createcategorymodel ? '#0009' : '' }}>
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div
                    className="modal-header"
                    style={{
                      backgroundColor: "rgb(126, 102, 188)",
                      color: "#fff",
                      borderBottom: "none",
                      borderRadius: "0.5rem 0.5rem 0 0",
                      padding: "1rem 1.5rem",
                    }}
                  >
                    <h5 className="modal-title" id="createCategoryModalLabel">Create Watchlist</h5>
                    <button type="button" className="btn-close btn-close-white" onClick={() => { setcreatecategorymodel(false) }} aria-label="Close"></button>
                  </div>
                  <div className="modal-body" style={{ padding: "1.5rem 2rem", backgroundColor: "#F1F1F1" }}>
                    <div className="mb-4">
                      <label htmlFor="categoryTitle" className="form-label fw-bold mb-3">Watchlist Title:</label>
                      <input type="text" className="form-control" value={formdata4.title} name="title" id="categoryTitle" onChange={(e) => { handleInputChangevalue(e) }} placeholder="Enter Watchlist title" />
                    </div>
                  </div>
                  <div className="modal-footer" style={{ borderTop: "none", padding: "1rem 1.5rem" }}>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => { setcreatecategorymodel(false) }}
                      style={{ borderRadius: "0.3rem", padding: '0.5rem 1.5rem' }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                      disabled={buttonclickcreate}
                      style={{
                        backgroundColor: "rgb(126, 102, 188)",
                        border: "none",
                        color: "#fff",
                        borderRadius: "0.3rem",
                        padding: '0.5rem 1.5rem',
                        transition: "background 0.3s, transform 0.2s",
                      }}
                      onClick={() => { Handlewatchlistcategory() }}  // Function to handle category creation
                      onMouseEnter={(e) => e.target.style.backgroundColor = 'rgb(154, 123, 187)'}
                      onMouseLeave={(e) => e.target.style.backgroundColor = 'rgb(126, 102, 188)'}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header text-white"
                    style={{
                      background: "linear-gradient(90deg, rgba(126,102,188,1) 0%, rgba(154,123,187,1) 100%)",
                      borderBottom: "none",
                      borderRadius: "0.3rem 0.3rem 0 0",
                    }}
                  >
                    <h5 className="modal-title" id="exampleModalLabel">
                      Report a stock
                    </h5>
                    <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div
                    className="modal-body"
                    style={{
                      padding: "2rem",
                      backgroundColor: "#F9F9F9",
                    }}
                  >
                    <form id="reportStockForm">
                      <div className="mb-3 d-flex">
                        <label htmlFor="stock" className="form-label fw-bolder ">Stock :</label>
                        <p className="ms-2">{data?.screenerData?.stock}</p>
                        {/* <input type="text" className="form-control" id="stock" name="stock" required /> */}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="description" className="form-label fw-bolder">Description :</label>
                        <textarea className="form-control" name="description" id="description" value={formdata.description} onChange={(e) => { handleInputChange(e) }} rows="3" required></textarea>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer" style={{ borderTop: "none" }}>
                    <div className="d-flex justify-content-end gap-2 w-100">
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        data-bs-dismiss="modal"
                        style={{ borderRadius: "0.3rem" }}
                      >
                        Cancel
                      </button>
                      <button
                        data-bs-dismiss="modal"
                        // type="submit"
                        // form="reportStockForm"
                        className="btn btn-danger"
                        style={{
                          background: "rgb(126, 102, 188)",
                          border: "none",
                          color: "#fff",
                          borderRadius: "0.3rem",
                          transition: "background 0.3s",
                        }}
                        onClick={() => { Handlesendreport() }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {screen == "OVERALL" && (
              <div _ngcontent-qal-c183="">
                <div _ngcontent-qal-c183="" className="desktop-only-container row" style={{ display: 'flex', alignItems: 'stretch' }}>

                  <div _ngcontent-qal-c183="" className="right-side-container col-lg-8 col-md-8 col-sm-12" style={{ display: 'flex', flexDirection: 'column' }}>
                    <section _ngcontent-qal-c148="" className="section mt-2 mb-2 card p-1"
                      style={{
                        backgroundColor: theme ? '#1B1B1B' : '#fff',
                        borderRadius: '10px',
                        flex: 1
                      }}
                    >

                      {loading ? (
                        <Skeleton height={390} />
                      ) : (
                        <Overview
                          symbol={
                            data?.TechinicalData?.Symbol_of_Tradingview ||
                            data?.stockData?.Symbol_of_Tradingview ||
                            data?.stockData?.stock_code
                          }
                          Themes={theme}
                        />
                      )}
                    </section>
                  </div>


                  <div _ngcontent-qal-c183="" className="right-side-container col-lg-4 col-md-4 col-sm-12"
                    style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                  >

                    {loading ? (
                      <Skeleton count={5} />
                    ) : (
                      Compliancefield(data)
                    )}
                  </div>
                </div>

                <div _ngcontent-qal-c183="" className="mt-5 left-side-container col-lg-12 col-md-12 col-sm-12">

                </div>
              </div>
            )}
            {screen == "CHART" && (
              <div _ngcontent-qal-c183="">
                <div
                  _ngcontent-qal-c183=""
                  className="desktop-only-container  row"
                >
                  <div
                    _ngcontent-qal-c183=""
                    className="right-side-container col-lg-12 col-md-12 col-sm-12"
                  >
                    <section
                      _ngcontent-qal-c148=""
                      className="section mt-0 mb-2 card p-1"
                      style={{ backgroundColor: theme ? '#1B1B1B' : '#fff', borderRadius: '10px', height: '80vh' }}
                    >
                      <Advancegraph symbol={data?.TechinicalData?.Symbol_of_Tradingview || data?.stockData?.Symbol_of_Tradingview || data?.stockData?.Symbol_of_Tradingview} themes={theme} />
                    </section>
                  </div>
                </div>
              </div>
            )}
            {screen == "FUNDAMENTAL" && (
              <div className="row">
                <div
                  _ngcontent-qal-c183=""
                  className="left-side-container col-lg-12 col-md-12 col-sm-12"
                >
                  <section
                    _ngcontent-qal-c148=""
                    className="section mt-0 mb-2 card p-1"
                    style={{ backgroundColor: theme ? '#1B1B1B' : '#fff', borderRadius: '10px' }}
                  >
                    <Fundemental symbol={data?.stockData?.stock_code || data?.TechinicalData?.Symbol_of_Tradingview || data?.stockData?.Symbol_of_Tradingview} themes={theme} />
                  </section>
                </div>
              </div>
            )}
            {screen == "PROFILE-INFO" && (
              <div className="row">
                <div
                  _ngcontent-qal-c183=""
                  className="left-side-container mt-2 col-lg-8 col-md-8 col-sm-12"
                >
                  <section
                    _ngcontent-qal-c148=""
                    className="section mt-0 mb-2 card p-1"
                    style={{ backgroundColor: theme ? '#1B1B1B' : '#fff', borderRadius: '10px', height: isMobile ? 'auto' : '390px' }}
                  >
                    <Profilechart data1={data} themes={theme} />
                  </section>
                </div>
                <div _ngcontent-qal-c183="" className="right-side-container col-lg-4 col-md-4 col-sm-12 mt-2" >
                  <div style={{ border: theme ? '1px solid rgb(36, 36, 36)' : '1px solid rgb(209, 209, 209)', borderRadius: '10px', height: '390px', backgroundColor: theme ? "" : "#fff" }}>
                    <News themes={theme} />
                  </div>
                </div>
              </div>
            )}
            {screen == "SWOT" && (
              <div className="row">
                <div
                  _ngcontent-qal-c183=""
                  className="left-side-container mt-2 col-lg-6 col-md-6 col-sm-12 mt-2 d-flex justify-content-center"
                >
                  <section
                    _ngcontent-qal-c148=""
                    className="section mt-0 mb-2 card p-1 "
                    style={{ backgroundColor: theme ? '#1B1B1B' : '#fff', borderRadius: '10px', width: "100%" }}
                  >
                    <Swot symbol={data?.stockData?.stock_code} />
                    <Qvtstocks symbol={data?.stockData?.stock_code} />
                  </section>
                </div>
                <div _ngcontent-qal-c183="" className="right-side-container col-lg-6 col-md-6 col-sm-12 mt-2 d-flex justify-content-center" >
                  <div className="text-center pt-3" style={{ border: theme ? '1px solid rgb(36, 36, 36)' : '1px solid rgb(209, 209, 209)', borderRadius: '10px', width: "100%", backgroundColor: theme ? '#1B1B1B' : '#fff' }}>
                    <Linelist symbol={data?.stockData?.stock_code} themes={theme} />
                  </div>
                </div>
              </div>
            )}
            {screen == "TECHENICAL" && (
              <div className="row">
                <div
                  _ngcontent-qal-c183=""
                  className="left-side-container mt-2 col-lg-7 col-md-7 col-sm-12"
                >
                  <section
                    _ngcontent-qal-c148=""
                    className="section mt-0 mb-2 card p-1"
                    style={{ backgroundColor: theme ? '#1B1B1B' : "#fff", borderRadius: '10px', height: '480px' }}
                  >
                    <Techenical symbol={data?.stockData?.stock_code} Themes2={theme} />
                  </section>
                </div>
                <div _ngcontent-qal-c183="" className="right-side-container col-lg-5 col-md-5 col-sm-12 mt-2" >
                  <div className="d-flex justify-content-center" style={{ border: theme ? '1px solid rgb(36, 36, 36)' : '1px solid rgb(209, 209, 209)', borderRadius: '10px', height: '480px', backgroundColor: theme ? '#1B1B1B' : "#fff" }}>
                    <TechnicalsPage symbol={data?.stockData?.stock_code} Themes2={theme} />
                    {/* <News themes={theme} /> */}
                  </div>
                </div>
              </div>
            )}
            {screen == "COMPLIANCE" && (
              <div className="row" style={{ display: 'flex', alignItems: 'stretch' }}>
                <div _ngcontent-qal-c183="" className="left-side-container mt-2 col-lg-8 col-md-8 col-sm-12" >
                  <section className="section mt-0 mb-2 card p-1" style={{ backgroundColor: theme ? '#1B1B1B' : "#fff", borderRadius: '10px', height: 'auto' }}>
                    <div className="row mt-2  justify-content-center">
                      <div className="col-12 col-md-6 p-3 d-flex justify-content-between text-center" onClick={() => { setchartvalue("Business") }} style={{ backgroundColor: chartvalue == 'Business' ? '#1A257E' : theme ? '#2C2C2C' : '#2C2C2C', borderRadius: '10px', height: isMobile ? "50px" : '55px', marginBottom: '10px', width: !isMobile ? '46%' : '90%', marginRight: isMobile ? "" : "2%", border: theme ? "2px solid rgb(126, 102, 188)" : "" }}>
                        <p className="text-white" style={{ fontSize: isMobile ? "14px" : istab ? "12px" : "16px", fontWeight: '500' }}>
                          <i className="fa fa-briefcase me-3" style={{ color: '#fff', fontSize: isMobile ? "20px" : '23px' }}></i>
                          Business Activity
                        </p>
                        <div className="text-white">
                          {data?.screenerData?.status == 6 ?

                            <button className="btn pt-1 d-flex activitycard-fail" style={{ fontSize: isMobile ? "14px" : "" }}>Fail</button>
                            : halal >= 97.5 ? (
                              <button className="btn pt-1 d-flex activitycard-pass" style={{ fontSize: isMobile ? "14px" : "" }}>Pass</button>
                            ) : (
                              <button className="btn pt-1 d-flex activitycard-fail" style={{ fontSize: isMobile ? "14px" : "" }}>Fail</button>
                            )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 p-3 d-flex justify-content-between text-center" onClick={() => { setchartvalue("Debt") }} style={{ backgroundColor: chartvalue == 'Debt' ? '#1A257E' : theme ? '#2C2C2C' : '#2C2C2C', borderRadius: '10px', height: isMobile ? "50px" : '55px', marginBottom: '10px', width: !isMobile ? '46%' : '90%', border: theme ? "2px solid rgb(126, 102, 188)" : "" }}>
                        <p className="text-white" style={{ fontSize: isMobile ? "14px" : istab ? "12px" : "16px", fontWeight: '500' }}>
                          <i className="fa fa-cube me-3" style={{ color: '#fff', fontSize: isMobile ? "20px" : '23px' }}></i>
                          Debt to Asset
                        </p>
                        <div className="text-white">
                          {data?.screenerData?.debtAsset <= 25 ? (
                            <button className="btn pt-1 d-flex activitycard-pass" style={{ fontSize: isMobile ? "14px" : "" }}>Pass</button>
                          ) : (
                            <button className="btn pt-1 d-flex activitycard-fail" style={{ fontSize: isMobile ? "14px" : "" }}>Fail</button>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3 justify-content-center">
                      <div className="col-12 col-md-6 p-3 d-flex justify-content-between text-center" onClick={() => { setchartvalue("Total") }} style={{ backgroundColor: chartvalue == 'Total' ? '#1A257E' : theme ? '#2C2C2C' : '#2C2C2C', borderRadius: '10px', height: isMobile ? "50px" : '55px', marginBottom: '10px', width: !isMobile ? '46%' : '90%', marginRight: isMobile ? "" : "2%", border: theme ? "2px solid rgb(126, 102, 188)" : "" }}>
                        <p className={`text-white ${isMobile ? 'mb-0' : ''}`} style={{ fontSize: isMobile ? "14px" : istab ? "12px" : "16px", fontWeight: '500' }}>
                          <i className="fa fa-inr me-3" style={{ color: '#fff', fontSize: isMobile ? "20px" : '23px' }}></i>
                          Total Interest Income
                        </p>
                        <div className="text-white">
                          {data?.screenerData?.intIncInv <= 2.5 ? (
                            <button className="btn pt-1 d-flex" style={{ backgroundColor: 'green', borderRadius: '5px', color: '#fff', height: '28px', width: '65px', alignItems: 'center', fontSize: isMobile ? "14px" : "" }}>Pass</button>
                          ) : (
                            <button className="btn pt-1 d-flex activitycard-fail" style={{ fontSize: isMobile ? "14px" : "" }}>Fail</button>
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-md-6 p-3 d-flex justify-content-between text-center" onClick={() => { setchartvalue("Liquid") }} style={{ backgroundColor: chartvalue == 'Liquid' ? '#1A257E ' : theme ? '#2C2C2C' : '#2C2C2C', borderRadius: '10px', height: isMobile ? "50px" : '55px', marginBottom: '10px', width: !isMobile ? '46%' : '90%', border: theme ? "2px solid rgb(126, 102, 188)" : "" }}>
                        <p className="text-white" style={{ fontSize: isMobile ? "14px" : istab ? "12px" : "16px", fontWeight: '500' }}>
                          <i className="fa fa-ils me-3" style={{ color: '#fff', fontSize: isMobile ? "20px" : '23px' }}></i>
                          Liquid Asset
                        </p>
                        <div className="text-white">
                          {data?.screenerData?.cashTotalAsset <= 90 ? (
                            <button className="btn pt-1 d-flex activitycard-pass" style={{ fontSize: isMobile ? "14px" : "" }}>Pass</button>
                          ) : (
                            <button className="btn pt-1 d-flex activitycard-fail" style={{ fontSize: isMobile ? "14px" : "" }}>Fail</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </section>
                  <section
                    _ngcontent-qal-c148=""
                    className="section mt-0 mb-2 card p-1"
                    style={{ backgroundColor: theme ? '#1B1B1B' : '#fff', borderRadius: '10px', height: "auto" }}
                  >
                    {chartvalue == "Business" && (
                      <div>
                        <div className="mt-2">
                          {data?.screenerData?.status == 6 ?
                            <div>
                              <p className={`${theme ? 'text-white' : 'text-black'} ms-5`} style={{ fontSize: isMobile ? "16px" : "24px", fontWeight: '500' }}> Primary Business Activity <span>
                                <button className="btn p-1 ms-2" style={{ backgroundColor: 'green', borderRadius: '5px', color: '#fff', width: '15%' }}>Pass</button>

                              </span>
                              </p>
                              <p className={`${theme ? 'text-white' : 'text-black'} ms-5 mt-2`} style={{ fontSize: isMobile ? "16px" : "24px", fontWeight: '500' }}>Secondary Business Activity<span>
                              <button className="btn p-1 ms-2" style={{ backgroundColor: 'red', borderRadius: '5px', color: '#fff', width: '15%' }}>Fail</button>

                              </span>
                              </p>
                            </div>

                            : <p className={`${theme ? 'text-white' : 'text-black'} ms-5`} style={{ fontSize: isMobile ? "16px" : "24px", fontWeight: '500' }}> Business Activity <span>
                              {halal >= 97.5 ?
                                <button className="btn p-1 ms-2" style={{ backgroundColor: 'green', borderRadius: '5px', color: '#fff', width: '15%' }}>Pass</button>
                                :
                                <button className="btn p-1 ms-2" style={{ backgroundColor: 'red', borderRadius: '5px', color: '#fff', width: '15%' }}>Fail</button>
                              }
                            </span>
                            </p>
                          }
                        </div>
                        {data?.screenerData?.status == 2 || data?.screenerData?.status == 3 ? (
                          <div className="d-flex justify-content-center align-items-center" style={{ height: isMobile ? "200px" : "430px" }}>
                            <h1 className="text-danger">
                              Non Shariah compliant
                            </h1>
                          </div>

                        ) : (
                          <div className="text-center">
                            <Chart themes={theme} doubtfull={doubtfull} halal={halal} haram={haram} />

                            <p className="mt-3 mb-4 p-2" style={{ fontSize: "16px", fontWeight: '500', color: '#898989' }}>
                              The combined value of Non-Compliant and Doubtful income sources, based on business activity, should not exceed 2.5% of the Total Revenue (Gross Sales + Other Income). If this limit is surpassed, the company will be marked as Non-Compliant, even if it passes other business activity screening criteria.
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    {chartvalue == "Debt" && (
                      <div>
                        <div className="mt-2">
                          <p className={`${theme ? 'text-white' : 'text-black'} ms-5`} style={{ fontSize: isMobile ? "16px" : "24px", fontWeight: '500' }}>Debt to Asset <span>
                            {data?.screenerData?.debtAsset <= 25 ?
                              <button className="btn p-1 ms-2" style={{ backgroundColor: 'green', borderRadius: '5px', color: '#fff', width: '15%' }}>Pass</button>
                              :
                              <button className="btn p-1 ms-2" style={{ backgroundColor: 'red', borderRadius: '5px', color: '#fff', width: '15%' }}>Fail</button>
                            }
                          </span>
                          </p>
                        </div>
                        {data?.screenerData?.status == 2 || data?.screenerData?.status == 3 ? (
                          <div className="d-flex justify-content-center align-items-center" style={{ height: isMobile ? "200px" : "430px" }}>
                            <h1 className="text-danger">
                              Non Shariah compliant
                            </h1>
                          </div>

                        ) : (
                          <div className="text-center mt-5">
                            <GaugeChart
                              id="gauge-chart5"
                              nrOfLevels={420}
                              arcsLength={[
                                25 / 100,
                                75 / 100
                              ]}
                              colors={[
                                'red',
                                'green'

                              ]}
                              percent={data?.screenerData?.debtAsset / 100}
                              arcPadding={0.02}

                              needleColor={theme ? "white" : 'black'}
                              needleBaseColor={theme ? "white" : 'black'}
                              textColor={theme ? "white" : 'black'}
                              style={{
                                width: "60%",
                                margin: "0 auto",
                              }}
                            />
                            <div className="d-flex justify-content-around mt-3 mb-2" style={{ width: "70%", margin: "0 auto" }}>
                              <span className="d-flex" style={{ color: theme ? "white" : "black", fontWeight: '500', fontSize: isMobile ? "12px" : '20px' }}><span className="card me-2" style={{ width: isMobile ? "10px" : '30px', height: isMobile ? "15px" : '30px', backgroundColor: 'green' }}></span>Permissible</span>
                              <span className="d-flex" style={{ color: theme ? "white" : "black", fontWeight: '500', fontSize: isMobile ? "12px" : '20px' }}><span className="card me-2" style={{ width: isMobile ? "10px" : '30px', height: isMobile ? "15px" : '30px', backgroundColor: 'red' }}></span>Non-Permissible</span>
                            </div>
                            <p className="mt-3 mb-4 p-2" style={{ fontSize: "16px", fontWeight: '500', color: '#898989' }}>The total interest-bearing debt should not exceed 25% of the total assets, ensuring that the company maintains a minimal level of interest-based liabilities and adheres to Shariah principles that restrict excessive debt financing.
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    {chartvalue == "Total" && (
                      <div>
                        <div className="mt-2">
                          <p className={`${theme ? 'text-white' : 'text-black'} ms-5`} style={{ fontSize: isMobile ? "16px" : "24px", fontWeight: '500' }}>Total Interest Income <span>
                            {data?.screenerData?.intIncInv <= 2.5 ?
                              <button className="btn p-1 ms-2" style={{ backgroundColor: 'green', borderRadius: '5px', color: '#fff', width: '15%' }}>Pass</button>
                              :
                              <button className="btn p-1 ms-2" style={{ backgroundColor: 'red', borderRadius: '5px', color: '#fff', width: '15%' }}>Fail</button>
                            }
                          </span>
                          </p>
                        </div>
                        {data?.screenerData?.status == 2 || data?.screenerData?.status == 3 ? (
                          <div className="d-flex justify-content-center align-items-center" style={{ height: isMobile ? "200px" : "430px" }}>
                            <h1 className="text-danger">
                              Non Shariah compliant
                            </h1>
                          </div>

                        ) : (
                          <div className="text-center mt-5">
                            <GaugeChart
                              id="gauge-chart5"
                              nrOfLevels={420}
                              arcsLength={[2.5 / 100, 97.5 / 100]}
                              colors={['red', 'green']}
                              percent={data?.screenerData?.intIncInv / 100}
                              arcPadding={0.02}
                              needleColor={theme ? "white" : 'black'}
                              needleBaseColor={theme ? "white" : 'black'}
                              textColor={theme ? "white" : 'black'}
                              style={{
                                width: "60%",
                                margin: "0 auto",
                              }}
                            />
                            <div className="d-flex justify-content-around mt-3 mb-2" style={{ width: "70%", margin: "0 auto" }}>
                              <span className="d-flex" style={{ color: theme ? "white" : "black", fontWeight: '500', fontSize: isMobile ? "12px" : '20px' }}><span className="card me-2" style={{ width: isMobile ? "10px" : '30px', height: isMobile ? "15px" : '30px', backgroundColor: 'green' }}></span>Permissible</span>
                              <span className="d-flex" style={{ color: theme ? "white" : "black", fontWeight: '500', fontSize: isMobile ? "12px" : '20px' }}><span className="card me-2" style={{ width: isMobile ? "10px" : '30px', height: isMobile ? "15px" : '30px', backgroundColor: 'red' }}></span>Non-Permissible</span>
                            </div>
                            {/* <Semigraph /> */}
                            <p className="mt-3 mb-4 p-2" style={{ fontSize: "16px", fontWeight: '500', color: '#898989' }}>The combined value of Non-Compliant and Doubtful income sources should not exceed 2.5% of the Total Revenue (Gross Sales + Other Income).
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                    {chartvalue == "Liquid" && (
                      <div>
                        <div className="mt-2">
                          <p className={`${theme ? 'text-white' : 'text-black'} ms-5`} style={{ fontSize: isMobile ? "16px" : "24px", fontWeight: '500' }}>
                            Liquid Asset
                            <span>
                              {data?.screenerData?.cashTotalAsset <= 90 ?
                                <button className="btn p-1 ms-2" style={{ backgroundColor: 'green', borderRadius: '5px', color: '#fff', width: '15%' }}>
                                  Pass
                                </button>
                                : <button className="btn p-1 ms-2" style={{ backgroundColor: 'red', borderRadius: '5px', color: '#fff', width: '15%' }}>
                                  Fail                              </button>
                              }
                            </span>
                          </p>
                        </div>
                        {data?.screenerData?.status == 2 || data?.screenerData?.status == 3 ? (
                          <div className="d-flex justify-content-center align-items-center" style={{ height: isMobile ? "200px" : "430px" }}>
                            <h1 className="text-danger ">
                              Non Shariah compliant
                            </h1>
                          </div>

                        ) : (
                          <div className="text-center mt-5">
                            <GaugeChart
                              id="gauge-chart5"
                              nrOfLevels={420}
                              arcsLength={[
                                9 / 10,   // This is correct for your first arc
                                1 / 10  // This ensures the remaining portion of the gauge
                              ]}
                              colors={['green', 'red']}
                              percent={data?.screenerData?.cashTotalAsset / 100}
                              arcPadding={0.02}
                              needleColor={theme ? "white" : 'black'}
                              needleBaseColor={theme ? "white" : 'black'}
                              textColor={theme ? "white" : 'black'}
                              style={{
                                width: "60%",
                                margin: "0 auto",
                              }}
                            />
                            {/* Labels for Halal and Haram */}
                            <div className="d-flex justify-content-around mt-3 mb-2" style={{ width: "70%", margin: "0 auto" }}>
                              <span className="d-flex" style={{ color: theme ? "white" : "black", fontWeight: '500', fontSize: isMobile ? "12px" : '20px' }}><span className="card me-2" style={{ width: isMobile ? "10px" : '30px', height: isMobile ? "15px" : '30px', backgroundColor: 'green' }}></span>Permissible</span>
                              <span className="d-flex" style={{ color: theme ? "white" : "black", fontWeight: '500', fontSize: isMobile ? "12px" : '20px' }}><span className="card me-2" style={{ width: isMobile ? "10px" : '30px', height: isMobile ? "15px" : '30px', backgroundColor: 'red' }}></span>Non-Permissible</span>
                            </div>
                            <p className="mt-3 mb-4 p-2" style={{ fontSize: "16px", fontWeight: '500', color: '#898989' }}>
                              Liquid assets—including cash, bank balances, accounts receivable, and other liquid investments—must not exceed 90% of total assets
                            </p>
                          </div>
                        )}
                      </div>
                    )
                    }
                  </section>
                </div>
                <div _ngcontent-qal-c183="" className="right-side-container col-lg-4 col-md-4 col-sm-12" >
                  {Compliancefield2(data)}
                  <div style={{ border: theme ? '2px solid rgb(36, 36, 36)' : '1px solid rgb(209, 209, 209)', borderRadius: '10px', height: chartvalue == "Business" ? "380px" : '200px', backgroundColor: theme ? "" : "#fff" }}>
                    <Newspage themes={theme} />
                  </div>
                </div>
              </div>
            )}
          </div>
          {screen !== "CHART" && (
            <div className={`${isMobile ? "p-4" : 'p-3'}`}>
              <div className={`d-flex justify-content-between w-100 ${theme ? 'text-white' : 'text-black'} mb-2 mt-2`}>
                <div>
                  <span style={{ fontSize: '22px' }}>
                    Similar <span style={{ color:theme?'#7E66BC':"#5b298c" }}>Compliant Stock</span>
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: '14px', cursor: 'pointer' }} onClick={() => handleviewall(filteredItems)}>
                    <span style={{ color: theme?'#7E66BC':"#5b298c" }}>
                      View All &#8594;
                    </span>
                  </span>
                </div>
              </div>
              <div className="mt-4 mb-0">
                {loading2 ? (
                  <div className="d-flex justify-content-between">
                    {Array(7).fill().map((_, index) => (
                      <div
                        key={index}
                        className="text-center"
                        style={{
                          backgroundColor: theme ? '#2C2C2C' : '#F5F5F5',
                          borderRadius: '15px',
                          width: '148px',
                          height: '170px',
                          border: theme ? '' : '1px solid #E9E9E9',
                        }}
                      >
                        <Skeleton width={40} height={43} style={{ borderRadius: '15px' }} />
                        <Skeleton width={100} height={15} className="mt-3" />
                        <Skeleton width={80} height={15} className="mt-2" />
                        <div className="d-flex justify-content-center">
                          <Skeleton width={86} height={27} className="mt-2" style={{ borderRadius: '10px' }} />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {filteredItems && filteredItems.length <= 2 && isMobile ? (
                      <div className="d-flex" >
                        {filteredItems.map((item, index) => (

                          <div key={index} className="p-2">
                            <div
                              className=""
                              onClick={() => Handelsetlocaldata(item)}
                              style={{
                                backgroundColor: theme ? '#2C2C2C' : '#fff',
                                borderRadius: '15px',
                                width: '148px',
                                height: '170px',
                                cursor: "pointer",
                                border: theme ? '1px solid rgb(44, 44, 44)' : '1px solid #E9E9E9',
                              }}
                            >
                              <div className="text-center">
                                <Cardimage value={item} />
                                <p
                                  className={`card-text mt-2`}
                                  style={{ fontSize: item?.stockData?.stock_code ? '12px' : '11px', color: theme ? '#fff' : 'black', height: "30px" }}
                                >
                                  {item?.stockData?.stock_code || item?.screenerData?.stock}
                                </p>
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="p-1 text-center"
                                    style={{
                                      backgroundColor: theme ? '#3A3A3A' : '#F5F5F5',
                                      color: '#26C000',
                                      borderRadius: '10px',
                                      width: 'auto',
                                      height: 'auto',
                                    }}
                                  >
                                    {item?.screenerData?.status == 1 ? (

                                      <div className="d-flex p-1 justify-content-center" >
                                        <span className="" style={{ color: '#218303', fontWeight: "500", fontSize: "12px" }}>Compliant</span>
                                        <span className="ms-1"><img src="/images/checklist.png" width={15} height={15} ></img></span>
                                      </div>

                                    ) : item?.screenerData?.status == 2 || item?.screenerData?.status == 4 || item?.screenerData?.status == 6 || item?.screenerData?.status == 7 ? (

                                      <div className="d-flex p-1 justify-content-center">
                                        <span className="" style={{ color: '#D43A3A', fontWeight: "500", fontSize: "12px" }}>Non Compliant</span>
                                        <span className="ms-1 "><img src="/images/non-permisable.png" width={15} height={15} ></img></span>
                                      </div>

                                    ) : item?.screenerData?.status == 3 ? (

                                      <div className="d-flex p-1 justify-content-center" >
                                        <span className="" style={{ color: '#2132CA', fontWeight: "500", fontSize: "12px" }}>Unknown</span>
                                        <span className="ms-1 "><img src="/images/underreview.png" width={15} height={15} ></img></span>
                                      </div>

                                    ) :
                                      (
                                        <div className="d-flex p-1 justify-content-center">
                                          <span className="" style={{ color: '#EFA625', fontWeight: "500", fontSize: "12px" }}>Under Review</span>
                                          <span className="ms-1 "><img src="/images/underreview.png" width={15} height={15} ></img></span>
                                        </div>

                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : filteredItems && filteredItems.length < 7 && !isMobile ? (
                      <div className="d-flex" >
                        {filteredItems.map((item, index) => (
                          <div key={index} className="p-2">
                            <div
                              className=""
                              onClick={() => Handelsetlocaldata(item)}
                              style={{
                                backgroundColor: theme ? '#2C2C2C' : '#FFF',
                                borderRadius: '15px',
                                width: '148px',
                                height: '170px',
                                cursor: "pointer",
                                border: theme ? '1px solid rgb(44, 44, 44)' : '1px solid #E9E9E9',
                              }}
                            >
                              <div className="text-center">
                                <Cardimage value={item} />
                                <p
                                  className={`card-text mt-2`}
                                  style={{ fontSize: item?.stockData?.stock_code ? '12px' : '11px', color: theme ? '#fff' : 'black', height: "30px" }}
                                >
                                  {item?.stockData?.stock_code || item?.screenerData?.stock}
                                </p>
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="p-1 text-center"
                                    style={{
                                      backgroundColor: theme ? '#3A3A3A' : '#F5F5F5',
                                      color: '#26C000',
                                      borderRadius: '10px',
                                      width: 'auto',
                                      height: 'auto',
                                    }}
                                  >
                                    {item?.screenerData?.status == 1 ? (
                                      <div className="d-flex p-1 justify-content-center" >
                                        <span className="" style={{ color: '#218303', fontWeight: "500", fontSize: "12px" }}>Compliant</span>
                                        <span className="ms-1"><img src="/images/checklist.png" width={15} height={15} ></img></span>
                                      </div>

                                    ) : item?.screenerData?.status == 2 || item?.screenerData?.status == 4 || item?.screenerData?.status == 6 || item?.screenerData?.status == 7 ? (
                                      <div className="d-flex p-1 justify-content-center">
                                        <span className="" style={{ color: '#D43A3A', fontWeight: "500", fontSize: "12px" }}>Non Compliant</span>
                                        <span className="ms-1 "><img src="/images/non-permisable.png" width={15} height={15} ></img></span>
                                      </div>

                                    ) : item?.screenerData?.status == 3 ? (
                                      <div className="d-flex p-1 justify-content-center" >
                                        <span className="" style={{ color: '#2132CA', fontWeight: "500", fontSize: "12px" }}>Unknown</span>
                                        <span className="ms-1 "><img src="/images/underreview.png" width={15} height={15} ></img></span>
                                      </div>

                                    ) :
                                      (
                                        <div className="d-flex p-1 justify-content-center">
                                          <span className="" style={{ color: '#EFA625', fontWeight: "500", fontSize: "12px" }}>Under Review</span>
                                          <span className="ms-1 "><img src="/images/underreview.png" width={15} height={15} ></img></span>
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Slider {...settings}>
                        {filteredItems.map((item, index) => (
                          <div key={index} className="p-2">
                            <div
                              className=""
                              onClick={() => Handelsetlocaldata(item)}
                              style={{
                                backgroundColor: theme ? '#2C2C2C' : '#fff',
                                borderRadius: '15px',
                                width: '148px',
                                height: '170px',
                                cursor: "pointer",
                                border: theme ? '1px solid rgb(44, 44, 44)' : '1px solid #e5e5e5',
                              }}
                            >
                              <div className="text-center">
                                <Cardimage value={item} />
                                <p
                                  className={`card-text mt-2`}
                                  style={{ fontSize: item?.stockData?.stock_code ? '12px' : '11px', color: theme ? '#fff' : 'black', height: "30px" }}
                                >
                                  {item?.stockData?.stock_code || item?.screenerData?.stock}
                                </p>
                                <div className="d-flex justify-content-center">
                                  <div
                                    className="p-1 text-center"
                                    style={{
                                      backgroundColor: theme ? '#3A3A3A' : '#F5F5F5',
                                      color: '#26C000',
                                      borderRadius: '10px',
                                      width: 'auto',
                                      height: 'auto',
                                    }}
                                  >
                                    {item?.screenerData?.status == 1 ? (

                                      <div className="d-flex p-1 justify-content-center" >
                                        <span className="" style={{ color: '#218303', fontWeight: "500", fontSize: "12px" }}>Compliant</span>
                                        <span className="ms-1"><img src="/images/checklist.png" width={15} height={15} ></img></span>
                                      </div>

                                    ) : item?.screenerData?.status == 2 || item?.screenerData?.status == 4 || item?.screenerData?.status == 6 || item?.screenerData?.status == 7 ? (

                                      <div className="d-flex p-1 justify-content-center">
                                        <span className="" style={{ color: '#D43A3A', fontWeight: "500", fontSize: "12px" }}>Non Compliant</span>
                                        <span className="ms-1 "><img src="/images/non-permisable.png" width={15} height={15} ></img></span>
                                      </div>

                                    ) : item?.screenerData?.status == 3 ? (

                                      <div className="d-flex p-1 justify-content-center" >
                                        <span className="" style={{ color: '#2132CA', fontWeight: "500", fontSize: "12px" }}>Unknown</span>
                                        <span className="ms-1 "><img src="/images/underreview.png" width={15} height={15} ></img></span>
                                      </div>

                                    ) :
                                      (
                                        <div className="d-flex p-1 justify-content-center">
                                          <span className="" style={{ color: '#EFA625', fontWeight: "500", fontSize: "12px" }}>Under Review</span>
                                          <span className="ms-1 "><img src="/images/underreview.png" width={15} height={15} ></img></span>
                                        </div>

                                      )
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </Slider>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  )
}
export default Newstock