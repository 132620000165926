import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import "./chart.css"

const { CanvasJSChart } = CanvasJSReact;

const Chart = ({ themes, haram, doubtfull, halal }) => {
  // Check if the values are valid numbers; otherwise, assign default values
  const halalValue = halal == 0 ? 0 : Number(halal);
  const doubtfullValue = doubtfull == 0 ? 0 : Number(doubtfull);
  const haramValue = haram == 0 ? 0 : Number(haram);

  const options = {
    animationEnabled: true,
    backgroundColor: "transparent",
    legend: {
      fontSize: 20,
      fontColor: themes ? "white" : "black",
      horizontalAlign: "center",
      verticalAlign: "bottom",
    },
    data: [
      {
        type: "doughnut",
        showInLegend: true,
        yValueFormatString: "#,##0.00'%'",
        innerRadius: "65%",
        radius: "80%",
        dataPoints: [
          { name: "Compliant", y: halalValue, color: "green" ,fontSize:'16px'},
          { name: "Doubtful", y: doubtfullValue, color: "orange" ,fontSize:'16px' },
          { name: "Non Compliant", y: haramValue, color: "red" ,fontSize:'16px' },
        ],
      },
    ],
    creditText: "", 
  };
  
  

  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default Chart;
