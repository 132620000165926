import React, { useEffect, useRef } from 'react';

const Linelist = ({ symbol, themes }) => {
  const widgetContainerRef = useRef(null);

  useEffect(() => {
    // Create a blockquote element for the widget
    const blockquote = document.createElement('blockquote');
    blockquote.className = 'trendlyne-widgets';
    blockquote.dataset.getUrl = `https://trendlyne.com/web-widget/checklist-widget/Poppins/${symbol}/?posCol=00A25B&primaryCol=006AFF&negCol=EB3B00&neuCol=F7941E&theme=${themes ? 'dark' : 'light'}`;

    // Inline style for transparency
    blockquote.style.backgroundColor = 'transparent';

    const script = document.createElement('script');
    script.src = 'https://cdn-static.trendlyne.com/static/js/webwidgets/tl-widgets.js';
    script.async = true;
    script.charset = 'utf-8';

    if (widgetContainerRef.current) {
      // Clear any previous content before adding new elements
      widgetContainerRef.current.innerHTML = '';

      // Append blockquote and script to container
      widgetContainerRef.current.appendChild(blockquote);
      widgetContainerRef.current.appendChild(script);
    }

    return () => {
      // Cleanup by clearing inner HTML
      if (widgetContainerRef.current) {
        widgetContainerRef.current.innerHTML = '';
      }
    };
  }, [symbol, themes]); // Re-run the effect when symbol or themes change

  return (
    <div
      ref={widgetContainerRef}
      style={{
        backgroundColor: 'transparent', 
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: 'auto',
      }}
    ></div>
  );
};

export default Linelist;
