import React, { useState, useEffect, useContext } from "react";
import "../style.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import FetchData from "../../fetchapi/Apifetch";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import { Alert } from "bootstrap";
import { UserContext } from '../../Utills/UserContext';
import Dashboardlight from "../whitetheam/whitedashboard";
import Newspage from "../Detailpage/News";
import Blogs from "../Detailpage/Blogs";
import "./Newdashboard.css";
import "../../style.css"


const Newdashboard = () => {
  const { user, setUser } = useContext(UserContext);
  const { theme, setTheme } = useContext(UserContext);
  const [imageError, setImageError] = useState(false);
  const [search, setsearch] = useState(false);
  const [searchvalue, setsearchvalue] = useState('');
  const [industry, setindustry] = useState('');
  const [query, setQuery] = useState([]);
  const [Stocklist, setStocklist] = useState("");
  const [category, setCategory] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [visibleItems, setVisibleItems] = useState(12); 
  
    const handleToggle = () => {
      setIsExpanded(!isExpanded);
      setVisibleItems(isExpanded ? 12 : industry.length); 
    };

    useEffect(() => {
      window.scrollTo(0, 0);
      },[]);
    
  const stockvalueData = JSON.parse(localStorage.getItem('stockdata'));

  useEffect(() => {
    const fetchStockData = async () => {
      const stockData = JSON.parse(localStorage.getItem('stockdata'));
      const items = stockData?.data?.filter((item) => {
        const stockName = item.stock?.replaceAll(' ', '').toLowerCase();
        const stockCode = item.stock_code?.replaceAll(' ', '').toLowerCase();
        const searchTerm = searchvalue.toLowerCase();
        return (
          stockName.includes(searchTerm) || stockCode.includes(searchTerm)
        );
      }).slice(0, 20);
      setQuery(items || []);
    };

    fetchStockData();
  }, [searchvalue]);
  const [Stockscreener, setStockscreener] = useState("");
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [filteredItems, setfilteredItems] = useState('');


  const navigate = useNavigate();

  const handleRowClick = (item) => {
    navigate("/stock", { state: { data: item?.screenerData?.isin } });
  };

  const NextPage = () => {
    setPage(page + 1);
  };

  const PreviousPage = () => {
    setPage(page - 1);
  };

  useEffect(() => {
    Stockscreenerlist();
  }, [page]);

  const Stockscreenerlist = async () => {
    const res = await FetchData(
      `catstocks`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      setStockscreener(res.data);
      const firstItem = res.data?.length > 0 ? res.data[0] : null;
      setCategory(firstItem._id)
      setfilteredItems(firstItem.stockall)
      setPaginationInfo(res.data?.pagination);
    }
  };
  const Imageview = ({ item }) => {
    const [imageError, setImageError] = useState(false);
    const stock = item?.screenerData.stock;

    const splitStock = (stock) => {
      if (!stock) return '';

      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase(); 
    };

    return (
      <div>
        {/* {!imageError ? (
          <img
            className=""
            src={item?.stockData?.img || ""}
            alt="img"
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              border: "0.5px solid #1B1B1B",
            }}
            onError={() => setImageError(true)}
          />
        ) : ( */}
          <div
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              border: "2px solid #7E66BC",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f0edf7",
              fontSize: "16px",
              fontWeight: 500,
            }}
          >
            {splitStock(item?.screenerData?.stock)}
          </div>
        {/* )} */}
      </div>
    );
  };

  useEffect(() => {
    fetchData3();
  }, []);

  const fetchData3 = async () => {
    const res = await FetchData(
      `industrytasis`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      setindustry(res.data)
    }
  };


  const handlestockvalue = (value) => {
    setCategory(value)

    const Items = Stockscreener?.find(
      (item) => item?._id == value

    );
    setfilteredItems(Items.stockall);

  };
  
  const splitStock = (item) => {
    const lettersOnlyRegex = /^[A-Za-z]/;
    const words = item.split(' ');
    const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
    const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

    return `${firstChar} ${secondChar}`;
  };
  const profiledetails = JSON.parse(localStorage.getItem("email"));

  return (
    <>
      <Navbar />

      {theme &&
        <div
          className=" home-header"
          style={{ width: "95%", margin: 'auto' }}
        >
          <div className="row" >
            <div className="col-lg-3 col-md-4 col-sm-6 pl-1" >
              <div className="card px-0" style={{ backgroundColor: theme ? '#1B1B1B' : '#fff', color: theme ? '#fff' : 'black' }}>
                <div className="p-3">
                  
                  <p className="profile-desc  mb-0">
                  <span  className="profile-desc" >Assalamu Alaikum,</span>{" "}
                  <span className="profile-name me-1"   style={{ color: "#7E66BC" }}>
                    <span>{profiledetails.first_name}</span> <span>{profiledetails.last_name}</span>!
                  </span>
                  Welcome back to <span className="fw-bold">
  Islamic Tijarat<sup style={{ fontSize: "0.6em"}}>TM</sup>
</span> —your trusted companion in ethical investing. May every step you take be filled with Barakah, and may your investments always flourish with integrity and peace of mind.

                  </p>
                </div>

                {/* <div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
                  <div className="mb-3 mb-md-0">
                    <img
                      className="img-fluid"
                      src="./images/trade.png"
                      alt="img"
                      style={{ maxHeight: "120px", maxWidth: "123px" }}
                    />
                  </div>
                  <div className="text-center mt-3 mt-md-0 ms-md-3">
                    <p
                      style={{ fontSize: "18px", fontWeight: 500 }}
                      className="text-center mb-0"
                    >
                      % SHARIAH Compliant
                    </p>
                  </div>
                </div> */}

                <hr />
                <div style={{ height: '560px' }}>
                  <Blogs themes={theme} />
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-8 herocard card  p-3" style={{ backgroundColor: theme ? '#1B1B1B' : '#fff' }} >
              <div className="row g-2">
                {/* Stock Screener Card */}
                <div className="col-12 col-md-6 col-lg-3">
                  <div
                    className="card py-3 border-0 stock-card"
                    onClick={() => { window.location.href = "/screener" }}
                  >
                    <div className="d-flex px-2 justify-content-between align-items-center">
                      <div className="p-2">
                        <h4 className="p-0 fw-bold m-0" style={{ fontSize: '18px' }}>STOCK</h4>
                        <p className="para p-0" style={{ fontSize: '16px' }}>Screener</p>
                      </div>
                      <div className="img-circle1 d-flex align-items-center"
                        style={{ position: 'absolute', right: -5, top: -5 }}
                      >
                        <img
                          className="mx-auto"
                          src="/images/icons/card1.png"
                          alt="img"
                          style={{ height: "22px", width: "22px" }}
                        />
                      </div>
                    </div>
                    <div className="px-3 d-flex mt-2 justify-content-between">
                      <div className="mt-2">
                        <p style={{ fontSize: '10px' }}>View all Stocks  <i className="fa fa-arrow-right" /></p>
                      </div>
                      <div>
                        <img
                          className="mx-auto stack-img"
                          src="/images/graph1.png"
                          alt="img"
                          style={{ height: "30px", width: "27px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* IPO Screener Card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                  <div
                    className="card py-3 border-0  stock-card"
                    onClick={() => { navigate("/iposcreen"); }}
                    style={{ background: "linear-gradient(292.72deg, rgb(193, 230, 250) -0.41%, rgb(178, 216, 250) 48.3%, rgb(143, 202, 227) 93.36%)" }}
                  >
                    <div className="d-flex px-2 justify-content-between align-items-center">
                      <div className="p-2">
                        <h4 className="p-0 fw-bold m-0" style={{ fontSize: '18px' }}>IPO</h4>
                        <p className="para p-0" style={{ fontSize: '16px' }}>Screener</p>
                      </div>
                      <div className="img-circle1 d-flex align-items-center"
                        style={{ position: 'absolute', right: -4, top: -5 }}
                      >
                        <img
                          className="mx-auto"
                          src="/images/islamic/ipo-dark.png"
                          alt="img"
                          style={{ height: "22px", width: "22px" }}
                        />
                      </div>
                    </div>
                    <div className="px-3 d-flex mt-2 justify-content-between">
                      <div className="mt-2">
                        <p style={{ fontSize: '10px' }}>Coming Soon <i className="fa fa-arrow-right" /></p>
                      </div>
                      <div>
                        <img
                          className="mx-auto stack-img"
                          src="images/graph2.png"
                          alt="img"
                          style={{ height: "35px", width: "32.5px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Purification Calculator Card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                  <div
                    className="card py-3 border-0  stock-card"
                    onClick={() => { window.location.href = "/purification" }}
                    style={{ background: "linear-gradient(248.68deg, rgb(233, 150, 138) -5.74%, rgb(238, 203, 192) 44.95%, rgb(183, 171, 242) 96.99%)" }}
                  >
                    <div className="d-flex px-2 justify-content-between align-items-center">
                      <div className="p-2">
                        <h4 className="p-0 fw-bold m-0" style={{ fontSize: '18px' }}>PURIFICATION</h4>
                        <p className="para p-0" style={{ fontSize: '16px' }}>Calculator</p>
                      </div>
                      <div className="img-circle1 d-flex align-items-center"
                        style={{ position: 'absolute', right: -4, top: -5 }}
                      >
                        <img
                          className="mx-auto"
                          src="/images/islamic/purify-dark.png"
                          alt="img"
                          style={{ height: "22px", width: "22px" }}
                        />
                      </div>
                    </div>
                    <div className="px-3 d-flex mt-2 justify-content-between">
                      <div className="mt-2">
                        <p style={{ fontSize: '10px' }}>Let's Purify Your Earnings <i className="fa fa-arrow-right" /></p>
                      </div>
                      <div>
                        <img
                          className="mx-auto stack-img"
                          src="/images/calculator.png"
                          alt="img"
                          style={{ height: "40px", width: "40px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Zakat Calculator Card */}
                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                  <div
                    className="card py-3 border-0 stock-card"
                    onClick={() => { window.location.href = "/zakat" }}
                    style={{ background: "linear-gradient(56.28deg, rgb(43, 205, 186) 13.28%, rgb(171, 193, 216) 55.12%, rgb(121, 158, 232) 90.75%)" }}
                  >
                    <div className="d-flex px-2 justify-content-between align-items-center">
                      <div className="p-2">
                        <h4 className="p-0 fw-bold m-0" style={{ fontSize: '18px' }}>ZAKAT</h4>
                        <p className="para p-0" style={{ fontSize: '16px' }}>Calculator</p>
                      </div>
                      <div className="img-circle1 d-flex align-items-center"
                        style={{ position: 'absolute', right: -4, top: -5 }}
                      >
                        <img
                          className="mx-auto"
                          src="/images/islamic/zakit-dark.png"
                          alt="img"
                          style={{ height: "22px", width: "22px" }}
                        />
                      </div>
                    </div>
                    <div className="px-3 d-flex mt-2 justify-content-between">
                      <div className="mt-2">
                        <p style={{ fontSize: '10px' }}>Let's Purify Your Wealth  <i className="fa fa-arrow-right" /></p>
                      </div>
                      <div>
                        <img
                          className="mx-auto stack-img"
                          src="/images/calculator.png"
                          alt="img"
                          style={{ height: "40px", width: "40px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-12 d-flex col-md-12 ">
                  <div className="col-lg-8  col-md-8 me-2 mt-3">
                    <div className="d-flex justify-content-between mb-1">
                      <h5 className="title-font"><span style={{color:'#fff'}} >Trending </span><span style={{ color: '#7E66BC' }}>Compliant Stocks</span></h5>
                    
                    </div>
                    <div style={{ border: '2px solid #242424', borderRadius: '10px' }}>
                      <div className="ms-2 mt-2 text-white tablehead-font">
                        Recently Added
                      </div>
                      <div className="d-flex justify-content-between px-2">
                        <div
                          className="d-flex"
                          style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '10px 0px', }}
                        >
                          {Array.isArray(Stockscreener) &&
                            Stockscreener.map((button, index) => (
                              <div
                                key={index}
                                className="p-1 me-1 select-value category-item"
                                style={{ backgroundColor: category === button._id ? '#7E66BC' : '#f0edf7',
                                  border:category === button._id ?"0.5px solid #f0edf7":"0.5px solid #7E66BC",
                                  color:category === button._id ?"#fff":"#000",
                                 }}
                                onClick={() => { handlestockvalue(button._id); }}
                              >
                                {button.categoryname.charAt(0).toUpperCase() + button.categoryname.slice(1)}
                              </div>
                            ))}
                        </div>
                      </div>

                      <table
                        class="table table-responsive mt-0"
                        style={{
                          textAlign: "start",
                          backgroundColor: "#1B1B1B",
                          borderRadius: "10px",
                          overflow: "hidden",
                          border: '1.5px solid #222222',
                          height: '500px'
                        }}
                      >
                        <thead style={{ backgroundColor: "#1B1B1B" }}>
                          <tr
                            style={{ textAlign: 'start', fontSize: "14px" }} >
                            <th className="text-secondary bg-transparent font-wight w-50 " scope="col ">Name</th>
                            <th className="text-secondary bg-transparent font-wight w-25 text-center" scope="col">Industry</th>
                            <th className="text-secondary bg-transparent font-wight" scope="col" >Compliance</th>
                            {/* <th scope="col" style={{fontSize:"14px",color:'#656565',backgroundColor: "#1B1B1B",fontWeight:500}}>Compliance</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {filteredItems &&
                            filteredItems.map((item, index) => {

                              return (
                                <tr className="mx-auto" style={{ cursor: 'pointer' }}>
                                  <th scope="row" className="w-50" style={{ backgroundColor: '#1B1B1B' }}>
                                    <div
                                      className="d-flex justify-content-start"
                                      onClick={() => handleRowClick(item)}


                                    >
                                      <Imageview item={item} />


                                      <div className="">
                                        <h3
                                          className="mb-0 table-list-value font-wight"

                                        >
                                          {item && item.stockData && item.stockData.stock_code ? item.stockData.stock_code : ""}

                                        </h3>
                                        <p
                                          className="mb-0 table-list-value font-wight"

                                        >
                                          {item?.screenerData.stock}

                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                  <td className="w-50"  style={{ fontSize: '12px', backgroundColor: '#1B1B1B', color: '#fff', fontWeight: '400' }}>
                                    {item?.screenerData.industry_group}
                                  </td>
                                  {item?.screenerData?.status == 1 ? (
                                    <td className="heder-title bg-color">
                                      <div className="d-flex p-1 justify-content-center" >
                                        <span className="heder-title" style={{ color: '#218303', fontWeight: "500" }}>Compliant</span>
                                        <span className="ms-1"><img src="/images/checklist.png" width={20} height={20} ></img></span>
                                      </div>
                                    </td>
                                  ) : item?.screenerData?.status == 2 || item?.screenerData?.status == 4 || item?.screenerData?.status == 6 || item?.screenerData?.status == 7 ? (
                                    <td className="heder-title bg-color">
                                      <div className="d-flex p-1 justify-content-center">
                                        <span className="heder-title" style={{ color: '#D43A3A', fontWeight: "500" }}>Non Compliant</span>
                                        <span className="ms-1"><img src="/images/non-permisable.png" width={20} height={20} ></img></span>
                                      </div>
                                    </td>
                                  ) : item?.screenerData?.status == 3 ? (
                                    <td className="heder-title bg-color">
                                      <div className="d-flex p-1 justify-content-center" >
                                        <span className="heder-title" style={{ color: '#2132CA', fontWeight: "500" }}>Unknown</span>
                                        <span className="ms-1"><img src="/images/checklist.png" width={20} height={20} ></img></span>
                                      </div>
                                    </td>
                                  ) :
                                    (<td className="heder-title bg-color">
                                      <div className="d-flex p-1 justify-content-center">
                                        <span className="heder-title" style={{ color: '#EFA625', fontWeight: "500" }}>Under Review</span>
                                        <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                                      </div>
                                    </td>
                                    )
                                  }

                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-4  col-md-4 ms-2 p-O  mt-5" style={{ border: '2px solid rgb(36, 36, 36)', borderRadius: '10px'}}>
                    < Newspage themes={theme} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
          <div className="container mt-4">
          <span className="ms-2 fs-5 text-white">Stocks by <span style={{color:'#7E66BC'}}>categories</span></span>
       <div
    className="d-flex justify-content-start align-items-center mt-3"
    style={{
      overflowX: "auto", // Horizontal scroll
      padding: "10px 0",
      whiteSpace: "nowrap",
      scrollBehavior: "smooth", // Smooth scrolling
      flexWrap:"wrap",
    }}
  >
   {industry && industry.slice(0, visibleItems).map((category, index) => (
      <div
        key={index}
        style={{
          backgroundColor: "#3D3D3D",
          margin: '10px 10px',
          width: "fit-content",
          borderRadius: "5px",
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 8px 0px", // Add shadow for depth
          cursor: "pointer",
          transition: "transform 0.3s", // Smooth hover effect
        }}
        onClick={()=>{navigate("/industry", { state: { value: category } });}}
        className="p-2 d-flex flex-row align-items-center category-card"
// Add onClick for category selection
      >
        <div className="img-circle3 d-flex align-items-center justify-content-center ">
          <img
            src={category.icon || "/images/icons/card1.png"}
            alt="img"
            style={{ height: "15px", width: "16px" }}
          />
        </div>
        <div className="text-white text-center ms-2" style={{fontSize:'12px'}}>{category.industry_group}</div>
      </div>
    ))}
  </div>
  <div className="mt-3 d-flex justify-content-end">
        <button onClick={handleToggle} className={`btn `} style={{color:"rgb(126, 102, 188)",fontSize:'14px'}}>
        {isExpanded ? (
      <>
        See Less Categories<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
  <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
</svg>
      </>
    ) : (
      <>
        See More Categories <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
  <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
</svg>
      </>
    )}
        </button>
      </div>
</div>
          </div>

          {/* bottom card */}
          {/* <div className="card mt-2">
                <div className="row p-3">
                  <div className="col-lg-12 col-md-12">
                    <div className="d-flex justify-content-between">
                      <h4 className="fonttitle ">Islamic Tijarat Academy</h4>
                      <button className="btn  text-success ">
                        View All{" "}
                        <i
                          class="fa-solid fa-arrow-right text-success"
                          style={{ color: "black", marginLeft: "5px" }}
                        ></i>{" "}
                      </button>
                    </div>
      
                    <p className="para">
                      Explore the portfolios of renowned investors. Track their Buys{" "}
                      <br /> and Sells to get inspired.
                    </p>
      
                    <div className="d-flex justify-content-around flex-wrap">
                      <img src="./images/cardimg.png" className="boximg" alt="img" />
                      <img src="./images/cardimg.png" className="boximg" alt="img" />
                      <img src="./images/cardimg.png" className="boximg" alt="img" />
                      <img src="./images/cardimg.png" className="boximg" alt="img" />
                    </div>
                  </div>
                </div>
              </div> */}
        </div>

      }
      {
        !theme &&
        <Dashboardlight />

      }

      <Footer />
    </>
  );
}


export default Newdashboard