import React ,{useState ,useEffect,useContext } from 'react';
import "./css/login.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import FetchData from '../../fetchapi/Apifetch';
import { useNavigate,useLocation } from "react-router-dom";
import { UserContext } from '../../Utills/UserContext';
import { signInWithEmailAndPassword,signInWithPopup } from 'firebase/auth';
import { auth ,googleAuthProvider} from "../../../firebase";
import OtpInput from 'react-otp-input';
import OTPInput from './Otpinput';
import DisclaimerModal from '../Disclaimer/DisclaimerModal';


const Otpverification = () => {
  const location = useLocation();
  const [Showmodel, setShowmodel] = useState(false);
  const [userid, setUserid] = useState('');
        const { theme, setTheme } = useContext(UserContext);
        const [buttonclick, setbuttonclick] = useState(false);
        const [buttonclickotp, setbuttonclickotp] = useState(false);
        const [email, setEmail] = useState('');
        const [resendotp, setResendotp] = useState(true);
        const [otptiming, setOtptiming] = useState(60);


        const [password, setPassword] = useState('');
        const navigate = useNavigate();
        const { user, setUser } = useContext(UserContext);
        const [formdata1, setFormdata1] = useState({
           email:location?.state?.data,  
           otp:""
        });

        const [formdataemail, setFormdataemail] = useState({
          email:location?.state?.data,  
        
       });



       useEffect(() => {
        if (formdata1?.otp.length == 6 ) {
          setbuttonclick(false); // Enable button when both fields have input
        } else {
          setbuttonclick(true); // Disable button if any field is empty
        }
      }, [formdata1.otp]);
      
        const CloseModel = async () => {

          try {
            const token = false;
            const data = {
              userstatus: true,
            };
            const res = await FetchData(`userstatus/${userid}`, 'POST', JSON.stringify(data), token, false);
            if (res.data) {
            
                localStorage.setItem("login",true);
                setUser(true)
                console.log('res.data',res.data) ;
                const jsonLoginData = JSON.stringify(res.data);
                localStorage.setItem("email",jsonLoginData);
                toast.success("Login successfully"); 
                // navigate("/dashboard");
                // window.location.href = "/dashboard" 
                setShowmodel(false)
              
            }
        } catch (error) {
            await toast.error(error.message);
            setbuttonclick(false);
          }
          
        }


        useEffect(() => {
          let timer;
          if (resendotp && otptiming > 0) {
            timer = setInterval(() => {
              setOtptiming((prevTime) => prevTime - 1);
            }, 1000);
          }
          if (otptiming == 0) {
            clearInterval(timer);
            setOtptiming(60);
            setResendotp(false);
            setbuttonclick(false);
            setbuttonclickotp(false);
          }
          return () => clearInterval(timer); // Cleanup timer
        }, [resendotp, otptiming]);


      

        const handleOtpChange = (otp1) => {
    
          // Update formdata1 with the new OTP value
          setFormdata1((prevFormdata) => ({
            ...prevFormdata, // Spread the previous form data to retain other fields
            otp: otp1,
            email:location?.state?.data // Set the otp field to the new OTP value
          }));
        };


        const UserAuthencationdata = async (username) => {
          try {
            // Ensure username exists before making the request
            if (!username) {
              console.error("Username is missing");
              return;
            }
            // Make API call to get the JWT token
            const res = await FetchData(`userAuth/${username}`, "GET", null, true, false);
        
            // Check if the response contains the token
            if (res && res.access_token) {
              console.log('Token received:', res.access_token);
        
              // Store the token in localStorage
              localStorage.setItem("TOKEN", res.access_token);
            } else {
              console.error("No access token received from the API");
            }
          } catch (error) {
            // Handle any errors during the request
            console.error("Error during user authentication:", error);
          }
        };

        const LoginCheck = async () => {
          try {
              setbuttonclick(true)
        
              const token = false;
      
              const res = await FetchData("otpverification", 'POST', JSON.stringify(formdata1), token, false);
              if (res.data) {
                console.log('res.data',res?.data?.email)
 
                if(location?.state?.user == true){

                  navigate("/password", { state: { data:res?.data?.email,user:true} });
                }
                else{
                await toast.success("OTP verified successfully");
                 if(!res?.data?.userstatus){
                  const Userfirstname = res?.data?.first_name;
                  await UserAuthencationdata(Userfirstname)
                  setShowmodel(true);
                  console.log('res',res.data);
                  setUserid(res?.data?._id)
                 }else{
                   const Userfirstname = res?.data?.first_name;
                   await UserAuthencationdata(Userfirstname)
                    localStorage.setItem("login",true);
                    setUser(true)
                    console.log('res.data',res.data) ;
                    const jsonLoginData = JSON.stringify(res.data);
                    localStorage.setItem("email",jsonLoginData);
                    toast.success("Login successfully");
                 }
                }

                  // window.location.href = "/dashboard" 
              }
          } catch (error) {
              toast.error(error.message);
              setbuttonclick(false)
          }
      }
      


      const Resendopt = async () => {
        try {
            setbuttonclickotp(true)
            const token = false;
            const res = await FetchData("resendotp", 'POST', JSON.stringify(formdataemail), token, false);
            if (res.data) {
              setbuttonclick(false)
              await setResendotp(true);
               await toast.success("Otp Resend Sucessfully");
                setUserid(res?.data?._id)
            }
        } catch (error) {
            toast.error(error.message);
            setbuttonclickotp(false)
        }
    }

      
      
      
        return (
          <>
          <div className={`container-fluid mt-5 ${theme?'text-white':'text-black'}`} style={{width:"80%",border:"1px solid #ededed"}}>
          <ToastContainer />
          <DisclaimerModal showModal={Showmodel} handleClose={CloseModel}  />
              <div className='row'>
              <div className='col-lg-6 col-md-6 col-sm-12  d-flex align-items-center bg-white'
              style={{
                background: "linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(/images/islamic/webdesign.png) no-repeat center center /cover"
             ,borderLeft:'2px solid #5b298c',borderTop:'2px solid #5b298c',borderBottom:'2px solid #5b298c'}}
             >
              <div className='p-4 w-100'>
              <h3 className='mb-1 ' style={{marginTop:"-10%"}}>OTP Verification</h3>
              <p className='mb-4'>Please enter the One-Time Password to verify your account</p>
        <div class="mb-3 mt-3 ">
          <label for="exampleInputEmail1" class="form-label">Enter OTP</label>
          <OTPInput length={6} onChange={handleOtpChange} />
        </div>


        {resendotp ?
          <div className='mt-5'>
          <p className='fw-bold text-center mt-5 mb-0'>Resend OTP in  {otptiming}s</p>
          <button type="submit" class="btn btn-primary w-100 mt-3" disabled={buttonclick} style={{backgroundColor:"rgb(121 100 175)"}} onClick={()=>{LoginCheck()}}>VERIFY</button>
          <p className='text-center my-2'>Or login with</p>
          </div>
        :
        <div>
        <p className='fw-bold text-center mt-5 mb-0'>Didn't get the OTP?</p>
        <button type="submit" class="btn btn-primary w-100 mt-2 mb-0" disabled={buttonclickotp} style={{backgroundColor:"rgb(121 100 175)"}} onClick={()=>{Resendopt()}}>RESEND OTP</button>
        </div>
        }
   
          </div>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 p-0 img-layout' >
        <img src="images/loginpage.png" alt='logo' style={{width:"100%",height:"100%"}} /> 
          <div className='text-overlay'>
          <img src="logonew.png" alt='logo' className='mt-1' style={{width:"130px",height:"130px"}} /> 
             <img src={"images/app/itg-logo1.png"}  alt="Logo" style={{width:"280px",height:"60px"}} />

          <p className='paratext px-4'> At Islamic Tijarat , we redefine what it means to invest with faith. Our industry-leading Shariah screening technology and uncompromising compliance standards make your investment journey seamless and precise, so you never have to choose between profits and principles. Pure Profits. Absolute Peace of Mind. Invest the Right Way—Shariah Compliant, Always. </p>
          <div className='mt-3'>
          <div className={`${theme ? "social-icons-dark" : "social-icons"} mb-4  mt-0`}>
          <a href="https://www.facebook.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/facebook.png' className='p-2 ' style={{backgroundColor:"white",borderRadius:'50%'}}  width={38} height={38} />
            </a>
            <a href="https://www.instagram.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/insta.png' className=' p-2 ' style={{backgroundColor:"white",borderRadius:'50%'}}    width={40} height={40} />
            </a>
            <a href="https://x.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/twitter.png'  className=' p-2 '  style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
            <a href="https://www.youtube.com/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/youtube.png' className='  p-2 '  style={{backgroundColor:"white",borderRadius:'50%'}}   width={35} height={35} />
            </a>
            <a href="https://www.linkedin.com/in/islamictijarat" target="_blank" rel="noopener noreferrer">
            <img src='images/linkedin.png'   className='p-2' style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
            <a href="https://whatsapp.com/channel/0029VakHKaFJENy4EzxloA13" target="_blank" rel="noopener noreferrer">
            <img src='images/whatsapp.png' className='p-2'   style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
            <img src='images/islamic/telegram.png' className='p-2'   style={{backgroundColor:"white",borderRadius:'50%'}}  width={35} height={35} />
            </a>
          </div>
          </div>
          </div>
        </div>
              </div>
          </div>
      
      
          
          </>
        )
      
}

export default Otpverification