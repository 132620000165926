import React, { createContext, useState, useEffect ,useContext} from 'react';

// Create the context
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Use lazy initialization to set the initial state from localStorage
  const [user, setUser] = useState(() => {
    const storedUser = JSON.parse(localStorage.getItem("login"));
    return storedUser || null;
  });
  const [theme, setTheme] = useState(() => {
    const theme = JSON.parse(localStorage.getItem("theme"));
    return theme || null;
  });
    // const [theme, setTheme] = useState(true)


  // Save user to localStorage when it changes
  useEffect(() => {
    if (user) {
      localStorage.setItem("login", JSON.stringify(user));
    } else {
      localStorage.removeItem("login");
    }
  }, [user]);
  useEffect(() => {
      localStorage.setItem("theme", theme);
  }, [theme]);

  // Define the context value
  const value = {
    user,
    setUser,
    theme, setTheme
  };

  // Provide the context to children components
  return (
    <UserContext.Provider value={value} >
      {children}
    </UserContext.Provider>
  );
};
