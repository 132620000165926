import React, { useEffect, useRef } from 'react';

const TechnicalsPage = ({ symbol, Themes2 }) => {
  const widgetContainerRef = useRef(null);

  useEffect(() => {
    // Create a blockquote element for the widget
    const blockquote = document.createElement('blockquote');
    blockquote.className = 'trendlyne-widgets';
    blockquote.dataset.getUrl = `https://trendlyne.com/web-widget/technical-widget/${symbol}/?posCol=00A25B&primaryCol=006AFF&negCol=EB3B00&neuCol=F7941E&theme=${Themes2 ? 'dark' : 'light'}`;

    // Inline style for transparency
    blockquote.style.backgroundColor = 'transparent';

    // Create the script element for the Trendlyne widget
    const script = document.createElement('script');
    script.src = 'https://cdn-static.trendlyne.com/static/js/webwidgets/tl-widgets.js';
    script.async = true;
    script.charset = 'utf-8';

    // Clear any previous content and append the new blockquote and script
    if (widgetContainerRef.current) {
      widgetContainerRef.current.innerHTML = ''; // Clear previous widget
      widgetContainerRef.current.appendChild(blockquote);
      widgetContainerRef.current.appendChild(script);
    }

    return () => {
      // Cleanup by clearing the container's inner HTML on unmount or props change
      if (widgetContainerRef.current) {
        widgetContainerRef.current.innerHTML = '';
      }
    };
  }, [symbol, Themes2]); // Re-run when symbol or theme changes

  return (
    <div
      ref={widgetContainerRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'transparent',
      }}
    ></div>
  );
};

export default TechnicalsPage;
