import React, { useEffect, useRef } from 'react';
import "./Swot.css";

const Swot = ({ symbol }) => {
  const stock_code = symbol;
  const widgetContainerRef = useRef(null);
  useEffect(() => {
    const elements = document.getElementsByClassName("tl__swot_box_scope--2y60Y");
    for (let i = 0; i < elements.length; i++) {
      elements[i].style.maxWidth = '100%';
    }
  }, []); 

  useEffect(() => {
    const blockquote = document.createElement('blockquote');
    blockquote.className = 'trendlyne-widgets';
    blockquote.dataset.getUrl = `https://trendlyne.com/web-widget/swot-widget/Poppins/${stock_code}/?posCol=00A25B&primaryCol=006AFF&negCol=EB3B00&neuCol=F7941E&theme=dark`;

    // Inline style for transparency
    blockquote.style.backgroundColor = 'transparent';

    const script = document.createElement('script');
    script.src = 'https://cdn-static.trendlyne.com/static/js/webwidgets/tl-widgets.js';
    script.async = true;
    script.charset = 'utf-8';

    if (widgetContainerRef.current) {
      // Clear any previous content before adding new elements
      widgetContainerRef.current.innerHTML = '';

      // Append blockquote and script to container
      widgetContainerRef.current.appendChild(blockquote);
      widgetContainerRef.current.appendChild(script);
    }

    return () => {
      // Cleanup by clearing inner HTML
      if (widgetContainerRef.current) {
        widgetContainerRef.current.innerHTML = '';
      }
    };
  }, [stock_code]);

  return (
    <div
      ref={widgetContainerRef}
      style={{
        backgroundColor: 'transparent', // Ensure the container is transparent
        display: 'flex',                // Flex display for centering
        justifyContent: 'center',       // Center content horizontally
        alignItems: 'center',           // Center content vertically (optional, if needed)
        width: '100%',                  // Auto width to fill the container
        height: 'auto',                 // Auto height to adjust to content
      }}
    ></div>
  );
};

export default Swot;
