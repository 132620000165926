import React, { useEffect, useState, useCallback, useContext, useRef } from 'react';
import { debounce } from 'lodash';
import "./style.css";
import { useNavigate, useLocation } from "react-router-dom";
import _ from 'lodash';
import { UserContext } from '../../Utills/UserContext';
import io from "socket.io-client";
// const socket = io("http://3.108.189.162")
const socket = io("https://islamicapi.qcodesinfotech.com")


function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [userprofile, setUserProfile] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [notification, setNotification] = useState(false);
  const [search, setsearch] = useState(false);
  const [searchvalue, setsearchvalue] = useState('');
  const [query, setQuery] = useState([]);
  const [loader, setloader] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [istab, settab] = useState(window.innerWidth <= 1000);
  const name = JSON.parse(localStorage.getItem("email"));
  const { theme, setTheme } = useContext(UserContext);
  const [Stocklist, setStocklist] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [notifications, setNotifications] = useState([]);
  const [notificationVisible, setNotificationVisible] = useState(false);

  // Fetch notifications from localStorage on component mount
  // useEffect(() => {

  // }, [socket]);

  // Count the number of unread notifications (status: false)
  const unreadCount = notifications.filter(notification =>
    notification.user.some(user => user.status === false)).length;

  // Handle notification click to mark it as read (status: true)
  // const handleNotificationClick = (index) => {
  //   const updatedNotifications = [...notifications];
  //   updatedNotifications[index].user[0].status = true;  // Assuming a single user object in the array

  //   // Update the state and localStorage
  //   setNotifications(updatedNotifications);
  //   localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
  // };

  const handleNotificationClick = () => {
    const updatedNotifications = notifications.map(notification => {
      // Set the status of each notification to true
      notification.user[0].status = true;
      return notification;
    });



    // Update the state and localStorage
    setNotifications(updatedNotifications);
    localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
  };




  useEffect(() => {
    socket.on("notificationalert", (data) => {
      console.log('data', data)
      alert('notification received');
      listnotification()
    });

  }, [socket]);

  useEffect(() => {
    const existingNotifications1 = JSON.parse(localStorage.getItem('notifications')) || [];
    setNotifications(existingNotifications1);

  }, []);



  useEffect(() => {
    // Define the socket event handler
    const handleNotificationAlert = (data) => {
      const existingNotifications = JSON.parse(localStorage.getItem('notifications')) || [];
      const updatedNotifications = [...existingNotifications, data];
      localStorage.setItem('notifications', JSON.stringify(updatedNotifications));
      console.log('Updated notifications:', updatedNotifications);
      setNotifications(updatedNotifications);

    };
    // Add the event listener
    socket.on("notificationalert", handleNotificationAlert);

    // Cleanup to avoid adding multiple listeners
    return () => {
      socket.off("notificationalert", handleNotificationAlert);
    };

  }, [socket]);




  const listnotification = async () => {
    // Check if Groupvalue1 is valid
    try {
      // Make the API call
      const res = await (
        `getnotification`,
        "GET",
        null,
        true,
        false
      );

      // Process the response
      if (res) {
        const datavalue = res?.data;
        const jsonData1 = JSON.stringify(datavalue);
        localStorage.setItem("notifications", jsonData1);;

      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      // Reset the message text regardless of the outcome
    }
  };

  const HandleNotificationAlertopen = () => {
    handleNotificationClick();
    setNotificationVisible(!notificationVisible)

  }


  const handlelogout = () => {
    window.location.href = "/";
    setUser(null);
    localStorage.clear();
  };

  const toggleDarkTheme = () => {
    setTheme(!theme)
    // window.location.reload();
    document.body.classList.toggle('dark-theme');
  };

  const itemRefs = useRef([]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) =>
        prevIndex < query.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'Enter' && selectedIndex !== -1) {
      handlesearch(query[selectedIndex]);
    }
  };

  useEffect(() => {
    if (selectedIndex !== -1 && itemRefs.current[selectedIndex]) {
      itemRefs.current[selectedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  useEffect(() => {
    const fetchStockData = async () => {
      const stockData = JSON.parse(localStorage.getItem('stockdata'));
      // console.log('stockData', stockData);
      // Filter stock data based on stock or stockCode
      const items = stockData && stockData?.filter((item) => {
        const stockName = item.stock?.replaceAll(' ', '').toLowerCase();
        const stockCode = item.stock_code?.replaceAll(' ', '').toLowerCase();
        const searchTerm = searchvalue.replaceAll(' ', '').toLowerCase(); // Remove spaces from searchvalue

        // Check if either stock name or stock code matches the search value
        return (
          stockName.includes(searchTerm) || stockCode.includes(searchTerm)
        );
      }).slice(0, 20);
      setQuery(items || []);
    };
    fetchStockData();
  }, [searchvalue]);


  const handlesearch = async (items) => {
    console.log('items', items.stock)
    console.log('stock', Stocklist);
    setsearchvalue('');
    setsearch(false);
    navigate("/stock", { state: { data: items.isin } });
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleThemeChange = () => {
    if (location.pathname !== '/stock') {
      window.location.reload();
    }
    setTheme(!theme);
  }

  const DropdownImage = ({ item }) => {
    const [imageError, setImageError] = useState(false);
    const stock = item?.stock;

    const splitStock = (stock) => {
      if (!stock) return '';

      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase(); // Convert to uppercase if needed
    };

    // Determine if we should show the fallback
    const shouldShowFallback = imageError || !item?.img;



    return (
      <div>
        {/* {!shouldShowFallback ? (
          <img
            className=""
            src={item.img}
            alt="Stock Image"
            style={{
              height: '40px',
              width: '40px',
              borderRadius: '50%',
              marginLeft: '10%',
              border: '0.5px solid #fff',
              objectFit: 'cover', // Ensures the image covers the element without distortion
            }}
            onError={() => setImageError(true)} // Set imageError to true on load error
          />
        ) : ( */}
        <div

          style={{
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            border: theme? '1px solid #7E66BC':"1px solid #5b298c",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: "10%",
            fontWeight: 500,
            backgroundColor: '#f0edf7',
            fontSize: '16px',
            color: '#333',
          }}
        >
          {splitStock(stock)}
        </div>
        {/* )} */}
      </div>
    );
  };



  const DropdownImage2 = ({ item, width, height, font, value }) => {
    const [imageError2, setImageError2] = useState(false);
    const stock1 = `${item?.first_name || ''} ${item?.last_name || ''}`;
    const splitStock1 = (stock1) => {
      if (!stock1) return '';

      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock1.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase(); // Convert to uppercase if needed
    };

    const shouldShowFallback = imageError2 || !item?.img;



    return (
      <div className='pointer'>
        <div
          className='marginresponsive'
          style={{

            height: height,
            width: width,
            borderRadius: '50%',
            border: theme ? '0.5px solid #fff' : value ? '0.5px solid gray' : '0.5px solid #fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 500,
            backgroundColor: theme ? '#e6e6e6b0' : value ? "#fff" : '#e6e6e6b0',
            fontSize: font,
            color: '#333',
          }}

        >
          {splitStock1(stock1)}
        </div>
        {/* )} */}
      </div>
    );
  };


  return (
    <div className='' style={{paddingBottom:isMobile?"80px":"100px"}}>
      <nav
        className="navbar navbar-expand-lg navbar-light position-fixed top-0"
        style={{
            background: theme
              ? `linear-gradient(to bottom, rgba(3, 0, 12, 75%), rgba(3, 0, 12, 75%)), url(/images/islamic/webdesign.png) no-repeat center center / cover`
              : `linear-gradient(to bottom, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(/images/islamic/webdesign.png) no-repeat center center /cover`,
          backgroundSize: "cover", // Ensure the image covers the navbar area
          backgroundPosition: "center", // Center the background image
          backgroundColor: theme ? "rgba(32, 32, 32, 0.8)" : "rgba(255, 255, 255, 0.8)", // Semi-transparent background color
          // backgroundColor: theme ? "#202020" : "#fff", // Conditional background color
          boxShadow: "0px 0px 4px 0px #00000040", // Box shadow for a subtle effect
          padding: "15px 30px", // Navbar padding
          height: isMobile ? '60px' : "70px", // Height of the navbar

        }}
      >
      
        <div className="container-fluid p-0">
          {/* <a className={`navbar-brand font-head nav-title ${theme ? "text-white" : "text-black"}`} href="/dashboard" style={{ fontWeight: "600" }} >
            Islamic <span style={{ color: "#7E66BC" }}>Tijarat</span>{" "}
            <img src="images/app/itg-logo-light.png"/>
          </a> */}
          <a  onClick={() => {
          navigate('/dashboard');
          window.scrollTo(0, 0); // Scroll to top of the page
        }} className='navbar-brand ms-1 pointer'>
            <img src={theme ? "images/app/itg-logo1.png" : "images/app/itg-logo-light1.png"} className='app-logo' />
          </a>

          <div className="search-container mobileview" style={{ position: 'relative' }}>
            <div className="d-flex">
              <input
                className={`search-input ms-2 me-2 ${theme ? "navsearchheader1" : "navsearchheaderlight"}`}
                type="search"
                value={searchvalue}
                onChange={(e) => {
                  setsearch(true);
                  if (e.target.value.length === 0) {
                    setsearch(false);
                  }
                  setsearchvalue(e.target.value);
                }}
                onKeyDown={(e) => handleKeyDown(e)}
                placeholder="Search Stocks"
                aria-label="Search"
                style={{
                  border: "0.5px solid #ededed",
                  width: isMobile ? "100%" : "",
                }}
              />
            </div>

            <div
              className={`${theme ? '' : 'card'}`}
              style={{
                position: "absolute",
                top: '100%',  // Place it directly below the input
                left: 0,  // Align the left edge with the search bar
                width: '100%',  // Match the width of the input
                display: search ? "block" : "none",
                zIndex: 999,
              }}
            >
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <div
                  className="w-100"
                  style={{
                    maxHeight: '300px',
                    overflowY: 'auto',
                    fontSize: 14,
                    backgroundColor: theme ? "rgb(51, 51, 51)" : "transparent"
                  }}
                >
                  {query.length > 0 ? (
                    query.map((item, index) => (
                      <div
                        key={index}
                        ref={(el) => (itemRefs.current[index] = el)}  // Assign each item ref
                        onClick={() => handlesearch(item)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          padding: '5px',
                          backgroundColor: selectedIndex === index ? 'rgb(136 113 192 / 73%)' : theme ? 'rgb(51, 51, 51)' : 'transparent',
                          color: selectedIndex === index ? 'white' : theme ? "white" : 'black',
                          cursor: 'pointer',
                          borderBottom: theme ? '1px solid #424242' : '1px solid #ddd',
                        }}
                      >
                        <DropdownImage item={item} />

                        {/* Container to distribute space evenly between stock_code and stock */}

                        <div className="font-weight-normal   w-25 ms-4" style={{ color: selectedIndex === index ? 'white' : 'gray' }}>{item.stock_code}</div>
                        <div className="w-50">{item.stock}</div>
                      </div>

                    ))
                  ) : (
                    <div className={`text-center`}>
                      <p className={`fs-6 fw-bold ${theme ? 'text-white' : 'text-dark'}`}>No Stocks found</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            className={`navbar-toggler ${theme ? 'text-white bg-white' : ''}`}
            type="button"
            onClick={toggleNavbar}
            aria-controls="navbarSupportedContent"
            aria-expanded={isOpen}
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${theme ? "dark-backcolor" : 'backcolor'} backcolor ${isOpen ? 'show' : ''}`} id="navbarSupportedContent" style={{ padding: isMobile ? '5%' : "" }}>
            <div className="search-container searchview" style={{ position: 'relative', width: isMobile ? '100%' : istab ? "100%" : "" }}>
              <div className="d-flex">
                <input
                  className={`search-input ms-2 me-2 ${theme ? "navsearchheader1" : "navsearchheaderlight"}`}
                  type="search"
                  value={searchvalue}
                  onChange={(e) => {
                    setsearch(true);
                    if (e.target.value.length === 0) {
                      setsearch(false);
                    }
                    setsearchvalue(e.target.value);
                  }}
                  onKeyDown={(e) => handleKeyDown(e)}
                  placeholder="Search Stocks"
                  aria-label="Search"
                  style={{
                    border: "0.5px solid #ededed",
                    width: isMobile ? "100%" : "",
                  }}
                />
              </div>

              <div
                className={`${theme ? '' : 'card'}`}
                style={{
                  position: "absolute",
                  top: '100%',  // Place it directly below the input
                  left: 0,  // Align the left edge with the search bar
                  width: '100%',  // Match the width of the input
                  display: search ? "block" : "none",
                  zIndex: 999,
                }}
              >
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <div
                    className="w-100"
                    style={{
                      maxHeight: '300px',
                      overflowY: 'auto',
                      fontSize: 14,
                      backgroundColor: theme ? "rgb(51, 51, 51)" : "transparent"
                    }}
                  >
                    {query.length > 0 ? (
                      query.map((item, index) => (
                        <div
                          key={index}
                          ref={(el) => (itemRefs.current[index] = el)}  // Assign each item ref
                          onClick={() => handlesearch(item)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '5px',
                            backgroundColor: selectedIndex === index ? 'rgb(136 113 192 / 73%)' : theme ? 'rgb(51, 51, 51)' : 'transparent',
                            color: selectedIndex === index ? 'white' : theme ? "white" : 'black',
                            cursor: 'pointer',
                            borderBottom: theme ? '1px solid #424242' : '1px solid #ddd',
                          }}
                        >
                          <DropdownImage item={item} />

                          {/* Container to distribute space evenly between stock_code and stock */}

                          <div className="font-weight-normal   w-25 ms-4" style={{ color: selectedIndex === index ? 'white' : 'gray' }}>{item.stock_code}</div>
                          <div className="w-50">{item.stock}</div>
                        </div>

                      ))
                    ) : (
                      <div className={`text-center`}>
                        <p className={`fs-6 fw-bold ${theme ? 'text-white' : 'text-dark'}`}>No Stocks found</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* )} */}
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a
                  className={`nav-link nav-tab-text  ${location.pathname == '/dashboard' ? "color-primary" : (theme ? "text-white" : "text-black")} `} aria-current="page"
                  onClick={() => { navigate("/dashboard") }}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className={`nav-link nav-tab-text   ${location.pathname == '/screener' ? "color-primary" : (theme ? "text-white" : "text-black")}`}
                  onClick={() => { navigate("/screener"); }}
                >Screener</a>
              </li>
              <li className="nav-item">
                <a className={`nav-link nav-tab-text   ${location.pathname == '/watchlist' ? "color-primary" : (theme ? "text-white" : "text-black")}`}
                  onClick={() => { navigate("/watchlist"); }}
                >
                  Watchlist
                </a>
              </li>

              <li className="nav-item">
                <a className={`nav-link nav-tab-text   ${location.pathname == '/premium' ? "color-primary" : (theme ? "text-white" : "text-black")}`}
                  onClick={() => { navigate("/premium"); }}
                >
                  Premium
                </a>
              </li>


              {/* <li className="nav-item">
                <a className={`nav-link nav-tab-text   ${location.pathname == '/community' ? "color-primary" : (theme ? "text-white" : "text-black")}`}
                  onClick={() => { navigate("/community"); }}
                >
           Community
                </a>
              </li> */}
              <li className="nav-item position-relative">
                <a
                  className="nav-link nav-tab-text"
                  onClick={() => { HandleNotificationAlertopen() }}
                  style={{ position: "relative" }} // Ensure the icon can position the badge correctly
                >
                  <i className={`fa-solid fa-bell fs-4 ${theme ? "text-white" : "text-black"}`}></i>

                  {unreadCount > 0 && (
                    <span className="notification-badge">
                      {unreadCount}
                    </span>
                  )}
                </a>
              </li>


              <div
                className="notification-list"
                onMouseEnter={() => setNotificationVisible(true)}
                onMouseLeave={() => setNotificationVisible(false)}
                style={{
                  position: "absolute",
                  top: 50,
                  right: "8%",
                  width: 350,
                  display: notificationVisible ? "block" : "none",
                  zIndex: 999,
                  backgroundColor: theme ? '#2C2C2E' : '#FFFFFF', // Dark mode or light mode background
                  borderRadius: '10px',
                  padding: '15px',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                  color: theme ? '#FFFFFF' : '#000000',
                }}
              >
                <div className="list-group">
                  {notifications.map((notification, index) => (
                    <div
                      key={index}
                      className="notification-item d-flex justify-content-between align-items-center"
                      onClick={() => handleNotificationClick(index)} // Mark notification as read on click
                      style={{
                        backgroundColor: notification.user[0].status === false ? '#e9f7fa' : 'transparent', // Highlight new messages
                        borderRadius: '5px',
                        marginBottom: '10px',
                        padding: '10px 15px',
                        transition: 'background-color 0.3s',
                        cursor: 'pointer',
                        position: 'relative',
                      }}
                    >
                      <div className="flex-grow-1">
                        <span className="fw-bold" style={{ fontSize: '14px' }}>{notification.notification}</span>
                        <p className="mb-0" style={{ color: "gray", fontSize: "12px" }}>
                          {notification.role} | {notification.time}
                        </p>
                        {notification.user[0].status === false && ( // Show "New" badge if status is false 
                          <span className="badge bg-success text-white" style={{ position: 'absolute', top: '5px', right: '5px' }}>New</span>
                        )}
                      </div>
                      <i className="fa fa-bell text-secondary" style={{ fontSize: '18px', marginLeft: '10px' }} />
                    </div>
                  ))}

                </div>
              </div>



            </ul>

            <ul className="mb-0 position-relative list-unstyled ms-2">
              <div onClick={() => {
                setUserProfile(!userprofile); // Toggles the user profile dropdown
              }}>
                <DropdownImage2
                  item={name}
                  width={"40px"}
                  height={"40px"}
                  font={"16px"}
                  value={true}
                />
              </div>
              <div
                onMouseLeave={() => {
                  setUserProfile(false);
                }}
                onMouseEnter={() => setUserProfile(true)}
                className="card p-2 shadow"
                style={{
                  position: "absolute",
                  top: "50px",
                  right: "10px",
                  width: "300px",
                  display: userprofile ? "block" : "none",
                  zIndex: 999,
                  borderRadius: "8px",
                  backgroundColor: theme ? '#333333' : "#fff",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)"
                }}
              >
                <div className="d-flex flex-column p-3">
                  <div className="d-flex align-items-center">
                    <span>
                      <DropdownImage2 item={name} width={"50px"} height={"50px"} font={"20px"} />
                    </span>
                    <div className="ms-3 d-flex flex-column" style={{ fontSize: "14px" }}>
                      <p className="mb-0 d-flex align-items-center gap-1" style={{ color: '#7E66BC' }}>
                        Assalamu Alaikum
                      </p>
                      <span className={` fw-bold ${theme ? 'text-white' : 'text-dark'}`}>{name?.first_name} <span className="ms-1">{name?.last_name}</span></span>
                    </div>
                  </div>
                  <hr className="my-3" />

                  {/* Profile Options List */}
                  <div className="w-100" style={{ fontSize: "14px" }}>
                    <ul className="list-group list-group-flush w-100 text-start" style={{
                      cursor: 'pointer'
                    }}>
                      {/* Profile Details */}
                      <li className={`d-flex align-items-center p-2 justify-content-between ${theme ? '' : 'hover-shadow'}`}>
                        <span className={`ms-2 ${theme ? 'text-white' : 'text-dark'}`} onClick={() => {navigate("/profile"); }}>Profile Details</span>
                      </li>

                      {/* <li className={`d-flex align-items-center p-2 justify-content-between ${theme ? '' : 'hover-shadow'}`}>
                        <span className={`ms-2 ${theme ? 'text-white' : 'text-dark'}`} onClick={() => { window.location.href = "/chat"; }}>Community</span>
                      </li> */}

                      {/* Subscription with ">" symbol */}
                      <li className={`d-flex align-items-center p-2 justify-content-between ${theme ? '' : 'hover-shadow'}`}>
                        <span className={`ms-2 ${theme ? 'text-white' : 'text-dark'}`}onClick={() => {navigate("/plan"); }}>Subscription plan</span>
                      </li>

                      {/* Refer a friend with number */}
                      <li className={`d-flex align-items-center p-2 justify-content-between ${theme ? '' : 'hover-shadow'}`}>
                        <span className={`ms-2 ${theme ? 'text-white' : 'text-dark'}`} >Refer a Friend</span>
                        {/* <span className={` ${theme ?'text-white':'text-dark'}`}>23</span> */}
                      </li>

      
                      <hr className="my-2" />

                      {/* Help Center with ">" symbol */}
                      <li className={`d-flex align-items-center p-2 justify-content-between ${theme ? '' : 'hover-shadow'}`}>
                        <span className={`ms-2 ${theme ? 'text-white' : 'text-dark'}`} >Help Center</span>
                        <span className={` ${theme ? 'text-white' : 'text-dark'}`}>&gt;</span>
                      </li>

                      {/* Notifications with ">" symbol */}
                      <li className={`d-flex align-items-center p-2 justify-content-between ${theme ? '' : 'hover-shadow'}`}>
                        <span className={`ms-2 ${theme ? 'text-white' : 'text-dark'}`}>Notifications</span>
                        <span className={` ${theme ? 'text-white' : 'text-dark'}`}>&gt;</span>
                      </li>

                      {/* Horizontal line after "Help Center" and "Notifications" */}
                      <hr className="my-2" />

                      {/* Dark Theme Toggle */}
                      <li
                        className={`d-flex align-items-center p-2 justify-content-between ${theme ? '' : 'hover-shadow'}`}
                        style={{
                          borderRadius: '10px',
                          backgroundColor: theme ? '#2c3e50' : '#ecf0f1',
                          boxShadow: theme ? 'none' : '0 4px 10px rgba(0, 0, 0, 0.1)',
                          padding: '10px',
                          transition: 'background-color 0.4s ease',
                        }}
                      >
                        <span
                          className={`ms-2 ${theme ? 'text-white' : 'text-dark'}`}
                          style={{ fontSize: '14px', fontWeight: '500', transition: 'color 0.4s ease' }}
                        >
                          <span className='me-2'>{theme ? 'Dark' : 'Light'}</span>
                          Theme
                        </span>
                        <label className="toggle-wrapper" style={{ position: 'relative', display: 'inline-block', width: '78px', height: '35px' }}>
                          <input
                            type="checkbox"
                            checked={theme}
                            onChange={() => handleThemeChange()}
                            style={{ display: 'none' }}
                          />
                          <span
                            className="toggle-slider"
                            style={{
                              position: 'absolute',
                              cursor: 'pointer',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              backgroundColor: theme ? '#9598a1' : '#bdc3c7',
                              borderRadius: '34px',
                              transition: 'background-color 0.4s ease',
                              boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                            }}
                          ></span>
                          <span
                            className="toggle-circle"
                            style={{
                              position: 'absolute',
                              height: '32px',
                              width: '32px',
                              borderRadius: '50%',
                              backgroundColor: '#ffffff',
                              top: '2px',
                              left: theme ? '42px' : '2px',
                              transform: theme ? 'rotate(360deg)' : 'rotate(0deg)', // Rotation effect
                              transition: 'left 0.4s ease, background-color 0.4s ease, transform 0.4s ease', // Added transform transition
                              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              className="icon-sun"
                              style={{
                                fontSize: '18px',
                                color: '#f39c12',
                                display: theme ? 'none' : 'block',
                                opacity: theme ? '0' : '1',
                                transition: 'opacity 0.4s ease',
                              }}
                            >
                              ☀️
                            </span>
                            <span
                              className="icon-moon"
                              style={{
                                fontSize: '18px',
                                color: '#f1c40f',
                                display: theme ? 'block' : 'none',
                                opacity: theme ? '1' : '0',
                                transition: 'opacity 0.4s ease',
                              }}
                            >
                              🌙
                            </span>
                          </span>
                        </label>
                      </li>

                    </ul>
                  </div>
                  {/* Logout Button */}
                  <div className="w-100 mt-3">
                    <button
                      className="btn w-100  btn-sm rounded-pill"
                      onClick={() => {
                        handlelogout();
                      }}
                      style={{ fontWeight: "bold", letterSpacing: "1px", backgroundColor: '#9677cb', color: 'white' }}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
