import React, { useContext,useState } from 'react';
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import "../Purificationcalculator/purify.css"
import { UserContext } from '../../Utills/UserContext';
import "./comingsoon.css"

const Premium = () => {
  const { theme } = useContext(UserContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  return (
    <div>
      <Navbar />

      {/* Main Content */}
      <div className="home-header padding-header" style={{ width: '95%', margin: 'auto',height:isMobile?"100vh":"70vh" }}>
        <div className="row mobilemarign" >
          <div className="d-flex justify-content-center align-items-center" >
            <div className="text-center mt-3">
              <img src='/images/comingsoon.png' alt='commingsoon' style={{width:'40%'}} />
              <p className={`mt-3 ${theme ? "text-white" :'text-dark'} mx-auto`} style={{ fontSize: '16px', width: '80%', textAlign: 'center' }}>
             <span className={`${theme ? "text-white" :'text-dark'} fw-bolder`} >Bismillah! A Big Announcement Awaits! </span> 
We’re thrilled to share that our exclusive Premium Features are launching soon! Elevate your investment journey with advanced tools, guided by Shariah principles, to ensure purity and compliance at every step.

Stay tuned and be among the first to benefit— <span className='fw-bolder'>Insha’Allah</span> , a game-changer is coming your way!

              </p>
              
              {/* Input Field with "Notify Me" Button */}
              <div className="mt-4 d-flex justify-content-center">
                <div className="input-group" style={{ maxWidth: '400px' }}>
                  <input 
                    type="text" 
                    className={`form-control text-center ${theme?'placeholder-dark':'placeholder-light'}`} 
                    placeholder="Please enter your email address"
                    style={{
                      backgroundColor:theme?'#424242':'',
                      color:theme? "#fff":'black',
                    }}
                  />
                  <button className="btn" type="button" style={{backgroundColor:theme?'#fff':'#424242',color:theme?'black':'#fff'}}>
                    Notify Me
                  </button>
                </div>
              </div>
              <div className="contact-section text-center py-4 d-flex justify-content-center align-items-center">
        <span className={`me-3 ${theme?'text-white':'text-dark'}`}>Contact Us</span>

      
        <div className='d-flex justify-content-center align-items-center' style={{ backgroundColor: '#424242', width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}>
          <i className="fas fa-envelope" style={{ color: '#fff' }}></i>
        </div>  

  
        <div className='d-flex justify-content-center align-items-center' style={{ backgroundColor: '#424242', width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}>
          <i className="fab fa-instagram" style={{ color: '#fff' }}></i>
        </div>  

      
        <div className='d-flex justify-content-center align-items-center' style={{ backgroundColor: '#424242', width: '30px', height: '30px', borderRadius: '50%' }}>
          <i className="fab fa-facebook" style={{ color: '#fff' }}></i>
        </div>  
      </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Us Section */}
 

      {/* Optional Footer */}
      <Footer />
    </div>
  );
}

export default Premium;
