import React, { useEffect, useRef } from 'react';
const Fundemental = ({ symbol , themes }) => {
    const widgetRef = useRef(null);
console.log('symbol',symbol)
 const stock_code =  symbol

    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-financials.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        "isTransparent": true,
        "largeChartUrl": "",
        "displayMode": "regular",
        "width": "100%",
        "backgroundColor": themes ? "#1B1B1B" :'#fff',
        "height": "800", // Adjust the height as needed
        "colorTheme":themes ? 'dark':'light',
        "symbol": stock_code,
        "locale": "in"
      });
  
      if (widgetRef.current) {
        widgetRef.current.appendChild(script);
      }
  
      return () => {
        if (widgetRef.current) {
          widgetRef.current.innerHTML = '';
        }
      };
    }, [symbol]);
  
    return (
        <div className="tradingview-widget-container" ref={widgetRef}>
          <div className="tradingview-widget-container__widget"></div>
          <div className="tradingview-widget-copyright">
            <a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank">
              {/* <span className="blue-text">Track all markets on TradingView</span> */}
            </a>
          </div>
        </div>
      );
}

export default Fundemental