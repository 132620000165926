import React, { useState, useEffect, useContext, useRef } from 'react';
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import './watchlist.css';
import FetchData from '../../fetchapi/Apifetch';
import { UserContext } from '../../Utills/UserContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faEdit } from '@fortawesome/free-solid-svg-icons';

const Watchpage = () => {
  const navigate = useNavigate();
  const { theme } = useContext(UserContext);
  const [watchlist, setWatchlist] = useState([]);
  const [buttonclick, setbuttonclick] = useState(true);


  const [watchlistcat, setWatchlistCat] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deletecategoryID, setdeletecategoryID] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [query, setQuery] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [editvalue, setEditvalue] = useState("");
  const [editbutton, setEditbutton] = useState(true);
  
  const [editvalueid, setEditvalueid] = useState("");
  const profiledetails = JSON.parse(localStorage.getItem("email"));
  const [formdata, setFormdata] = useState({
    user_id: profiledetails._id,
    title: "",
    status: 1
  });

  const [formdata1, setFormdata1] = useState({
    user_id: profiledetails._id,
    watchlist_cat_id: "",
    stock_symbol: "",
    status: 1,
  });

  const [deleteformdata, setdeleteformdata] = useState({
    user_id: profiledetails._id,
    watchlist_cat_id: "",
    stock_symbol: "",
  });

  const [stockwatchlist, setStockwatchlist] = useState({
    user_id: profiledetails._id,
    watchlist_cat_id: "",
  });

  useEffect(() => {
    fetchData();
    getValue();
  }, []);



  useEffect(() => {
    if (formdata?.title?.trim().length > 0) {
      setbuttonclick(false); // Enable button when the title has input
    } else {
      setbuttonclick(true); // Disable button if the title is empty
    }
  }, [formdata?.title]);

  useEffect(() => {
    fetchStockData();
  }, [searchValue]);

  const fetchStockData = async () => {
    const stockData = JSON.parse(localStorage.getItem('stockdata'));

    const items = stockData?.filter((item) => {
      const stockName = item.stock?.replaceAll(' ', '').toLowerCase();
      const stockCode = item.stock_code?.replaceAll(' ', '').toLowerCase();
      const searchTerm = searchValue.toLowerCase();

      return stockName.includes(searchTerm) || stockCode.includes(searchTerm);
    }).slice(0, 20);

    setQuery(items || []);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormdata({ ...formdata, [name]: value });
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    },[]);

  // const handleInputChangewatchlist = (e) => {
  //   const { name, value } = e.target;

  //   setFormdata1({ ...formdata1, [name]: value });
  //   setdeleteformdata((prevFormdata) => ({
  //     ...prevFormdata,
  //     watchlist_cat_id: value,
  //   }));

  //   listStocks(value);
  // };

  const handleInputChangewatchlist = (id, title) => {
    setFormdata1({ ...formdata1, watchlist_cat_id: id });
    setdeleteformdata((prevFormdata) => ({
      ...prevFormdata,
      watchlist_cat_id: id,
    }));

    setSelectedItem(title); // Update the selected item in the dropdown
    listStocks(id); // Call the function to list stocks based on the selected value
    setIsOpen(false)
  };

  const handleSendReport = async () => {
    try {
      const res = await FetchData("addwatchlistcat", 'POST', JSON.stringify(formdata), true, false);
      if (res.success) {
        fetchData();
        setFormdata(prevState => ({
          ...prevState,
          title: ""
        }));
        toast.success("Watchlist created");
      } else {
        fetchData();
        toast.error("Watchlist not created");
        setFormdata(prevState => ({
          ...prevState,
          title: ""
        }));
      }
    } catch (error) {
      toast.error("Watchlist already exist");
      console.error('Error:', error);
      setFormdata(prevState => ({
        ...prevState,
        title: ""
      }));
    }
  };

  const handleAddWatchlist = async (item) => {
    setSearchValue('');
    setFormdata1((prevFormdata) => ({
      ...prevFormdata,
      stock_symbol: item,
    }));

    const dataObject = {
      ...formdata1,
      stock_symbol: item._id,
    };

    try {
      const res = await FetchData("addwatchlist", 'POST', JSON.stringify(dataObject), true, false);
      if (res.success) {
        toast.success("Watchlist created");
        setSearch(false);
        setSearchValue("");
        listStocks();
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      console.log('error',error.message)
      if(error.message == "Please send mandatory fields"){
        toast.error("Watchlist category is not found");
        setSearch(false);
        setSearchValue("");
        console.error('Error:', error);
      }else{

        toast.error("Watchlist Already Entered");
        setSearch(false);
        setSearchValue("");
        console.error('Error:', error);
      }
      
    }
  };


  const handleEditwatchlist = async (e) =>{
    setEditvalue(e.target.value)
    setEditbutton(false);
  }

  const listStocks = async (item = formdata1.watchlist_cat_id) => {
    try {
      const ObjectValue = { ...formdata1, ["watchlist_cat_id"]: item };
      setStockwatchlist(ObjectValue);
      const res = await FetchData("watchlist", 'POST', JSON.stringify(ObjectValue), true, false);
      if (res.success) {
        setWatchlist(res.data);
      } else {
        console.error("Please Check Credentials");
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const getValue = () => {
    const watchlistStored = JSON.parse(localStorage.getItem("watchlist")) || [];
    setWatchlist(watchlistStored);
  };

  const deleteFromWatchlist = (index) => {
    const watchlistStored = JSON.parse(localStorage.getItem("watchlist")) || [];
    if (index >= 0 && index < watchlistStored.length) {
      watchlistStored.splice(index, 1);
      localStorage.setItem("watchlist", JSON.stringify(watchlistStored));
      getValue();
    } else {
      console.error("Invalid index. No item removed.");
    }
  };

  const fetchData = async () => {
    try {
      const res = await FetchData(`watchlistcat/${profiledetails._id}`, "GET", null, true, false);
  
      if (res && res.data) {
        console.log('res.data', res.data);
        setFormdata1((prevFormdata) => ({
          ...prevFormdata,
          watchlist_cat_id: res.data[0]?._id,
        }));
        setdeleteformdata((prevFormdata) => ({
          ...prevFormdata,
          watchlist_cat_id: res.data[0]?._id,
        }));
        setWatchlistCat(res.data); // Populate watchlist
        listStocks(res.data[0]?._id); // Load stocks based on first category
        setSelectedItem(res.data[0]?.title); // Set the first category's title
        console.log("value of stock", res.data[0]?.title);
      } else {
        throw new Error("No data received from the API");
      }
    } catch (error) {
      // Handle any error (e.g., 400 status or network failure)
      console.error('Error fetching data:', error);
      setFormdata1((prevFormdata) => ({
        ...prevFormdata,
        watchlist_cat_id:[],
      }));
      setWatchlistCat([]); // Empty array for watchlist categories
      listStocks(null);    // No stock ID to list
      setSelectedItem(''); // Clear selected item titl
    }
  };
  
  const toggleDropdown = () => setIsOpen(!isOpen);

  const RemoveWatchlistcat = async (id) => {
    const res = await FetchData(`deletewatchlistcat/${id}`, 'GET', null, true, false)
    if (res) {
      toast.success("Watchlist category deleted Successfully");
      setIsOpen(false)
      fetchData();
    }
  }




  
  const removeAdmin = async (id) => {
    const dataObject = {
      ...deleteformdata,
      stock_symbol: id,
    };
    try {
      const res = await FetchData("deletewatchlist", 'POST', JSON.stringify(dataObject), true, false);
      if (res.success) {
        listStocks();
        // setWatchlist(res.data);

      } else {
        console.error("Please Check Credentials");
      }
    } catch (error) {
      console.error('Error:', error);
    }


  };

  const Imageview = ({ item }) => {
    const [imageError, setImageError] = useState(false);
    const stock = item?.stockDetails?.stock;
    
    const splitStock = (stock) => {
      if (!stock) return '';
      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');
      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';
      return `${firstChar}${secondChar}`.toUpperCase();
    };

    return (
          <div
            style={{
              height: isMobile ? "20px" : "40px",
              width: isMobile ? "20px" : "40px",
              borderRadius: "50%",
              border: theme ? "1.5px solid #7E66BC":"1.5px solid #5b298c",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: '2%',
              backgroundColor: "#f0edf7",
              fontSize: isMobile ? "10px" : "14px",
              color:"black",
              fontWeight: "bold",
            }}
          >
            {splitStock(item?.stockDetails?.stock)}
          </div>
    );
  };

  const Editapi = async (id) => {

    const value = {
      title: editvalue
    }
    try {
      const res = await FetchData(`updatewatchlistcat/${editvalueid}`, 'POST', JSON.stringify(value), true, false);
      if (res.success) {
        fetchData();
        toast.success("Watchlistcategory updated Successfully")
      } else {
        toast.error("Watchlistcategory already exist ")
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error("Watchlistcategory already exist ")
    }


  };


  const itemRefs = useRef([]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setSelectedIndex((prevIndex) =>
        prevIndex < query.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setSelectedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'Enter' && selectedIndex !== -1) {
      handleAddWatchlist(query[selectedIndex]);
    }
  };

  useEffect(() => {
    if (selectedIndex !== -1 && itemRefs.current[selectedIndex]) {
      itemRefs.current[selectedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [selectedIndex]);

  const Handleeditevalue = (item) => {
    setEditvalue(item.title)
    setEditvalueid(item._id)
  }


  const DropdownImage = ({ item }) => {
    const [imageError1, setImageError1] = useState(false);
    const stock = item?.stock;

    const splitStock1 = (stock) => {
      if (!stock) return '';
      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');
      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';
      return `${firstChar}${secondChar}`.toUpperCase();
    };

    return (       
          <div
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              color:"black",
              border:theme?"1.5px solid #7E66BC":"1.5px solid #5b298c",
              display: "flex",
              alignItems: "center",
              marginLeft: '10%',
              justifyContent: "center",
              fontWeight: 400,
              backgroundColor: "#f0edf7",
              fontSize: "16px",
            }}
          >
            {splitStock1(item?.stock)}
          </div>
    );
  };

  return (
    <>
      <Navbar />
      <ToastContainer />
      <div className='container-fluid' style={{ width: "95%", minHeight: "80vh" }}>
        <div className='card' style={{ backgroundColor: theme ? 'rgb(27, 27, 27)' : '#fff' }}>
          <div className='p-3 d-flex justify-content-between'>
            <h4 style={{ color:theme ? '#7E66BC' : '#5b298c'  }}>
              <span style={{ color: theme ? '#fff' : 'black' }}>My</span> watchlist
            </h4>
            <button className='btn text-center' style={{ color: 'white', backgroundColor: theme?'#7E66BC' : '#5b298c' }} data-bs-toggle="modal" data-bs-target="#exampleModal1">+ Create Watchlist</button>
          </div>
          <div>
            <div className='d-flex flex-wrap align-items-center mt-4 ms-2 mb-2'>
              <div className='flex-grow-1'>
                <label className={`ms-3 ${theme ? 'text-white' : 'text-dark'}`}>Add Stocks</label>
                <div className="d-flex">
                  <input
                    className={`form-control ms-2 me-2 ${theme ? "navsearch3" : "navsearch3white"}`}
                    type="search"
                    value={searchValue}
                    onChange={(e) => {
                      setSearch(true);
                      if (e.target.value.length === 0) {
                        setSearch(false);
                      }
                      setSearchValue(e.target.value);
                    }}
                    onKeyDown={(e) => handleKeyDown(e)}
                    placeholder="Search Stocks"
                    aria-label="Search"
                    style={{ border: "0.5px solid #ededed", fontSize: isMobile ? "12px" : "" }}
                  />
                </div>
              </div>
              <div className='w-50'>
                <label className={`ms-2 ${theme ? 'text-white' : 'text-dark'}`}>Selected Watchlist</label>
                <div className="custom-dropdown">
                  <div className={`${theme ? "dropdown-header-dark " : "dropdown-header"} fs-6`} onClick={toggleDropdown}>
                    {selectedItem ? selectedItem : (watchlistcat.length > 0 ? watchlistcat[0].title : "Select an option")}
                    <span className="dropdown-arrow">&#x25BC;</span>
                  </div>
                  {isOpen && (
                    <ul className={`${theme ? "dropdown-list-dark" : "dropdown-list"}`} style={{ cursor: 'pointer' }}>
                      {watchlistcat.map((item) => (
                        <li className={`${theme ? "dropdown-item-dark" : "dropdown-item"}`} key={item._id}  >
                          <span className='' onClick={() => handleInputChangewatchlist(item._id, item.title)} style={{ width: '100%' }}>{item.title}</span>
                          <div className="d-flex dropdown-actions">
                            <FontAwesomeIcon icon={faEdit} data-bs-toggle="modal" data-bs-target="#editModal" onClick={() => { Handleeditevalue(item) }} className='me-3' style={{ color: 'gray', cursor: 'pointer' }} />
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              data-bs-toggle="modal"
                              data-bs-target="#deleteModel"
                              onClick={() => { setdeletecategoryID(item._id); }}
                              style={{ color: 'red', cursor: 'pointer' }}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>

              </div>
              <div className='ms-2'>
                {/* <img src='/images/setting.png' width={20} height={20} alt="Settings" /> */}
              </div>
            </div>
            {search && (
              <div className={`${theme ? '' : 'card'}`} style={{ position: "absolute", top: isMobile ? 115 : 160, left: isMobile ? "2%" : "1%", width: isMobile ? "80%" : '40vw', zIndex: 999 }}>
                <div className="d-flex flex-column w-100" style={{ maxHeight: "300px", overflowY: "auto", fontSize: 14, backgroundColor: theme ? "rgb(51, 51, 51)" : "transparent", border: theme ? "1px solid #424242" : "" }}>
                  {query.length > 0 ? (
                    query.map((item, index) => (
                      <div
                        key={index}
                        ref={(el) => (itemRefs.current[index] = el)}  // Assign each item ref
                        onClick={() => handleAddWatchlist(item)}
                        style={{
                          display: 'flex',
                          // justifyContent: 'space-around',
                          alignItems: 'center',
                          padding: '10px',
                          backgroundColor: selectedIndex === index ? '#8871c0' : theme ? 'rgb(51, 51, 51)' : 'transparent', // Highlight selected row in red
                          color: selectedIndex === index ? 'black' : theme ? "white" : 'black', // Change text color for better contrast
                          cursor: 'pointer', // Add pointer cursor on hover
                          borderBottom: theme ? '1px solid #4B4B4B' : '1px solid #ddd',
                        }}
                      >
                        <DropdownImage item={item} />
                        <div className="fw-bolder w-25 ms-4" style={{ color: selectedIndex === index ? 'white' : 'gray' }}>{item.stock_code}</div>
                        <div className="w-50" style={{ color: selectedIndex === index ? 'white' : 'gray' }}>{item.stock}</div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">
                      <p className={`fs-6 fw-bold ${theme ? "text-white" : ''}`}>No Stocks found</p>
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className='d-flex justify-content-around flex-wrap text-center row p-3'>
              <div style={{ overflowX: 'auto' }}>
                <table
                  className="table mt-2"
                  style={{
                    textAlign: "start",
                    backgroundColor: theme ? "#1B1B1B" : '#fff',
                    borderRadius: "10px",
                    border: theme ? '1.5px solid #222222' : "1.5px solid rgba(0, 0, 0, 0.175)",
                    minWidth: '800px' 
                  }}
                >
                  <thead>
                    <tr style={{ backgroundColor: '#333333' }}>
                      <th className={`${theme ? 'top-header-table-black' : 'top-header-table-white'}`} scope="col">
                        <span className='ms-3'>Name</span>
                      </th>
                      <th className={`${theme ? 'top-header-table-black' : 'top-header-table-white'} text-start`} scope="col">Sector</th>
                      <th className={`${theme ? 'top-header-table-black' : 'top-header-table-white'}`} scope="col">Industry</th>
                      <th className={`${theme ? 'top-header-table-black' : 'top-header-table-white'} text-start`} scope="col">Compliance</th>
                      <th className={`${theme ? 'top-header-table-black' : 'top-header-table-white'}`} scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(watchlist) && watchlist.length > 0 ? (
                      watchlist.map((item, index) => (
                        <tr key={index}>
                          <th scope="row" className={`${theme ? 'theme-black-table' : 'theme-white-table'}`}>
                            <div className="d-flex justify-content-start pointer" onClick={() => navigate("/stock", { state: { data: item?.stockDetails?.isin } })}>
                              <Imageview item={item} />
                              <div className="ms-2">
                                <h3 className="mb-0" style={{ padding: "0px 10px", fontSize: isMobile ? "10px" : "16px" }}>{item?.stockDetails?.stock_code}</h3>
                                <p className="mb-0 ms-2" style={{ fontSize: isMobile ? "8px" : '12px' }}>{item?.stockDetails?.stock}</p>
                              </div>
                            </div>
                          </th>
                          <td className={`${theme ? 'theme-black-table' : 'theme-white-table'}`}>{item?.technicalsdata?.sector}</td>
                          <td className={`${theme ? 'theme-black-table' : 'theme-white-table'}`}>{item?.technicalsdata?.industry}</td>
                          {item?.screenerData?.status == 1 ? (
                            <td className={`${theme ? 'theme-black-table' : 'theme-white-table'} `}>
                              <div className="d-flex p-1 justify-content-start" >
                                <span className="heder-title" style={{ color: '#218303', fontWeight: "500" }}>Compliant</span>
                                <span className="ms-1"><img src="/images/checklist.png" width={20} height={20} ></img></span>
                              </div>
                            </td>
                          ) : item?.screenerData?.status == 2 || item?.screenerData?.status == 4 || item?.screenerData?.status == 6 || item?.screenerData?.status == 7 ? (
                            <td className={`${theme ? 'theme-black-table' : 'theme-white-table'}`}>
                              <div className="d-flex p-1 justify-content-start">
                                <span className="heder-title" style={{ color: '#D43A3A', fontWeight: "500" }}>Non Compliant</span>
                                <span className="ms-1"><img src="/images/non-permisable.png" width={20} height={20} ></img></span>
                              </div>
                            </td>
                          ) : item?.screenerData?.status == 3 ? (
                            <td className={`${theme ? 'theme-black-table' : 'theme-white-table'}`}>
                              <div className="d-flex p-1 justify-content-start" >
                                <span className="heder-title" style={{ color: '#2132CA', fontWeight: "500" }}>Unknown</span>
                                <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                              </div>
                            </td>
                          ) :
                            (<td className={`${theme ? 'theme-black-table' : 'theme-white-table'}`}>
                              <div className="d-flex p-1 justify-content-start">
                                <span className="heder-title" style={{ color: '#EFA625', fontWeight: "500" }}>Under Review</span>
                                <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                              </div>
                            </td>
                            )
                          }
                          <td className={`${theme ? 'theme-black-table' : 'theme-white-table'} text-center`}>
                            <span data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => { setDeleteIndex(item?.stockDetails?._id) }} style={{ color: 'red', cursor: 'pointer' }}>
                              <img src="/images/icons/delete.png" width={20} height={20} style={{ filter: 'invert(15%) sepia(97%) saturate(7452%) hue-rotate(-15deg) brightness(83%) contrast(113%)' }} />
                            </span>
                            {/* <FontAwesomeIcon icon={faTrashCan} /> */}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className={`${theme ? 'theme-black-table' : 'theme-white-table'} py-4 text-center text-gray-600 `} colSpan="7">
                          <img src="/images/icons/nostock.png" width={300} height={250} />
                          <p style={{ fontSize: "16px" }}> No items in the watchlist.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Modal for Deleting Watchlist */}
              <DeleteModal deleteIndex={deleteIndex} removeAdmin={removeAdmin} theme={theme} />
              {/* Modal for Creating Watchlist */}
            <CreateWatchlistModal handleInputChange={handleInputChange} handleSendReport={handleSendReport} formdata={formdata}  buttonclick={buttonclick} theme={theme}/>
            </div>

          </div>

          <div className="modal fade" id="deleteModel" tabIndex="-1" aria-labelledby="deleteModelLabel" aria-hidden="true" data-bs-backdrop="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header text-white" style={{ backgroundColor:theme ? '#7E66BC' : '#5b298c' , borderBottom: "none", borderRadius: "0.3rem 0.3rem 0 0" }}>
                  <h5 className="modal-title" id="deleteModelLabel">Confirmation</h5>
                  <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body text-center" style={{ padding: "2rem", backgroundColor: "#F9F9F9" }}>
                  <p style={{ fontSize: "1.1rem", marginBottom: "1rem", color: "#333" }}>Are you sure you want to delete this watchlist category?</p>
                </div>
                <div className="modal-footer" style={{ borderTop: "none" }}>
                  <div className="d-flex justify-content-end gap-2 w-100">
                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" style={{ borderRadius: "0.3rem" }}>Cancel</button>
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => { RemoveWatchlistcat(deletecategoryID) }} style={{ backgroundColor:theme ? '#7E66BC' : '#5b298c' , border: "none", color: "#fff", borderRadius: "0.3rem", transition: "background 0.3s" }}>Delete</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="editModal" tabIndex="-1" aria-labelledby="editModalLabel" aria-hidden="true" data-bs-backdrop="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header text-white" style={{ backgroundColor:theme ? '#7E66BC' : '#5b298c' , borderBottom: "none", borderRadius: "0.3rem 0.3rem 0 0" }}>
                  <h5 className="modal-title" id="editModalLabel">Edit Watchlist</h5>
                  <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body text-center" style={{ padding: "2rem", backgroundColor: "#F9F9F9" }}>
                  <input
                    type="text"
                    className="form-control"
                    value={editvalue}
                    onChange={(e) => { handleEditwatchlist(e) }}
                    placeholder="Enter new category title"
                  />
                </div>
                <div className="modal-footer" style={{ borderTop: "none" }}>
                  <div className="d-flex justify-content-end gap-2 w-100">
                    <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" style={{ borderRadius: "0.3rem" }}>Cancel</button>
                    <button type="button" className="btn btn-success" disabled={editbutton}  data-bs-dismiss="modal" onClick={() => { Editapi() }} style={{ backgroundColor:theme ? '#7E66BC' : '#5b298c' , border: "none", color: "#fff", borderRadius: "0.3rem", transition: "background 0.3s" }}>Save Changes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </>
  );
};


// Delete Confirmation Modal Component
const DeleteModal = ({ deleteIndex, removeAdmin, theme }) => (
  <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header text-white" style={{ backgroundColor:theme ? '#7E66BC' : '#5b298c' , borderBottom: "none", borderRadius: "0.3rem 0.3rem 0 0" }}>
          <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
          <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body text-center" style={{ padding: "2rem", backgroundColor: "#F9F9F9" }}>
          <p style={{ fontSize: "1.1rem", marginBottom: "1rem", color: "#333" }}>Are you sure you want to delete this item?</p>
        </div>
        <div className="modal-footer" style={{ borderTop: "none" }}>
          <div className="d-flex justify-content-end gap-2 w-100">
            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" style={{ borderRadius: "0.3rem" }}>Cancel</button>
            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => { removeAdmin(deleteIndex) }} style={{ backgroundColor:theme ? '#7E66BC' : '#5b298c', border: "none", color: "#fff", borderRadius: "0.3rem", transition: "background 0.3s" }}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);




// Create Watchlist Modal Component
const CreateWatchlistModal = ({ handleInputChange, handleSendReport, formdata , buttonclick ,theme}) => (
  <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header text-white" style={{ backgroundColor: theme ? '#7E66BC' : '#5b298c' , borderBottom: "none", borderRadius: "0.3rem 0.3rem 0 0" }}>
          <h5 className="modal-title" id="exampleModalLabel">Create Watchlist</h5>
          <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body text-center" style={{ padding: "2rem", backgroundColor: "#F9F9F9" }}>
          <div className="mb-3 text-start">
            <label htmlFor="watchlistName" className="form-label" style={{ fontSize: "1.1rem", color: "#333" }}>Watchlist  Name</label>
            <input type="text" className="form-control" name='title' id="watchlistName" value={formdata.title} onChange={handleInputChange} placeholder="Enter watchlist name" style={{ borderRadius: "0.3rem", borderColor: "#ccc", fontSize: "1rem", padding: "0.5rem" }} />
          </div>
        </div>
        <div className="modal-footer" style={{ borderTop: "none" }}>
          <div className="d-flex justify-content-end gap-2 w-100">
            <button type="button" className="btn btn-outline-secondary w-25" data-bs-dismiss="modal" style={{ borderRadius: "0.3rem" }}>Cancel</button>
            <button type="button" data-bs-dismiss="modal" disabled={buttonclick} className="btn btn-primary w-25" onClick={handleSendReport} style={{ backgroundColor:theme ? '#7E66BC' : '#5b298c' , border: "none", color: "#fff", borderRadius: "0.3rem", transition: "background 0.3s" }}>Create</button>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Watchpage;
