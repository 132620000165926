import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStar as faStarEmpty } from '@fortawesome/free-solid-svg-icons';

const StarRating = ({ value, theme }) => {

    const sanitizedValue = value !== undefined ? value : 0;
    const fullStars = Math.floor(sanitizedValue); 
    const decimalPart = sanitizedValue % 1;
    const emptyStars = 5 - fullStars - (decimalPart > 0 ? 1 : 0); 

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {/* Full Stars */}
            {Array(fullStars).fill().map((_, index) => (
                <FontAwesomeIcon
                    key={index}
                    icon={faStar}
                    style={{ color: theme?'#FFD700':"green", fontSize: '25px' }}
                />
            ))}

            {/* Partial Star */}
            {decimalPart > 0 && (
                <span style={{ position: 'relative', width: '25px', display: 'inline-block' }}>
                    <FontAwesomeIcon
                        icon={faStar}
                        style={{
                            color:theme?'#FFD700':"green",
                            fontSize: '25px',
                            position: 'absolute',
                            clipPath: `inset(0 ${100 - decimalPart * 100}% 0 0)`,
                        }}
                    />
                    <FontAwesomeIcon
                        icon={faStarEmpty}
                        style={{
                            color: '#D3D3D3',
                            fontSize: '25px',
                        }}
                    />
                </span>
            )}

            {/* Empty Stars */}
            {Array(emptyStars).fill().map((_, index) => (
                <FontAwesomeIcon
                    key={index + fullStars + (decimalPart > 0 ? 1 : 0)} // Adjust key for uniqueness
                    icon={faStarEmpty}
                    style={{ color: '#D3D3D3', fontSize: '25px' }}
                />
            ))}
        </div>
    );
};

export default StarRating;
