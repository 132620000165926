import React, { useEffect } from 'react';

const Blogs = ({ themes }) => {
  useEffect(() => {
    const container = document.getElementById('tradingview-widget-container');

    if (!container) return; // Early return if container not found

    // Clear any existing scripts to avoid duplication
    container.innerHTML = ''; // Clear the container before appending a new script

    // Create a script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js';
    script.async = true;

    // Set script content with widget settings
    script.innerHTML = JSON.stringify({
      colorTheme: themes ? 'dark' : 'light',
      dateRange: '12M',
      showChart: false,
      locale: 'en',
      width: '100%',
      height: '100%',
      largeChartUrl: '',
      isTransparent: true,
      showSymbolLogo: true,
      showFloatingTooltip: false,
      tabs: [
        {
          title: 'Indices',
          symbols: [
            { s: 'BSE:SENSEX', d: 'SENSEX' },
            { s: 'BSE:LRGCAP', d: 'LARGE CAP' },
            { s: 'BSE:MIDCAP', d: 'MID CAP' },
            { s: 'BSE:SMLCAP', d: 'SMALL CAP' },
            { s: 'BSE:BSE500', d: 'BSE 500' },
            { s: 'BSE:BSE200', d: 'BSE 200' },
            { s: 'BSE:BSE100', d: 'BSE 100' },
            { s: 'BSE:MSL400', d: 'MIDCAP SMALL CAP 400' },
            
          ],
          originalTitle: 'Indices',
        },
        {
          title: 'Sector',
          symbols: [
            { s: 'BSE:AUTO', d: 'AUTO' },
            { s: 'BSE:BANK', d: 'BANK' },
            { s: 'BSE:ENERGY', d: 'ENERGY' },
            { s: 'BSE:HC', d: 'PHARMA' },
            { s: 'BSE:FMCG', d: 'FMCG' },
            { s: 'BSE:INDSTR', d: 'INDUSTRIALS ' },
            { s: 'BSE:OILGAS', d: 'OIL & GAS' },
            { s: 'BSE:METAL', d: 'METAL' },
          ],
        },
      ],
    });

    // Append the script to the container
    container.appendChild(script);

    // Cleanup function to remove the script on component unmount
    return () => {
      if (container && container.contains(script)) {
        container.removeChild(script);
      }
    };
  }, [themes]); // Add `themes` to the dependency array

  return (
    <div className="tradingview-widget-container" id="tradingview-widget-container">
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          {/* <span className="blue-text">Track all markets on TradingView</span> */}
        </a>
      </div>
    </div>
  );
};

export default Blogs;
