import React,{useState , useContext, useEffect} from  'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../footer/Footer'
import "./setting.css"
import { UserContext } from '../../Utills/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan,faEdit } from '@fortawesome/free-solid-svg-icons';
import FetchData from "../../fetchapi/Apifetch";
import config from '../../Utills/ApiRoute';

const Newsettingpage = () => {
    const profiledetails = JSON.parse(localStorage.getItem("email"));
    const [pages, setpages] = useState("Setting");
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);


    const { theme, setTheme } = useContext(UserContext);
    const Handlethemeset = (e) =>{
        setTheme(!theme)
      }

      const { user, setUser } = useContext(UserContext);
      const handlelogout = () => {
        window.location.href = "/";
        setUser(null);
        localStorage.clear();
      };
      const [canUpdateImage, setCanUpdateImage] = useState(true);


      const [profiledetails1, setProfileDetails] = useState({
        first_name:profiledetails?.first_name,
        last_name:profiledetails?.last_name,
        email:profiledetails?.email,
        phone:profiledetails?.phone,
        address:profiledetails?.address,
        img:profiledetails?.img
      });
      const [profilePhoto, setProfilePhoto] = useState(null);

      const [profilePhotoremove, setProfilePhotoremove] = useState(null);
      const handleFileChange = (e) => {
        setProfilePhoto(e.target.files[0]);
        setCanUpdateImage(false);
        setProfilePhotoremove(e.target.files[0])
      };
    
      // Determine the image source to display

    



      const DropdownImage2 = ({ item , width , height ,font ,value}) => {
        const [imageError2, setImageError2] = useState(false);
        const stock1 = `${item?.first_name || ''} ${item?.last_name || ''}`;
       console.log('stock1',stock1);
        const splitStock1 = (stock1) => {
          if (!stock1) return '';
    
          const lettersOnlyRegex = /^[A-Za-z]/;
          const words = stock1.split(' ');
    
          const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
          const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';
    
          return `${firstChar}${secondChar}`.toUpperCase(); // Convert to uppercase if needed
        };
    
        // Determine if we should show the fallback
        const shouldShowFallback = imageError2 || !item?.img;
    
    
    
        return (
          <div style={{cursor:"pointer"}}>
            {/* {!shouldShowFallback ? (
              <img
                className=""
                src={item.img}
                alt="Stock Image"
                style={{
                  height:height ,
                  width:width ,
                  borderRadius: '50%',
                  marginLeft: '10%',
                  border: '0.5px solid #fff',
                  objectFit: 'cover', // Ensures the image covers the element without distortion
                }}
                onError={() => setImageError2(true)} // Set imageError to true on load error
               
              />
            ) : ( */}
            <div
              style={{
                height: height,
                width:width,
                borderRadius: '50%',
                border:'0.5px solid #fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: '10%',
                fontWeight: 500,
                backgroundColor:'#e6e6e6b0' ,
                fontSize: font,
                color: '#333',
              }}
              
            >
              {splitStock1(stock1)}
            </div>
            {/* )} */}
          </div>
        );
      };


      const updateprofile = async () => {
    
        try {
          const res = await FetchData(`updateuser/${profiledetails?._id}`, 'POST', JSON.stringify(profiledetails1), true,false );
          if (res.success) {
            const jsonLoginData = JSON.stringify(res.data);
            localStorage.setItem("email",jsonLoginData);
            window.location.reload();
          } else {
            console.error("Please Check Crediential")
          }
          return null;
        } catch (error) {
          console.error('Error:', error);
          return null;
        }
      }





  return (
    <div>
           <Navbar  />
    <div className='d-flex justify-content-center padding-header' style={{minHeight:'100vh'}}>
    <div className={`card d-flex ${theme ?'text-white':'text-black '}`} style={{width:'95%',borderTopRightRadius:"10px",borderTopLeftRadius:'10px',backgroundColor:theme?'#1B1B1B':'rgb(242 242 242)'}}>
        
<div style={{marginTop:'4%'}}>
   
<div className='row align-center'>

<div  className='col-lg-4 col-sm-12 d-flex justify-content-center text-white '>
<table class="table  ms-3" style={{border:theme?'1px solid #484848':'1px solid #D1D1D1',borderRadius:'10px',width:'98%'}}>
  <tbody>
    <tr>
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <div className='d-flex ms-4'>
<div>
<DropdownImage2
      item={profiledetails}
      width={"50px"}
      height={"50px"}
      font={"16px"}
      value={true}
    />
  
  {/* <img src={`${profiledetails.img ? profiledetails.img :'/images/user.png'  }`} className='' width={50} height={50}  alt="profile" />
   */}
  </div>
<div className='ms-3 mt-2'>
    <h6 className={` fw-bold ${theme ?'text-white':'text-black'}`} style={{fontSize:'18px'}}>{profiledetails.first_name} <span className='ms-2'>{profiledetails.last_name}</span></h6>
    {/* <p className='mt-0'><span className={`${theme ?'text-white':'text-black'}`} style={{fontSize:'14px'}}>Qcodesinfotech.com</span></p> */}
</div>
</div>
      </td>
    </tr>
    <tr >
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <p className={`ms-4 ${theme ?'text-white':'text-black'}`}><span>  <i className="fa fa-unlock-alt me-4  " style={{fontSize:'22px',width:"20px"}} /></span>Reset Password</p>

      </td>
    </tr>
    <tr >
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}
      >                <p className={`ms-4 ${theme ?'text-white':'text-black'}`} ><span>  <i className="fa fa-star me-4   text-warning" style={{fontSize:'22px',width:"20px"}} /></span>Premium Membership</p>
      </td>
    </tr>
    <tr>
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <p className={`ms-4 ${theme ?'text-white':'text-black'}`}><span>  <i className="fa fa-file-text me-4 " style={{fontSize:'22px',width:"20px"}} /></span>Refer a friend</p>

      </td>
    </tr>
    <tr >
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}> 
         <p  className={`ms-4 ${theme ?'text-white':'text-black'}`}> <span>  <i className={`fa fa-question-circle me-4  ${theme?'text-white':'text-black'}`} style={{fontSize:'22px',width:"20px"}} /></span>Help Center</p>
      </td>
    </tr>
    <tr >
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}} >
      <p className={`ms-4 ${theme ?'text-white':'text-black'}`}> <span>  <i className={`fa fa-bell me-4 ${theme ?'text-white':'text-black'}`} style={{fontSize:'22px',width:"20px"}} /></span>Notification</p>
      </td>
    </tr>
    
    
    <tr>
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <div class="dongle-container  ms-4">
    <span class={`dongle-label ${theme ?'text-white':'text-black'}`}>  <span>
    <i className={`fa fa-adjust  ${theme?"text-white":'text-black'}  me-4`} style={{fontSize:'22px',width:"20px"}}></i>
  </span>Theme</span>
    <div class="dongle-toggle">
        <input type="checkbox" id="themeToggle" value={theme}  onChange={(e)=>{Handlethemeset(e)}} />
        <label for="themeToggle" class="dongle-slider"></label>
    </div>
</div>
      </td>
    </tr>
   
    <tr >
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff',cursor:'pointer'}}>  
      <p className='text-danger ms-4 mt-2 ' onClick={()=>{handlelogout()}} > <span>  <i className="fa fa-power-off me-4 text-danger " style={{fontSize:'22px',width:"20px"}}/></span>logout</p>
      </td>
    </tr>
    
  </tbody>
</table>
</div>

<div  className='col-lg-8 col-sm-12 col-md-12 d-flex justify-content-center text-white'>
<table class="table  me-3" style={{border:theme?'1px solid #484848':'1px solid #D1D1D1',borderRadius:'10px',width:'98%'}}>
<thead>
  <tr>
    <td colSpan="2" style={{ backgroundColor: theme ? '#1B1B1B' : '#fff', height: '80px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <p className={`ms-2 ${theme ? 'text-white' : 'text-black'}`} style={{ margin: 0, fontSize: '20px' }}>
        Profile Details <FontAwesomeIcon
        icon={faEdit}
        data-bs-toggle="modal"
        data-bs-target="#editProfileModal"
        className="me-3 ms-1"
        style={{ color: 'gray', cursor: 'pointer', fontSize: '20px' ,float:'right'}}
      />

      </p>
    
    </td>
    <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
   
    </td>
  </tr>
</thead>

  <tbody>

    <tr >
      <td  style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <p className={`ms-2 ${theme ?'text-white':'text-black'}`}>First Name	</p>

      </td>
      <td  style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <p className={`ms-2 ${theme ?'text-white':'text-black'}`}>{profiledetails.first_name}</p>

      </td>
    </tr>
    <tr >
      <td  style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <p className={`ms-2 ${theme ?'text-white':'text-black'}`}>Last Name	</p>

      </td>
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <p className={`ms-2 ${theme ?'text-white':'text-black'}`}>{profiledetails.last_name}</p>

      </td>
    </tr>
    <tr >
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}
      >                <p className={`ms-2 ${theme ?'text-white':'text-black'}`} >Email Address</p>
      </td>
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <p className={` ms-2 ${theme ?'text-white':'text-black'}`}>{profiledetails.email}</p>

      </td>
    </tr>
    <tr>
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <p className={`ms-2 ${theme ?'text-white':'text-black'}`}>Phone Number</p>

      </td>
      <td  style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <p className={`ms-2 ${theme ?'text-white':'text-black'}`}>{profiledetails.phone}</p>
      </td>
    </tr>
    <tr >
      <td style={{backgroundColor:theme?'#1B1B1B':'#fff'}} >
      <p className={`ms-2 ${theme ?'text-white':'text-black'}`}>Address</p>
      </td>
      <td  style={{backgroundColor:theme?'#1B1B1B':'#fff'}}>
      <p className={`ms-2 ${theme ?'text-white':'text-black'}`}>{profiledetails.address}</p>
      </td>
    </tr>
  </tbody>
</table>
</div>
<div className="modal fade" id="editProfileModal" tabIndex="-1" aria-labelledby="editProfileModalLabel" aria-hidden="true" data-bs-backdrop="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content" style={{
      backgroundColor: theme ? '#1B1B1B' : '#ffffff',
      borderRadius: '0.5rem'
    }}>
      <div className="modal-header" style={{
        background: theme ? "linear-gradient(90deg, rgba(126,102,188,1) 0%, rgba(154,123,187,1) 100%)" : '#f0f0f0',
        borderBottom: "none",
        borderRadius: "0.5rem 0.5rem 0 0",
        color: theme ? '#ffffff' : '#000000'
      }}>
        <h5 className="modal-title" id="editProfileModalLabel">Update Profile</h5>
      </div>
      <div className="modal-body" style={{
        padding: "2rem",
        backgroundColor: theme ? '#1B1B1B' : '#F9F9F9'
      }}>
        <form id="profileForm">
          {/* Profile Photo Upload */}
          <div className="mb-3 text-center">
            <div style={{
              position: 'relative',
              cursor: canUpdateImage ? 'pointer' : 'not-allowed',
              display: 'inline-block'
            }}>
           
           <DropdownImage2
      item={profiledetails}
      width={"80px"}
      height={"80px"}
      font={"30px"}
      value={true}
    />
            </div>
          </div>
          
          {/* Other Form Fields */}
          <div className="mb-3">
  <label htmlFor="firstName" className={`form-label ${theme ? 'text-white' : 'text-black'}`}>First Name</label>
  <input
    type="text"
    className={`form-control ${theme ? 'bg-dark text-white' : 'bg-light text-dark'}`}
    id="firstName"
    value={profiledetails1.first_name}
    onChange={(e) => {
      const value = e.target.value;
      // Validate: only letters and length between 3 and 20
      if (/^[A-Za-z]*$/.test(value) && value.length <= 20) {
        setProfileDetails({ ...profiledetails1, first_name: value });
      }
    }}
  />
</div>
<div className="mb-3">
  <label htmlFor="lastName" className={`form-label ${theme ? 'text-white' : 'text-black'}`}>Last Name</label>
  <input
    type="text"
    className={`form-control ${theme ? 'bg-dark text-white' : 'bg-light text-dark'}`}
    id="lastName"
    value={profiledetails1.last_name}
    onChange={(e) => {
      const value = e.target.value;
      // Validate: only letters and length between 3 and 20
      if (/^[A-Za-z]*$/.test(value) && value.length <= 20) {
        setProfileDetails({ ...profiledetails1, last_name: value });
      }
    }}
  />
</div>

          <div className="mb-3">
            <label htmlFor="email" className={`form-label ${theme ? 'text-white' : 'text-black'}`}>Email Address</label>
            <p style={{color:'gray'}}>{profiledetails1.email}</p>
            {/* <input
              type="email"
              className={`form-control ${theme ? 'bg-dark text-white' : 'bg-light text-dark'}`}
              id="email"
              value={profiledetails1.email}
              onChange={(e) => setProfileDetails({ ...profiledetails1, email: e.target.value })}
            /> */}
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className={`form-label ${theme ? 'text-white' : 'text-black'}`}>Phone Number</label>
            <input
              type="tel"
              className={`form-control ${theme ? 'bg-dark text-white' : 'bg-light text-dark'}`}
              id="phone"
              value={profiledetails1.phone}
              onChange={(e) => setProfileDetails({ ...profiledetails1, phone: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="address" className={`form-label ${theme ? 'text-white' : 'text-black'}`}>Address</label>
            <input
              type="text"
              className={`form-control ${theme ? 'bg-dark text-white' : 'bg-light text-dark'}`}
              id="address"
              value={profiledetails1.address}
              onChange={(e) => setProfileDetails({ ...profiledetails1, address: e.target.value })}
            />
          </div>
          
        </form>
      </div>
      <div className="modal-footer" style={{
        borderTop: "none",
        backgroundColor: theme ? '#1B1B1B' : '#ffffff'
      }}>
        <div className="d-flex justify-content-end gap-2 w-100">
          <button type="button" className={`btn ${theme ? 'btn-outline-secondary text-white' : 'btn-outline-dark'}`} data-bs-dismiss="modal" style={{ borderRadius: "0.3rem" }}>Cancel</button>
          <button type="button" className='btn' onClick={() => { updateprofile() }} style={{ borderRadius: "0.3rem",backgroundColor:"rgb(126, 102, 188)",color:"#fff" }}>Update</button>
        </div>
      </div>
    </div>
  </div>
</div>




   

</div>

</div>

    </div>

</div>
<Footer />
    </div>
  )
}


export default Newsettingpage