import React, { useEffect, useRef } from 'react';

const Qvtstocks = ({ symbol }) => {
  const widgetContainerRef = useRef(null);

  useEffect(() => {
    // Create a blockquote element for the widget
    const blockquote = document.createElement('blockquote');
    blockquote.className = 'trendlyne-widgets';
    blockquote.dataset.getUrl = `https://trendlyne.com/web-widget/qvt-widget/Poppins/${symbol}/?posCol=00A25B&primaryCol=006AFF&negCol=EB3B00&neuCol=F7941E&theme=light`;

    // Inline style for transparency
    blockquote.style.backgroundColor = 'transparent';

    const script = document.createElement('script');
    script.src = 'https://cdn-static.trendlyne.com/static/js/webwidgets/tl-widgets.js';
    script.async = true;
    script.charset = 'utf-8';

    if (widgetContainerRef.current) {
      // Clear any previous content before adding new elements
      widgetContainerRef.current.innerHTML = '';

      // Append blockquote and script to container
      widgetContainerRef.current.appendChild(blockquote);
      widgetContainerRef.current.appendChild(script);
    }

    return () => {
      // Cleanup by clearing inner HTML
      if (widgetContainerRef.current) {
        widgetContainerRef.current.innerHTML = '';
      }
    };
  }, [symbol]);

  return (
    <div
      ref={widgetContainerRef}
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: 'transparent',
      }}
    ></div>
  );
};

export default Qvtstocks;
