import React, { useEffect, useState, useContext } from 'react';
import FetchData from '../../fetchapi/Apifetch';
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../Utills/UserContext';
import Skeleton from 'react-loading-skeleton'; // Import Skeleton
import 'react-loading-skeleton/dist/skeleton.css'; // Import Skeleton CSS
import "./screenerstock.css"
import { ToastContainer, toast } from 'react-toastify';
const Screener = () => {
  const navigate = useNavigate();
  const profiledetails = JSON.parse(localStorage.getItem("email"));
  const { theme, setTheme } = useContext(UserContext);
  const [activeButton, setActiveButton] = useState(0);
  const [Stockscreener, setStockscreener] = useState('');
  const [StockID, setStockID] = useState('');
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const [filterdata, setFilterdata] = useState(false); // Add loading state
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isTab, setisTab] = useState(window.innerWidth <= 900);
  const [industry, setindustry] = useState('');
  const [overallindustry, setoverallindustry] = useState('');
  const [sectorvalue, setsectorvalue] = useState('');
  const [apistockId, setApistockId] = useState('');
  const [categorymodel, setCategorymodel] = useState(false);
  const [createcategorymodel, setcreatecategorymodel] = useState(false);
  const [watchlistcategory, setwatchlistcategory] = useState('');
  const [buttonclick, setbuttonclick] = useState(true);
  const [Applybuttonclick, setApplybuttonclick] = useState(true);
  const [buttonclickcreate, setbuttonclickcreate] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [watchlist, setwatchlist] = useState(false);
  const [data, setData] = useState();
  const [formdata4, setFormdata4] = useState({
    user_id: profiledetails._id,
    title: "",
    status: 1
  });
  const Compliant = [
    { id: '1', name: 'Compliant' },
    { id: '2', name: 'Non Compliant' },
    { id: '3', name: 'Unknown' },
    { id: '4', name: 'Under Review' },

  ];

  const getSelectedExchangeName = (selectedexchange) => {

    const selected = Compliant.find(item => item.id == selectedexchange); // Find the matching item by id
    return selected ? selected.name : ""; // Return the name or an empty string if not found
  };

  const [selectedIndustries, setSelectedIndustries] = useState([]);

  const [selectedsector, setSelectedsector] = useState([]);
  const [selectedexchange, setSelectedexchange] = useState([]);
  const [selectedstatus, setSelectedstatus] = useState([]);

  const handleCheckboxChange = (id) => {
    if (selectedIndustries.includes(id)) {
      setSelectedIndustries(selectedIndustries.filter((id) => id !== id));
    } else {
      setSelectedIndustries([id]);
    }

  };

  const handleCheckboxstatus = (id) => {
    setSelectedstatus((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((industryId) => industryId !== id)
        : [...prevSelected, id]
    );
  };



  const handleCheckboxSector = (id) => {
    setSelectedsector((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((industryId) => industryId !== id)
        : [...prevSelected, id]
    );
  };


  const handleCheckboxexChange = (id) => {
    setSelectedexchange((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((industryId) => industryId !== id)
        : [...prevSelected, id]
    );
  };
  const handleRowClick = (item) => {
    navigate('/stock', { state: { data: item?.screenerData?.isin } });
  };

  const NextPage = () => {
    setPage(page + 1);
  };
  const PreviousPage = () => {
    setPage(page - 1);
  };
  const NextPageall = () => {
    setPage(paginationInfo?.totalPages);  // Go to the last page
  };

  const PreviousPageall = () => {
    setPage(1);  // Go to the first page
  };

  useEffect(() => {
    if (!filterdata) {
      Stockscreenerlist();
    }
    else {
      Filterdata();
    }
  }, [page]);


  const GetSTockWhatchList = (stockID)=>{
    return apistockId.includes(stockID)
  }

  // useEffect(() => 
  //   Filterdata();
  // }, [page]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);  // Adjust the width threshold as needed
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call the handler on mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    Watchlistcheck();
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    console.log('selectedIndustries', selectedIndustries)
  }, [selectedIndustries]);
  useEffect(() => {
    const handleSelectedSector = async () => {
      if (selectedsector.length == 0) {
        setSelectedIndustries('');
        await setindustry(overallindustry); // Assuming setindustry is a state setter
      }
    };

    handleSelectedSector();
  }, [selectedsector]);
  useEffect(() => {
    console.log('selectedexchange', selectedexchange)
  }, [selectedexchange]);



  const Stockscreenerlist = async () => {
    
    window.scrollTo(0, 0);
    setLoading(true); // Set loading to true before fetching
    const res = await FetchData(`stocklist?page=${page}&limit=10`, 'GET', null, true, false);
    if (res) {
      setStockscreener(res.data.data);
      setPaginationInfo(res.data.pagination);
    }
    setLoading(false); // Set loading to false after fetching
  };


  const Filterdata = async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    const dataObject = {
      limit: 12 || 10,    // Default limit is 10
      page: page || 1,       // Default page is 1
      filters: {
        sectors: selectedsector || [],  // Send sectors array
        industries: selectedIndustries || [],  // Send industries array
        status: selectedexchange || []
      }
    }
    try {
      const response = await FetchData(`screener_filter?page=${page}&limit=12`, 'POST', JSON.stringify(dataObject), true, false);
      // Response data contains the stock data and pagination
      // console.log(response.data);
      setFilterdata(true);
      setStockscreener(response.data);
      setPaginationInfo(response.pagination);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching stock data:', error);
    }
  };
  const Imageview = ({ item }) => {
    const [imageError, setImageError] = useState(false);
    const stock = item?.screenerData?.stock;

    const splitStock = (stock) => {
      if (!stock) return '';
      const lettersOnlyRegex = /^[A-Za-z]/;
      const words = stock.split(' ');

      const firstChar = words[0]?.charAt(0).match(lettersOnlyRegex) ? words[0].charAt(0) : '';
      const secondChar = words[1]?.charAt(0).match(lettersOnlyRegex) ? words[1].charAt(0) : '';

      return `${firstChar}${secondChar}`.toUpperCase();
    };

    return (
      <div>
        {/* {!imageError ? (
          <img
            className=""
            src={item?.stockData?.img || ""}
            alt="img"
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
              marginTop: '-5px'
            }}
            onError={() => setImageError(true)}
          />
        ) : ( */}
        <div
          style={{
            height: "40px",
            width: "40px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: theme ? "1.5px solid #7E66BC" : "1.5px solid #5b298c",
            backgroundColor: "#f0edf7",
            color: 'black',
            fontSize: "16px",
            fontWeight: 500,
            marginTop: '-5px'
          }}
        >
          {splitStock(item?.screenerData?.stock)}
        </div>
        {/* )} */}
      </div>
    );
  };

  const openCreateCategoryModal = () => {
    setCategorymodel(false);
    setcreatecategorymodel(true);
  };

  const styles = {
    pageLink: {
      borderRadius: "50%",
      width: "35px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      border: theme ? "2px solid #ddd" : '1px solid #949393',
      color: theme ? "#fff" : 'black',
      backgroundColor: "transparent",
      marginRight: "5px",
      cursor: "pointer",
    },
    activePage: {
      borderRadius: "50%",
      width: "35px",
      height: "35px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#7E66BC", // Active background color
      color: "#fff", // Active text color
      border: "none",
      marginRight: "5px",
    },
  };


  const PaginationIndex = () => {
    const totalPageNumbersToShow = 5; // Number of pagination numbers to show

    const getPaginationNumbers = () => {
      const totalPages = paginationInfo?.totalPages || 1;
      const paginationNumbers = [];

      if (totalPages <= totalPageNumbersToShow) {
        // Case 1: Show all pages if total pages <= 5
        for (let i = 1; i <= totalPages; i++) {
          paginationNumbers.push(i);
        }
      } else {
        // Always show the first page (1)
        paginationNumbers.push(1);

        const halfWindow = Math.floor((totalPageNumbersToShow - 3) / 2); // Adjust for first and last pages

        let startPage = Math.max(2, page - halfWindow);  // Middle page range starts from 2
        let endPage = Math.min(totalPages - 1, page + halfWindow);  // Exclude the last page initially

        // Adjust start/end if too close to the boundaries
        if (page <= halfWindow + 2) {
          startPage = 2;
          endPage = 4;
        } else if (page >= totalPages - halfWindow - 2) {
          startPage = totalPages - 3;
          endPage = totalPages - 1;
        }

        // Show ellipsis if not adjacent to page 2
        if (startPage > 2) {
          paginationNumbers.push("...");
        }

        // Add middle pages
        for (let i = startPage; i <= endPage; i++) {
          paginationNumbers.push(i);
        }

        // Show ellipsis if not adjacent to the second last page
        if (endPage < totalPages - 1) {
          paginationNumbers.push("...");
        }

        // Always show the last page
        paginationNumbers.push(totalPages);
      }

      return paginationNumbers;
    };

    return (
      <div className="mx-auto my-4 text-white pagination-container">
        <nav aria-label="Page navigation example">
          <ul className="pagination justify-content-center" style={{ marginLeft: "10px" }}>
            {/* Previous Button */}
            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <img
                onClick={PreviousPageall}
                className="pagination-img"
                src={theme ? "/images/icons/leftdouble.png" : "/images/icons/darkleftdouble.png"}
                alt="Previous All"
              />
            </li>

            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <img
                onClick={PreviousPage}
                className="pagination-img"
                src={theme ? "/images/icons/left.png" : "/images/icons/darkleft.png"}
                alt="Previous"
              />
            </li>

            {/* Page Number Buttons */}
            {getPaginationNumbers().map((pageNumber, index) => (
              <li
                key={index}
                className={`page-item ${page === pageNumber ? "active" : ""}`}
              >
                {pageNumber === "..." ? (
                  <span className="me-1 ms-1" style={{ backgroundColor: "none", color: theme ? "#fff" : "#000" }}>...</span>
                ) : (
                  <button
                    className="page-link"
                    onClick={() => setPage(pageNumber)}
                    style={page === pageNumber ? styles.activePage : styles.pageLink}
                  >
                    {pageNumber}
                  </button>
                )}
              </li>
            ))}

            {/* Next Button */}
            <li className={`page-item ${page === paginationInfo?.totalPages ? "disabled" : ""}`}>
              <img
                onClick={NextPage}
                className="pagination-img"
                src={theme ? "/images/icons/right1.png" : "/images/icons/darkright.png"}
                alt="Next"
              />
            </li>

            <li className={`page-item ${page === paginationInfo?.totalPages ? "disabled" : ""}`}>
              <img
                onClick={NextPageall}
                className="pagination-img"
                src={theme ? "/images/icons/rightdouble.png" : "/images/icons/darkrightdouble.png"}
                alt="Next All"
              />
            </li>
          </ul>
        </nav>

        {/* Pagination Info */}

      </div>
    );
  };

  const handleInputChangevalue = (e) => {

    const { name, value } = e.target;
    setFormdata4({ ...formdata4, [name]: value });
  };


  useEffect(() => {
    if (formdata4?.title.length > 0) {
      setbuttonclickcreate(false); // Enable button when both fields have input
    } else {
      setbuttonclickcreate(true); // Disable button if any field is empty
    }
  }, [formdata4?.title]);




  const Handlewatchlistcategory = async () => {
    try {
      setcreatecategorymodel(false);
      const res = await FetchData("addwatchlistcat", 'POST', JSON.stringify(formdata4), true, false);
      if (res.success) {
        setFormdata4(prevState => ({
          ...prevState,
          title: ""
        }));
        setCategorymodel(true);
        fetchDatawatchlist();
        toast.success("Watchlist category created");
      } else {
        setFormdata4(prevState => ({
          ...prevState,
          title: ""
        }));
        toast.error("Watchlist category  already exist");
      }
      return null;
    } catch (error) {
      toast.error("Watchlist category already exist");
      setFormdata4(prevState => ({
        ...prevState,
        title: ""
      }));
      console.error('Error:', error);
      return null;
    }
  }




  const [formdata1, setFormdata1] = useState({
    user_id: profiledetails._id,
    watchlist_cat_id: "",
    stock_symbol: "",
    status: 1,
  });
  const Stock_id = data?.stockData?._id

  const fetchData = async (stockId) => {
    try {
      const res = await FetchData(
        `watchlists/${profiledetails._id}/${stockId ? stockId : StockID}`,
        "GET",
        null,
        true,
        false
      );
  
      if (res && res.data) {
        await setwatchlistcategory(res.data);
        setCategorymodel(true);
      } else {
        console.error("No data returned from the API");
        setCategorymodel(true);
      }
    } catch (error) {
      // Log the error details for debugging
      console.error("Error occurred while fetching data:", error);
      
      // You can also set an error state or message to display to the user
      setCategorymodel(true); // Optionally, if you want to handle the error differently in UI
    }
  };


    const fetchDatawatchlist = async () => {
    try {
      const res = await FetchData(`watchlistcat/${profiledetails._id}`, "GET", null, true, false);
  
      if (res && res.data) {
      
        setwatchlistcategory(res.data); // Populate watchlist
        
      } else {
        throw new Error("No data received from the API");
      }
    } catch (error) {
      // Handle any error (e.g., 400 status or network failure)
      console.error('Error fetching data:', error);
    }
  };

  const Watchlistcheck = async () => {
    const res = await FetchData(
      `watchlist/${profiledetails._id}`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      setApistockId(res.data.flatMap(item => item.stock_symbol))
    }
  };

  const stockbasefetchData = async (item) => {
    window.scrollTo(0, 0);
    const res = await FetchData(
      `stockbasedlist?q=${item?.screenerData?.isin}`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      setData(res.data.data);
      setStockID(res?.data?.data?.stockData?._id);
      const stockId = res?.data?.data?.stockData?._id
      await fetchData(stockId);
    }
  };


  // const fetchData3 = async () => {
  //   const res = await FetchData(
  //     `industrytasis`,
  //     "GET",
  //     null,
  //     true,
  //     false
  //   );
  //   if (res) {
  //     setindustry(res.data)
  //   }
  // };

  const fetchsecctor = async () => {
    const res = await FetchData(
      `sectorvalue`,
      "GET",
      null,
      true,
      false
    );
    if (res) {

      setsectorvalue(res.data)

    }
  };


  const handleclear = () => {
    setindustry('');
    fetchindustry();
    setFilterdata(false);
    setSelectedexchange('')
    setSelectedsector('')
    setSelectedIndustries('')
    Stockscreenerlist();
  }

  const handleCheckboxChange1 = (value, type) => {
    if (type === 'compliant') {
      if (selectedexchange.includes(value)) {
        setSelectedexchange(selectedexchange.filter((id) => id !== value));
      } else {
        setSelectedexchange([value]);
      }
    } else if (type === 'sector') {
      console.log('sector', value);
      setindustry('');
      if (selectedsector.includes(value)) {
        setSelectedsector(selectedsector.filter((id) => id !== value));
      }
      else {
        setSelectedsector([value]);
        const fetchData3 = async () => {
          const res = await FetchData(
            `sectorbyindustry?q=${value}`,
            "GET",
            null,
            true,
            false
          );
          if (res) {
            console.log('industryMatch', selectedIndustries)
            if (selectedIndustries.length != 0) {
              const industryMatch = res?.industries?.find(
                (industryObj) => industryObj.industry == selectedIndustries
              );
              if (industryMatch) {
                setindustry(res.industries);
              }
              else {
                setSelectedIndustries('');
                setindustry(res.industries);
              }

            }
            else {
              setSelectedIndustries('');
              setindustry(res.industries);
            }

          }
        };
        fetchData3();
      }

    } else if (type === 'industry') {
      setSelectedIndustries(value);
    }
  };


  const fetchindustry = async () => {
    const res = await FetchData(
      `screenerindustry`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      setoverallindustry(res?.data);
      setindustry(res?.data);
    }
  };


  useEffect(() => {
    fetchindustry();
    fetchsecctor();
  }, []);

  const handlestockcategory = async (item) => {
    console.log('item', item); // Check if item logs properly
    await stockbasefetchData(item); // Assuming stockbasefetchData expects an item
    // Fetch the updated data
    // setCategorymodel(true); // Show the category modal
  };

  const handleCheckboxChange11 = (categoryId) => {
    setSelectedCategories(prevSelectedCategories => {
      if (prevSelectedCategories.includes(categoryId)) {
        return prevSelectedCategories.filter(id => id !== categoryId);
      } else {
        return [...prevSelectedCategories, categoryId];
      }
    });
  };

  const handleSubmit = async () => {
    setCategorymodel(false)
    const updatedFormData = {
      ...formdata1,
      watchlist_cat_id: selectedCategories,
      stock_symbol: data?.stockData?._id,
    };
    setFormdata1(updatedFormData);
    const dataObject = {
      ...updatedFormData,
    };
    try {
      const res = await FetchData("addmultiplewatchlist", 'POST', JSON.stringify(dataObject), true, false);
      if (res.success) {
        toast.success("Watchlist Updated");
        Watchlistcheck();
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error("Watchlist Already Entered");
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (selectedCategories.length > 0) {
      setbuttonclick(false); // Enable button when both fields have input
    } else {
      setbuttonclick(true); // Disable button if any field is empty
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (selectedsector.length > 0 || selectedIndustries.length > 0 || selectedexchange.length > 0) {
      setApplybuttonclick(false); // Enable button when both fields have input
    } else {
      setApplybuttonclick(true); // Disable button if any field is empty
    }
  }, [selectedsector, selectedIndustries, selectedexchange]);


  useEffect(() => {
    if (watchlistcategory) {
      setwatchlist(false)
      const initialSelected = watchlistcategory
        .filter(category => category.stock_exists)
        .map(category => category._id);
        console.log('initialSelected',initialSelected)
      setSelectedCategories(initialSelected);
      const hasStock = watchlistcategory.some(category => category.stock_exists);
      if (hasStock) {
        setwatchlist(true);
   
      } else {
        setwatchlist(false);
     
      }
    }
  }, [watchlistcategory]);



  const [isRotating, setIsRotating] = useState(false);

  const handleClick = () => {
    // Toggle rotation state
    setIsRotating(true);
    // Optionally stop the animation after a certain time and call handleclear
    setTimeout(() => {
      setIsRotating(false);
      handleclear();  // Call your handleclear function after rotation stops
    }, 1000);  // Stops rotating after 2 seconds
  };


  return (
    <>
      <Navbar />
      <ToastContainer />
      <div className='container-fluid' style={{ width: '95%', minHeight: "85vh" }}>
        <div className='row justify-content-center'>
          <div className='card col-lg-12 col-md-12 col-sm-12' style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
            <div className='mt-2 p-3'>
              <div>
                <h5 className={`fs-4  ${theme ? 'text-white' : 'text-dark'}`}>Stock <span style={{ color: theme ? '#7E66BC' : '#5b298c'  }}>Screener</span></h5>
              </div>
              <div className='d-flex justify-content-between align-items-center pt-3 pb-3' style={{ borderRadius: '10px', flexDirection: isTab ? 'column' : 'row' }}>

                <div className="dropdown-container d-flex" style={{ gap: '5px', flexDirection: isMobile ? 'column' : 'row' }}>

                  <img
                    src="/images/islamic/refresh.png"
                    width={30}
                    height={30}
                    className={isRotating ? 'rotate mt-1' : 'mt-1'}
                    onClick={handleClick}
                    alt="Refresh Icon"
                  />

                  {/* Exchange Dropdown */}
                  <div className="dropdown-item1">
                    <div className="custom-dropdown">
                      <div
                        className="dropdown-toggle d-flex justify-content-between align-items-center"
                        style={{
                          backgroundColor: theme ? "#2C2C2C" : "#fff",
                          color: theme ? "#fff" : "#333",
                          border: `1px solid ${theme ? "#333" : "#ddd"}`,
                          padding: '6px 12px',
                          borderRadius: '8px',
                          width: isMobile ? "300px" : '200px',
                          cursor: 'pointer'
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/*  */}
                        {/* <span>{selectedexchange.length == 0 ?"Compliance":getSelectedExchangeName(selectedexchange)}</span> */}
                        <span>Compliance</span>
                        {selectedexchange.length !== 0 && (
                          <span className='alian-center' style={{ backgroundColor:theme ? '#7E66BC' : '#5b298c' , color: '#fff', borderRadius: '50%', padding: '4px 10px', fontSize: '12px', width: '26px' }}>
                            1
                          </span>
                        )}
                      </div>
                      <ul className="dropdown-menu" style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        borderRadius: '8px',
                        backgroundColor: theme ? "gray" : '#fff',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: '10px',
                        border: '1px solid #ddd',
                        zIndex: '1'

                      }}>
                        {Compliant && Compliant.map((compliant) => (
                          <li
                            key={compliant.id}
                            style={{
                              padding: '10px 8px',
                              marginBottom: '5px',
                              backgroundColor: theme ? "#333" : "#f9f9f9",
                              borderRadius: '5px',
                              transition: 'background-color 0.3s ease',

                            }}
                            className={`text-start ${theme ? 'text-white' : 'text-dark'} `}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme ? "#444" : "#eee"}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = theme ? "#333" : "#f9f9f9"}
                          >
                            <input
                              type="checkbox"
                              id={`compliant-${compliant.id}`}
                              checked={selectedexchange.includes(compliant.id)}
                              onChange={() => handleCheckboxChange1(compliant.id, 'compliant')}
                              style={{
                                transform: 'scale(1.2)',
                                marginRight: '8px',
                                accentColor: theme ? "#fff" : "rgb(127 102 188)"
                              }}
                            />
                            <label
                              className='ms-3'
                              htmlFor={`compliant-${compliant.id}`}
                              style={{
                                color: theme ? "#fff" : "#333",
                                marginLeft: '8px',
                                fontWeight: '500',
                                cursor: 'pointer',
                              }}
                            >
                              {compliant.name}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* Sector Dropdown */}
                  <div className="dropdown-item1">
                    <div className="custom-dropdown">
                      <div
                        className="dropdown-toggle d-flex justify-content-between align-items-center"
                        style={{
                          backgroundColor: theme ? "#2C2C2C" : "#fff",
                          color: theme ? "#fff" : "#333",
                          border: `1px solid ${theme ? "#333" : "#ddd"}`,
                          padding: '6px 12px',
                          borderRadius: '8px',
                          width: isMobile ? "300px" : '200px',
                          cursor: 'pointer'

                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <span>{selectedsector.length == 0 ?"Sectors":selectedsector}</span> */}
                        <span>Sectors</span>
                        {selectedsector.length !== 0 && (
                          <span className='alian-center ms-1' style={{ backgroundColor:theme ? '#7E66BC' : '#5b298c' , color: '#fff', borderRadius: '50%', padding: '4px 10px', fontSize: '12px', width: '26px' }}>
                            1
                          </span>
                        )}

                      </div>
                      <ul className="dropdown-menu" style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        borderRadius: '8px',
                        backgroundColor: theme ? "gray" : '#fff',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: '10px',
                        border: '1px solid #ddd',
                        zIndex: '1'
                      }}>
                        {sectorvalue && sectorvalue.map((sector) => (
                          <li
                            key={sector.id}
                            style={{
                              padding: '10px 8px',
                              marginBottom: '5px',
                              backgroundColor: theme ? "#333" : "#f9f9f9",
                              borderRadius: '5px',
                              transition: 'background-color 0.3s ease'
                            }}
                            className={` ${theme ? 'text-white' : 'text-dark'} `}
                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme ? "#444" : "#eee"}
                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = theme ? "#333" : "#f9f9f9"}
                          >
                            <input
                              type="checkbox"
                              id={`sector-${sector.id}`}
                              checked={selectedsector.includes(sector.sector)}
                              onChange={() => handleCheckboxChange1(sector.sector, 'sector')}
                              style={{
                                transform: 'scale(1.2)',
                                marginRight: '8px',
                                accentColor: theme ? "#fff" : "rgb(127 102 188)"
                              }}
                            />
                            <label
                              htmlFor={`sector-${sector.id}`}
                              style={{
                                color: theme ? "#fff" : "#333",
                                marginLeft: '8px',
                                fontWeight: '500',
                                cursor: 'pointer',
                              }}
                            >
                              {sector.sector}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {/* Industry Dropdown */}
                  <div className="dropdown-item1">
                    <div className="custom-dropdown">
                      <div
                        className="dropdown-toggle d-flex justify-content-between align-items-center"
                        style={{
                          backgroundColor: theme ? "#2C2C2C" : "#fff",
                          color: theme ? "#fff" : "#333",
                          border: `1px solid ${theme ? "#333" : "#ddd"}`,
                          padding: '6px 12px',
                          borderRadius: '8px',
                          width: isMobile ? '300px' : '200px',
                          cursor: 'pointer',
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span>Industries</span>
                        {selectedIndustries.length !== 0 && (
                          <span
                            className="align-center ms-1"
                            style={{
                              backgroundColor:theme ? '#7E66BC' : '#5b298c',
                              color: '#fff',
                              borderRadius: '50%',
                              padding: '4px 10px',
                              fontSize: '12px',
                              width: '26px',
                            }}
                          >
                            {selectedIndustries.length}
                          </span>
                        )}
                      </div>
                      <ul
                        className="dropdown-menu"
                        style={{
                          maxHeight: '250px',
                          width: '400px',
                          overflowY: 'auto',
                          borderRadius: '12px',
                          backgroundColor: theme ? '#1e1e2d' : '#ffffff',
                          padding: '10px',
                          boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
                          border: theme ? '1px solid #333' : '1px solid #ddd',
                          zIndex: '1',
                        }}
                      >
                        {industry &&
                          industry.map((industry) => (
                            <li
                              key={industry.id}
                              style={{
                                padding: '12px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                borderRadius: '8px',
                                marginBottom: '8px',
                                backgroundColor: selectedIndustries.includes(industry.industry)
                                  ? theme
                                    ? 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)'
                                    : 'linear-gradient(135deg, #fce38a 0%, #f38181 100%)'
                                  : 'transparent',
                                transition: 'all 0.3s ease',
                                boxShadow: selectedIndustries.includes(industry.industry)
                                  ? '0 4px 12px rgba(0, 0, 0, 0.15)'
                                  : 'none',
                                cursor: 'pointer',
                              }}
                            >
                              <label style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', width: '100%' }}>
                                <input
                                  type="checkbox"
                                  checked={selectedIndustries.includes(industry.industry)}
                                  onChange={() => handleCheckboxChange(industry.industry, 'industries')}
                                  style={{
                                    transform: 'scale(1.2)',
                                    marginRight: '8px',
                                    accentColor: theme ? "#fff" : "rgb(127 102 188)"
                                  }}
                                />
                                <span style={{ color: theme ? '#ffffff' : '#333333' }}>{industry.industry}</span>
                              </label>

                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  {/* Islamic Tijarat Rating Dropdown */}
                  <div className="dropdown-item1">
                    <div className="custom-dropdown">
                      <div
                        className="dropdown-toggle d-flex justify-content-between align-items-center"
                        style={{
                          backgroundColor: theme ? "#2C2C2C" : "#fff",
                          color: theme ? "#fff" : "#333",
                          border: `1px solid ${theme ? "#333" : "#ddd"}`,
                          padding: '6px 12px',
                          borderRadius: '8px',
                          width: isMobile ? "300px" : '250px',
                          cursor: 'pointer'
                        }}
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/*  */}
                        {/* <span>{selectedexchange.length == 0 ?"Compliance":getSelectedExchangeName(selectedexchange)}</span> */}
                        <span><img src='/images/islamic/premium.png' width={20} height={20} /></span>
                        <span>Islamic Tijarat Rating</span>

                      </div>
                      <ul className="dropdown-menu" style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        borderRadius: '8px',
                        backgroundColor: theme ? "gray" : '#fff',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: '10px',
                        border: '1px solid #ddd',
                        zIndex: '1'

                      }}>

                        coming soon

                      </ul>
                    </div>
                  </div>

                </div>
                <div className='text-center' style={{ marginTop: isMobile ? "2%" : "" }}>
                  {/* <button className='btn btn-sm me-2' style={{ backgroundColor: '#4B4B4B', color: 'white' }} onClick={() => { handleclear() }}>
                    Reset
                  </button> */}

                  <button className='btn text-center ' disabled={Applybuttonclick} style={{ backgroundColor:theme ? '#7E66BC' : '#5b298c' , color: 'white', height: '35px' }}
                    onClick={() => {
                      setFilterdata(true);
                      Filterdata();
                      setPage(1);
                    }}>
                    Apply Filter
                  </button>
                </div>

              </div>
              <div className="table-responsive"> {/* Make table responsive */}
                <table className="table mt-2 table-responsive" style={{ border: theme ? "0.5px solid #222222" : "0.5px solid rgba(0, 0, 0, 0.175)", textAlign: "start", backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                  <thead>
                    <tr>
                      <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Name</th>
                      <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Sector</th>
                      <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Industry</th>
                      {/* <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Exchange</th> */}
                      <th style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black', fontSize: '14px' }} scope="col">Compliance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      Array.from({ length: 10 }).map((_, index) => (
                        <tr key={index}>
                          <td colSpan={8} style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                            <Skeleton height={40} style={{ width: '100%' }} />
                          </td>
                        </tr>
                      ))
                    ) : (
                      Array.isArray(Stockscreener) && Stockscreener
                        // .slice(0, 542 === page ? 2 : Stockscreener.length)
                        .map((item, index) => (
                          <tr className='mx-auto pointer' key={index} >
                            <th scope="row" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', color: theme ? '#fff' : 'black' }}>

                              <div className='d-flex justify-content-start'>
                                {/*                             
                             {watchlist ? (<div>
                                <img src="/images/islamic/starselect.png" onClick={() => {
                          handlestockcategory(item)
                        }} 
                         className='me-2' width={25} height={25} />
                              </div> )
                              :
                              ( */}
                                <div>
                                  {GetSTockWhatchList(item.stock_ID)?
                                  <img src="/images/islamic/starselect.png" onClick={() => {
                                    handlestockcategory(item)
                                  }}
                                    className='me-2' width={23} height={23} />
                                    :<img src="/images/islamic/star.png" onClick={() => {
                                    handlestockcategory(item)
                                  }}
                                    className='me-2' width={23} height={23} />
                                }
                                </div>
                                {/* )} */}
                                <Imageview item={item} />
                                <div className='' onClick={() => { handleRowClick(item) }}>

                                  <p className='mb-0' style={{ padding: "0px 10px", fontSize: "14px", fontWeight: '500', letterSpacing: '1px' }}>{item?.screenerData?.symbol ? item?.screenerData?.symbol : item?.screenerData?.symbol1}</p>
                                  <h3 className='mb-0' style={{ padding: "0px 10px", fontSize: "12px", color: 'gray' }}>{item?.screenerData.stock}</h3>

                                </div>
                              </div>
                            </th>
                            <td onClick={() => { handleRowClick(item) }} style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                              {item?.screenerData?.sector}
                            </td>
                            <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                              {item?.screenerData?.industry}
                            </td>
                            {/* <td style={{ backgroundColor: theme ? "#1B1B1B" : '#fff', fontSize: "14px", color: theme ? '#fff' : 'black' }}>
                              {item?.stockData?.exchange}
                            </td> */}
                            {item?.screenerData?.status == 1 ? (
                              <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                                <div className="d-flex  justify-content-start" >
                                  <span className="heder-title" style={{ color: '#218303', fontWeight: "500" }}>Compliant</span>
                                  <span className="ms-1"><img src="/images/checklist.png" width={20} height={20} ></img></span>
                                </div>
                              </td>
                            ) : item?.screenerData?.status == 2 || item?.screenerData?.status == 4 || item?.screenerData?.status == 6 || item?.screenerData?.status == 7 ? (
                              <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                                <div className="d-flex  justify-content-start">
                                  <span className="heder-title" style={{ color: '#D43A3A', fontWeight: "500" }}>Non Compliant</span>
                                  <span className="ms-1"><img src="/images/non-permisable.png" width={20} height={20} ></img></span>
                                </div>
                              </td>
                            ) : item?.screenerData?.status == 3 ? (
                              <td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                                <div className="d-flex  justify-content-start" >
                                  <span className="heder-title" style={{ color: '#2132CA', fontWeight: "500" }}>Unknown</span>
                                  <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                                </div>
                              </td>
                            ) :
                              (<td className="heder-title" style={{ backgroundColor: theme ? "#1B1B1B" : '#fff' }}>
                                <div className="d-flex  justify-content-start">
                                  <span className="heder-title" style={{ color: '#EFA625', fontWeight: "500" }}>Under Review</span>
                                  <span className="ms-1"><img src="/images/underreview.png" width={20} height={20} ></img></span>
                                </div>
                              </td>
                              )
                            }
                          </tr>
                        ))
                    )}
                  </tbody>
                </table>

                <div
                  className={`modal fade ${categorymodel ? "show" : ''}`}
                  id="exampleModal2"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                  style={{
                    display: categorymodel ? "block" : 'none',
                    backgroundColor: categorymodel ? 'rgba(0, 0, 0, 0.5)' : '',
                    transition: 'opacity 0.3s ease'
                  }}
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-0 rounded-lg shadow-lg">
                      <div
                        className="modal-header text-center "
                        style={{
                          background: theme ? "#7E66BC" : "#5b298c",
                          borderBottom: "none",
                          borderRadius: "0.5rem 0.5rem 0 0",
                          padding: "1rem 1.5rem",
                        }}
                      >
                        <h5 className="modal-title w-100 " id="exampleModalLabel" style={{ color:'#fff' , }}>
                          Add to Your Watchlist
                        </h5>
                        <button
                          type="button"
                          className="btn-close btn-close-white text-white"
                          onClick={() => setCategorymodel(false)}
                          aria-label="Close"
                        ></button>
                      </div>
                      <div
                        className="modal-body"
                        style={{
                          padding: "1.5rem 4rem",
                          backgroundColor: "#F1F1F1",
                        }}
                      >
                        <div className="mb-4">
                          <div className="d-flex justify-content-end align-items-center mb-3">
                            <label className={`form-label mb-0 ${theme ? "text-dark" : ''} me-2`} style={{ fontSize: '14px' }}>Create Watchlist:</label>
                            <button
                              type="button"
                              className="btn btn-sm "
                              onClick={openCreateCategoryModal}
                              style={{
                                background: theme ? "#7E66BC" : "#5b298c",
                                color: '#fff',
                                borderRadius: '0.3rem',
                                padding: '0.5rem 1rem'
                              }}
                            >
                              + Create
                            </button>
                          </div>
                          <div className="d-flex flex-column gap-2" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                            {watchlistcategory && watchlistcategory.map((category) => (
                              <div
                                key={category._id}
                                className="d-flex align-items-center p-2 rounded shadow-sm"
                                style={{ border: theme ? '1px solid #7E66BC' : '1px solid #5b298c', backgroundColor: '#f0edf7' }}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input me-2"
                                  style={{ width: '20px', height: '20px', backgroundColor: selectedCategories.includes(category._id) ? "#5b298c" : "#fff", border: "0.5px solid #5b298c" }}
                                  id={`category-${category._id}`}
                                  checked={selectedCategories.includes(category._id)}
                                  onChange={() => handleCheckboxChange11(category._id)}
                                />
                                <div>
                                  <label htmlFor={`category-${category._id}`} className="form-check-label" style={{ fontSize: '12px' }}>
                                    {category.title}
                                  </label>
                                  <p className="mb-0 text-muted" style={{ fontSize: '12px' }}>
                                    {category.stock_count} Stocks
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer border-0" style={{ backgroundColor: '#f1f1f1' }}>
                        <div className="d-flex justify-content-end gap-3 w-100">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => setCategorymodel(false)}
                            style={{
                              borderRadius: "0.3rem",
                              padding: '0.5rem 1.5rem',
                              borderColor: '#ccc',
                              color: '#333',
                              transition: "background 0.3s, border-color 0.3s",
                            }}
                            onMouseEnter={(e) => e.target.style.borderColor = '#999'}
                            onMouseLeave={(e) => e.target.style.borderColor = '#ccc'}
                          >
                            Cancel
                          </button>
                          <button
                            onClick={handleSubmit}
                            className="btn btn-primary"
                            // disabled={buttonclick}
                            style={{
                              backgroundColor: theme ? "#7E66BC" : "#5b298c",
                              border: "none",
                              color: "#fff",
                              borderRadius: "0.3rem",
                              padding: '0.5rem 1.5rem',
                              transition: "background 0.3s, transform 0.2s",
                            }}
                            onMouseEnter={(e) => e.target.style.backgroundColor = theme ? "#7E66BC" : "#5b298c"}
                            onMouseLeave={(e) => e.target.style.backgroundColor = 'rgb(126, 102, 188)'}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`modal fade ${createcategorymodel ? "show" : ''}`} id="createCategoryModal" tabIndex="-1" aria-labelledby="createCategoryModalLabel" aria-hidden="true"
                  style={{ display: createcategorymodel ? "block" : 'none', backgroundColor: createcategorymodel ? '#0009' : '' }}>
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{
                          backgroundColor: "rgb(126, 102, 188)",
                          color: "#fff",
                          borderBottom: "none",
                          borderRadius: "0.5rem 0.5rem 0 0",
                          padding: "1rem 1.5rem",
                        }}
                      >
                        <h5 className="modal-title" id="createCategoryModalLabel">Create Watchlist</h5>
                        <button type="button" className="btn-close btn-close-white" onClick={() => { setcreatecategorymodel(false) }} aria-label="Close"></button>
                      </div>
                      <div className="modal-body" style={{ padding: "1.5rem 2rem", backgroundColor: "#F1F1F1" }}>
                        <div className="mb-4">
                          <label htmlFor="categoryTitle" className="form-label fw-bold mb-3">Watchlist Title:</label>
                          <input type="text" className="form-control" value={formdata4.title} name="title" id="categoryTitle" onChange={(e) => { handleInputChangevalue(e) }} placeholder="Enter Watchlist title" />
                        </div>
                      </div>
                      <div className="modal-footer" style={{ borderTop: "none", padding: "1rem 1.5rem" }}>
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={() => { setcreatecategorymodel(false) }}
                          style={{ borderRadius: "0.3rem", padding: '0.5rem 1.5rem' }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-dismiss="modal"
                          disabled={buttonclickcreate}
                          style={{
                            backgroundColor: "rgb(126, 102, 188)",
                            border: "none",
                            color: "#fff",
                            borderRadius: "0.3rem",
                            padding: '0.5rem 1.5rem',
                            transition: "background 0.3s, transform 0.2s",
                          }}
                          onClick={() => { Handlewatchlistcategory() }}  // Function to handle category creation
                          onMouseEnter={(e) => e.target.style.backgroundColor = 'rgb(154, 123, 187)'}
                          onMouseLeave={(e) => e.target.style.backgroundColor = 'rgb(126, 102, 188)'}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center'>
                <PaginationIndex />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Screener;
