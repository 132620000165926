import React, { useEffect, useRef, memo } from 'react';

const Overview = ({symbol, Themes }) => {
//  console.log('symbol',symbol);
  const container = useRef();

  useEffect(() => {
    // Clear the container to prevent duplication
    if (container.current) {
      container.current.innerHTML = '';
    }

    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
    script.type = "text/javascript";
    script.async = true;

    // Dynamically add the JSON configuration to the script
    script.innerHTML = JSON.stringify({
      symbols: symbol,
      chartOnly: true,
      width: "100%",
      height: "100%",
      locale: "en",
      colorTheme:Themes ? "dark": "light",
      autosize: true,
      showVolume: false,
      showMA: false,
      hideDateRanges: false,
      hideMarketStatus: true,
      hideSymbolLogo: true,
      scalePosition: "right",
      scaleMode: "Normal",
      fontFamily: "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
      fontSize: "10",
      noTimeScale: false,
      valuesTracking: "1",
      changeMode: "price-and-percent",
      chartType: "area",
      headerFontSize: "medium",
      backgroundColor: "rgba(255, 255, 255, 0)",
      lineWidth: 2,
      lineType: 0,
      dateRanges: [
        "1d|1",
        "1m|30",
        "3m|60",
        "12m|1D",
        "60m|1W",
        "all|1M"
      ],
      lineColor: "rgba(171, 71, 188, 1)",
      topColor: "rgba(225, 190, 231, 0.49)",
      bottomColor: "rgba(209, 196, 233, 0)",
      color: "rgba(123, 31, 162, 1)"
    });

    // Append the script to the container
    container.current.appendChild(script);

    // Clean up function to remove script to avoid duplication
    return () => {
      if (container.current) {
        container.current.innerHTML = '';
      }
    };
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          <span className="blue-text">Track all markets on TradingView</span>
        </a>
      </div>
    </div>
  );
}

export default memo(Overview);
