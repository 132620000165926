import React, { useState, useContext, useEffect } from 'react';
import Navbar from '../Navbar/Navbar'
import Footer from '../footer/Footer'
import "./policy.css"
import { UserContext } from '../../Utills/UserContext';

const Teamsandcondition = () => {
  const { theme, setTheme } = useContext(UserContext)
  return (
    <div className=''>
    <Navbar />
    <div className='container-fluid' style={{width:"95%"}}>
   <div className='card p-3' style={{backgroundColor: theme ? 'rgb(27, 27, 27)' : '#fff'}}>
    <h1 className={`mt-4 pt-2 headerfont ${theme ? 'text-white':'text-dark'}`}  style={{ textAlign :'center'}}>Terms and <span style={{color:theme ? '#7E66BC' : '#5b298c'}}>Conditions</span></h1>
    <br />
    <h5 className="text-center">
      <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}> Welcome to IslamicTijarat™️, a platform developed by ITG EDUTECH LLP to provide Shariah-compliant financial insights, tools, and educational resources. By accessing, using, or subscribing to any service offered by IslamicTijarat™️ (the "Platform"), you (the "User") agree to comply with these Terms and Conditions, which constitute a legally binding agreement between you and ITG EDUTECH LLP. This document is intended to protect the interests of ITG EDUTECH LLP and its subsidiaries (hereafter referred to as "IslamicTijarat™️"), ensuring a safe and reliable experience for users and safeguarding IslamicTijarat™️ from any potential legal disputes.</p>
    </h5>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>1. Agreement to Terms:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>By accessing, registering, or using any part of IslamicTijarat™️, you signify that you have read, understood, and agree to be bound by these Terms and Conditions, as well as our Privacy Policy and Refund Policy. If you do not accept these terms, please do not use the Platform. IslamicTijarat™️ reserves the right to modify these terms at any time, and continued use of the Platform after modifications constitutes acceptance of the updated terms.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>2. User Eligibility and Account Registration:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Eligibility: Users must be at least 18 years old or of legal age in their jurisdiction to access and use IslamicTijarat™️.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Account Security:Users are responsible for maintaining the confidentiality of their account details and for any activity occurring under their account. IslamicTijarat™️ is not liable for any unauthorized access or misuse of your account.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Account Verification: IslamicTijarat™️ may require identity verification for account security or regulatory compliance purposes and reserves the right to refuse or terminate accounts that do not meet verification requirements.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>3. Services and Scope of Use:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Description of Services: IslamicTijarat™️ provides users with Shariah-compliant screening tools, educational content, and financial insights. These services are for informational purposes only and do not constitute financial, investment, or legal advice.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>User Responsibilities:Users agree to use the Platform solely for lawful and personal purposes. Unauthorized reproduction, distribution, or misuse of content and services is strictly prohibited.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>No Warranty on Compliance: While IslamicTijarat™️ endeavors to provide accurate, up-to-date information based on specific Shariah compliance standards, it does not guarantee the accuracy or suitability of this information for all users or in all jurisdictions.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>4. Intellectual Property Rights:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Ownership of Content:All materials on the Platform, including but not limited to logos, trademarks, text, graphics, and software, are owned or licensed by IslamicTijarat™️ and protected by copyright, trademark, and intellectual property laws.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Limited License: Users are granted a limited, non-transferable license to use the Platform solely for personal, non-commercial use. Any unauthorized use, modification, or distribution of IslamicTijarat™️ content is strictly prohibited.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Trademark Protection: "IslamicTijarat™️" and associated logos are trademarks of ITG EDUTECH LLP and may not be used without explicit written permission.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>5. Disclaimer of Warranties and Limitation of Liability:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>As-Is Basis:IslamicTijarat™️ services and content are provided "as is" without warranties of any kind, whether express or implied, including but not limited to implied warranties of accuracy, completeness, reliability, or fitness for a particular purpose.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Limitation of Liability: To the maximum extent permitted by law, IslamicTijarat™️ and its parent company, ITG EDUTECH LLP, disclaim liability for any indirect, incidental, special, consequential, or punitive damages arising from or related to the use of the Platform, even if advised of the possibility of such damages. IslamicTijarat™️ is not liable for financial losses, data loss, or other harm resulting from reliance on the Platform's content.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Third-Party Content: IslamicTijarat™️ may contain links to third-party websites and content, which are provided for informational purposes only. IslamicTijarat™️ is not responsible for the accuracy or practices of third-party sites and does not endorse their content.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>6. Subscription, Billing, and Refunds:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Subscription Plans: IslamicTijarat™️ offers various subscription models. By subscribing, users agree to the associated fees and terms of each plan.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Payment and Renewal: Subscriptions automatically renew unless canceled prior to the renewal date. IslamicTijarat™️ is not liable for renewal fees incurred due to user failure to cancel subscriptions on time.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Refund Policy: Refunds are governed by our Refund Policy, with refund requests honored only within 24 hours of the initial purchase. Beyond this period, subscriptions are non-refundable, and no exceptions will be made.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>7. No Financial or Legal Advice:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>IslamicTijarat™️ is an educational platform only. The content and tools provided are for general informational purposes and are not intended as financial, legal, or investment advice. Users are encouraged to consult licensed financial or legal professionals before making investment or financial decisions. IslamicTijarat™️ disclaims any liability arising from actions taken based on content on the Platform.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>8. Compliance and Shariah Standards:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>Shariah Compliance:IslamicTijarat™️ follows specific Shariah-compliant screening standards, including those from established institutions. However, compliance varies by interpretation, and users are responsible for assessing the suitability of these standards for their investment goals.</p>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>No Guarantee of Absolute Compliance:IslamicTijarat™️ does not guarantee that any company or investment instrument listed on the Platform will be deemed compliant across all schools of thought or jurisdictions. IslamicTijarat™️ disclaims responsibility for variations in Shariah compliance interpretations.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>9. User Conduct and Prohibited Activities:</h5>
       <p className={` fontsize_p `} style={{color:theme ? '#7E66BC' : '#5b298c'}}>Users agree not to:</p>
       <ul className="list-styled">
  <li className={`fontsize_p ${theme ? 'text-white' : 'text-dark'}`}>
    1. Engage in any unauthorized or illegal use of the Platform.
  </li>
  <li className={`fontsize_p ${theme ? 'text-white' : 'text-dark'}`}>
   2. Attempt to interfere with or compromise the Platform's security or functionality.
  </li>
  <li className={`fontsize_p ${theme ? 'text-white' : 'text-dark'}`}>
   3. Use the Platform to disseminate harmful, misleading, or unauthorized content.
  </li>
  <li className={`fontsize_p ${theme ? 'text-white' : 'text-dark'}`}>
   4. mpersonate other individuals, falsify identity, or attempt to gain unauthorized access to other accounts.
  </li>
</ul>

       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>IslamicTijarat™️ reserves the right to take necessary action, including suspension or termination of accounts, for any breach of these terms or misuse of the Platform.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>10. Indemnity and Hold Harmless:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>By using the Platform, you agree to indemnify, defend, and hold harmless IslamicTijarat™️, ITG EDUTECH LLP, and their respective directors, employees, agents, and affiliates from and against any claims, liabilities, damages, losses, or expenses, including legal fees, arising out of your use of the Platform or any violation of these Terms and Conditions.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>11. Termination and Account Suspension:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>IslamicTijarat™️ reserves the right, at its sole discretion, to suspend or terminate user accounts without notice for violations of these Terms and Conditions or other Platform policies. Termination does not waive IslamicTijarat’s right to take further action, if necessary.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>12. Governing Law and Jurisdiction:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>These Terms and Conditions are governed by the laws of [Jurisdiction]. Any disputes arising out of or related to these terms, or the use of the Platform, shall be resolved exclusively in the courts of [Jurisdiction]. By using the Platform, you consent to the exclusive jurisdiction of these courts.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>13. Amendments and Modifications to Terms:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>IslamicTijarat™️ reserves the right to amend, update, or replace these Terms and Conditions at any time without prior notice. Users are responsible for reviewing the terms periodically. Continued use after any modification constitutes acceptance of the revised terms.</p>
    </section>

    <section className="mx-5 mt-5">
      <h5 className='titlefont' style={{color:theme ? '#7E66BC' : '#5b298c'}}>14. Contact Information:</h5>
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}>For questions, concerns, or further information, please contact IslamicTijarat™️ customer support at [Contact Information]. This document and its contents are legally binding, and by using the Platform, you agree to these Terms and Conditions in full.</p>
    </section>

    <section className="mx-5 mt-5">
       <p className={` fontsize_p ${theme ? 'text-white':'text-dark'}`}> By accessing, registering, or subscribing to IslamicTijarat™️, you confirm that you have read, understood, and agree to abide by these Terms and Conditions. Failure to adhere to these terms may result in immediate suspension or termination of your account and potential legal action</p>
      </section>
  </div>
  </div>
  <Footer />
  </div>
  )
}

export default Teamsandcondition