import React, { useState, useEffect } from "react";
import "./style.css";
import Navbar from "../Navbar/Navbar";
import Footer from "../footer/Footer";
import FetchData from "../../fetchapi/Apifetch";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Dashboard() {
  const [Stockscreener, setStockscreener] = useState("");
  const [page, setPage] = useState(1);
  const [paginationInfo, setPaginationInfo] = useState(null);
  const [filteredItems, setfilteredItems] = useState('');

  const cardbook = [
    {
      image: "./images/bookcover.png",
      title: "CNBC",
      date: "Jun 30, 10:28 PM",
      phead: "‘Preparation overload’",
      para: "Democrats defend Biden after debate flop as voter support flinches",
    },
    {
      image: "./images/bookcover.png",
      title: "CNBC",
      date: "Jun 30, 10:28 PM",
      phead: "‘Preparation overload’",
      para: "Democrats defend Biden after debate flop as voter support flinches",
    },
    {
      image: "./images/bookcover.png",
      title: "CNBC",
      date: "Jun 30, 10:28 PM",
      phead: "‘Preparation overload’",
      para: "Democrats defend Biden after debate flop as voter support flinches",
    },
    {
      image: "./images/bookcover.png",
      title: "CNBC",
      date: "Jun 30, 10:28 PM",
      phead: "‘Preparation overload’",
      para: "Democrats defend Biden after debate flop as voter support flinches",
    }
  ];
  const navigate = useNavigate();

  const handleRowClick = (item) => {
    // console.log('item',item);
    navigate("/stock", { state: { value: item } });
  };

  const NextPage = () => {
    setPage(page + 1);
  };

  const PreviousPage = () => {
    setPage(page - 1);
  };

  useEffect(() => {
    Stockscreenerlist();
  }, [page]);

  const Stockscreenerlist = async () => {
    // const res = await FetchData(`get/stockscreener?page=${page}&limit=12`, 'GET', null, true, false)
    const res = await FetchData(
      `catstocks`,
      "GET",
      null,
      true,
      false
    );
    if (res) {
      // console.log(res.data);
      setStockscreener(res.data);
      const firstItem = res.data.length > 0 ? res.data[0] : null;
      setfilteredItems(firstItem.stockall)

      setPaginationInfo(res.data.pagination);
    }
  };

  const getButtonColor = (Compliance) => {
    switch (Compliance) {
      case "Halal":
        return "#dfffd6";

      case "Haram":
        return "#ffe7d6";
      case "Doubtful !":
        return "#f6f7b0";
    }
  };
  const handlestockvalue = (value) => {
    const Items = Stockscreener?.find(
        (item) => item?._id == value
      );
      setfilteredItems(Items.stockall);
      // console.log('filteredItems',Items.stockall)

  };

  

  const PaginationIndex = () => {
    return (
      <div className="mx-auto my-4">
        <nav aria-label="Page navigation example">
          <ul className="pagination" style={{ marginLeft: "10px" }}>
            <li className={`page-item ${page === 1 ? "disabled" : ""}`}>
              <button className="page-link" onClick={PreviousPage}>
                Previous
              </button>
            </li>
            {paginationNumbers().map((pageNumber) => (
              <li
                key={pageNumber}
                className={`page-item ${page === pageNumber ? "active" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => setPage(pageNumber)}
                >
                  {pageNumber}
                </button>
              </li>
            ))}
            <li
              className={`page-item ${
                page === paginationInfo?.totalPages ? "disabled" : ""
              }`}
            >
              <button className="page-link" onClick={NextPage}>
                Next
              </button>
            </li>
          </ul>
        </nav>
        <div className="pagination my-2" style={{ marginLeft: "17px" }}>
          <p>Total Records: {paginationInfo?.totalItems}</p>
          <br />
          <p style={{ paddingLeft: "10px" }}>
            Total Pages: {paginationInfo?.totalPages}
          </p>
        </div>
      </div>
    );
  };
  const paginationNumbers = () => {
    const totalPages = paginationInfo?.totalPages || 0;
    const currentPage = page;
    const paginationRange = 10;
    const paginationStart = Math.max(
      1,
      currentPage - Math.floor(paginationRange / 2)
    );
    const paginationEnd = Math.min(
      totalPages,
      paginationStart + paginationRange - 1
    );
    const numbersToShow = [];
    for (let i = paginationStart; i <= paginationEnd; i++) {
      numbersToShow.push(i);
    }
    return numbersToShow;
  };

  const [activeButton, setActiveButton] = useState(0);



  return (
    <>
      <Navbar />

      <div
        className=" home-header"
        style={{ width: "90%", margin:'auto' }}
      >
        <div className="row" style={{marginTop:'7%'}}>
          <div className="col-lg-4 col-md-4 mt-2">
            <div
              className="card p-2 pt-2 d-flex flex-row"
              style={{ height: "260px", alignItems: "center" }}
            >
              <div>
                <h3 className="fonttitle">
                  Hi <span style={{ color: "#1b8700" }}>Muazzim</span>
                </h3>

                <p className="fonttext">
                  Get a Shariah compliance overview of your entire portfolio and
                  view purification amount.
                </p>
                <button
                  className="btn text-white"
                  style={{ backgroundColor: "#1b8700" }}
                >
                  Invest Now
                </button>
              </div>
              <div>
                <img
                  className="mt-3"
                  src="./images/trade.png"
                  alt="img"
                  style={{ height: "100px", width: "100px" }}
                />
                <p
                  style={{ fontSize: "10px" }}
                  className="text-center mb-0 m-1 fw-bold"
                >
                  % SHARIAH Compliant
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-8 herocard">
            {/* <input type='text' placeholder='Search stock & EFTs'className='w-100 px-3 search-box inputfield'/> */}
            <div className="d-flex">
              <div
                className="card py-3 mt-2 w-50 border-0"
                style={{
                  backgroundColor: "#734CEC",
                  color: "white",
                  margin: "5px",
                }}
                onClick={() => {
                  navigate("/screener");
                }}
              >
                <div className="d-flex px-2 justify-content-between align-items-center flex-row">
                  <div className="p-2">
                    <h4 className="fonttitle p-0">STOCK</h4>
                    <p className="para p-0">Screener</p>
                  </div>
                  <div className="img-circle d-flex align-items-center">
                    <img
                      className="mx-auto"
                      src="/images/money-graph-with-up-arrow.png"
                      alt="img"
                      style={{ height: "23px", width: "23px" }}
                    />
                  </div>
                </div>
                <div className="px-3">
                  <button
                    onClick={() => {
                      navigate("/screener");
                    }}
                    className="btn p-0 text-white fontsubtitle "
                  >
                    Learn More{" "}
                    <i
                      class="fa-solid fa-arrow-right"
                      style={{ color: "white", marginLeft: "5px" }}
                    ></i>{" "}
                  </button>
                </div>
              </div>
              <div
                className="card py-3 mt-2 w-50 border-0"
                style={{
                  backgroundColor: "#EEC54D",
                  color: "white",
                  margin: "5px",
                }}
              >
                <div className="d-flex px-2 justify-content-between align-items-center flex-row">
                  <div className="p-2">
                    <h4 className="fonttitle p-0">IPO</h4>
                    <p className="para p-0">Screener</p>
                  </div>
                  <div className="img-circle d-flex align-items-center">
                    <img
                      className="mx-auto"
                      src="/images/Group 1000002322.png"
                      alt="img"
                      style={{ height: "23px", width: "23px" }}
                    />
                  </div>
                </div>
                <div className="px-3">
                  <button className="btn p-0 text-white fontsubtitle">
                    Learn More{" "}
                    <i
                      class="fa-solid fa-arrow-right"
                      style={{ color: "white", marginLeft: "5px" }}
                    ></i>{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div
                className="card py-3 mt-2 w-50 border-0"
                style={{
                  backgroundColor: "#F1956B",
                  color: "white",
                  margin: "5px",
                }}
              >
                <div className="d-flex px-2 justify-content-between align-items-center flex-row" onClick={()=>{window.location.href="/purification"}}>
                  <div className="p-2">
                    <h4 className="fonttitle p-0">PURIFICATION</h4>
                    <p className="para p-0">Calculator</p>
                  </div>
                  <div className="img-circle d-flex align-items-center">
                    <img
                      className="mx-auto"
                      src="/images/money (3) 1.png"
                      alt="img"
                      style={{ height: "23px", width: "23px" }}
                    />
                  </div>
                </div>
                <div className="px-3">
                  <button className="btn p-0 text-white fontsubtitle">
                    Learn More{" "}
                    <i
                      class="fa-solid fa-arrow-right"
                      style={{ color: "white", marginLeft: "5px" }}
                    ></i>{" "}
                  </button>
                </div>
              </div>
              <div
                className="card py-3 mt-2 w-50 border-0 "
                style={{
                  backgroundColor: "#67C9E5",
                  color: "white",
                  margin: "5px",
                }}
              >
                <div className="d-flex px-2 justify-content-between align-items-center flex-row">
                  <div className="p-2">
                    <h4 className="fonttitle p-0">ZAKAT</h4>
                    <p className="para p-0">Calculator</p>
                  </div>
                  <div className="img-circle d-flex align-items-center">
                    <img
                      className="mx-auto"
                      src="images/financial-graph (1) 1.png"
                      alt="img"
                      style={{ height: "23px", width: "23px" }}
                    />
                  </div>
                </div>
                <div className="px-3">
                  <button className="btn p-0 text-white fontsubtitle">
                    Learn More{" "}
                    <i
                      class="fa-solid fa-arrow-right"
                      style={{ color: "white", marginLeft: "5px" }}
                    ></i>{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 ">
            <div className="card mt-3 p-2">
              <h3 className="fonttitle">Market News</h3>
              {cardbook.map((item) => {
                return (
                  <div className="d-flex p-3">
                    <img
                      className="mx-auto"
                      src={item.image}
                      alt="img"
                      style={{ height: "129px", width: "95px" }}
                    />
                    <div className="">
                      <p
                        className="mb-0"
                        style={{ fontSize: "0.75rem", textAlign: "end" }}
                      >
                        {item.date}
                      </p>
                      <h3 className="fonttitle">{item.title}</h3>
                      <p
                        style={{ fontSize: "12px", paddingLeft: "5px" }}
                        className="mb-0 "
                      >
                        {" "}
                        {item.phead} <br />
                        {item.para}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-lg-8 col-md-8 ">
            <div className="card mt-3 p-3">
              <div className="d-flex justify-content-between">
                <h4 className="fonttitle ">Trending Halal Stocks</h4>
                <Link to="/stockpage">
                  <button className="btn  text-success ">
                    View All{" "}
                    <i
                      class="fa-solid fa-arrow-right text-success"
                      style={{ color: "black", marginLeft: "5px" }}
                    ></i>{" "}
                  </button>
                </Link>
              </div>

              <div>
                {Stockscreener && Stockscreener.map((button, index) => (
                  <button
                    key={index}
                    className="btn"
                    style={{
                      borderRadius: "20px",
                      fontWeight: "700",
                      backgroundColor:
                        activeButton === index ? "green" : "transparent",
                      color: activeButton === index ? "white" : "black",
                    }}
                    onClick={() =>{ setActiveButton(index)
                      handlestockvalue(button._id)}
                    }
                  >
                    {button.categoryname}
                  </button>
                ))}
              </div>

              <table
                class="table mt-2 table-responsive "
                style={{ border: "0.5px solid #ebebeb", textAlign: "start" }}
              >
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">CashTotalAsset</th>
                    <th scope="col">DebtAsset</th>
                    <th scope="col">Compliance</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredItems &&
                    filteredItems.map((item, index) => {
                   
                      return (
                        <tr className="mx-auto">
                          <th scope="row">
                            <div
                              className="d-flex justify-content-start"
                              onClick={() => handleRowClick(item)}
                            >
                              <img
                                className=""
                                src={item && item.stockData && item.stockData.img ? item.stockData.img : ""}
                                alt="img"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "50%",
                                  border: "0.5px solid #ededed",
                                }}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = "./images/dummy.png";
                                }}
                              />
                              <div className="">
                                <h3
                                  className="mb-0"
                                  style={{
                                    padding: "0px 10px",
                                    fontSize: "1rem",
                                  }}
                                >
                                  {item?.screenerData.stock}
                                </h3>
                                <p
                                  className="mb-0"
                                  style={{
                                    padding: "0px 10px",
                                    fontSize: "0.8rem",
                                    color: "gray",
                                  }}
                                >
                                {item && item.stockData && item.stockData.stock_code ? item.stockData.stock_code : ""}
                                </p>
                              </div>
                            </div>
                          </th>
                          <td>
                            {item?.screenerData.cashTotalAsset === undefined ||
                            item?.screenerData.cashTotalAsset === null ||
                            isNaN(Number(item?.screenerData.cashTotalAsset))
                              ? "N/A"
                              : Number(
                                  item.screenerData.cashTotalAsset
                                ).toFixed(2)}
                          </td>
                          <td>
                            {item?.screenerData.debtAsset === undefined ||
                            item?.screenerData.debtAsset === null ||
                            isNaN(Number(item?.screenerData.debtAsset))
                              ? "N/A"
                              : Number(item.screenerData.debtAsset).toFixed(2)}
                          </td>
                          {item?.screenerData.status == 1 ? (
                            <td>
                              {" "}
                              <button
                                className="btn"
                                style={{
                                  backgroundColor: "#DFFFD6",
                                  color: "#218303",
                                }}
                              >
                                Halal{" "}
                                <i
                                  class="fa-solid fa-check"
                                  style={{ color: "green" }}
                                ></i>{" "}
                              </button>
                            </td>
                          ) : item?.screenerData?.status == 2 ? (
                            <td>
                              {" "}
                              <button
                                className="btn"
                                style={{
                                  backgroundColor: "#ffd6d6",
                                  color: "#9f0000",
                                }}
                              >
                                Haram{" "}
                                <i
                                  class="fa-solid fa-times"
                                  style={{ color: "red" }}
                                ></i>{" "}
                              </button>
                            </td>
                          ) : (
                            <td>
                              {" "}
                              <button
                                className="btn"
                                style={{
                                  backgroundColor: "#feffd6",
                                  color: "#9f8500",
                                }}
                              >
                                {" "}
                                Unknown
                                <i
                                  class="fa-solid fa-circle-info"
                                  style={{ color: "#9f8500" }}
                                ></i>{" "}
                              </button>
                            </td>
                          )}
                         
                        </tr>
                      );
                    })}
                </tbody>
              </table>

              {/* <PaginationIndex /> */}
            </div>
          </div>
        </div>

        {/* bottom card */}
        <div className="card mt-2">
          <div className="row p-3">
            <div className="col-lg-12 col-md-12">
              <div className="d-flex justify-content-between">
                <h4 className="fonttitle ">Islamic Tijarat Academy</h4>
                <button className="btn  text-success ">
                  View All{" "}
                  <i
                    class="fa-solid fa-arrow-right text-success"
                    style={{ color: "black", marginLeft: "5px" }}
                  ></i>{" "}
                </button>
              </div>

              <p className="para">
                Explore the portfolios of renowned investors. Track their Buys{" "}
                <br /> and Sells to get inspired.
              </p>

              <div className="d-flex justify-content-around flex-wrap">
                <img src="./images/cardimg.png" className="boximg" alt="img" />
                <img src="./images/cardimg.png" className="boximg" alt="img" />
                <img src="./images/cardimg.png" className="boximg" alt="img" />
                <img src="./images/cardimg.png" className="boximg" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Dashboard;
