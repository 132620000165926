import React,{useState , useContext} from  'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../footer/Footer'
import "./setting.css"
import { UserContext } from '../../Utills/UserContext';
import FetchData from "../../fetchapi/Apifetch";
import { useNavigate, useLocation } from "react-router-dom";
const Setting = () => {
  const profiledetails = JSON.parse(localStorage.getItem("email"));
  const [pages, setpages] = useState("Setting");
  const navigate = useNavigate();
  const { theme, setTheme } = useContext(UserContext);
  const [canUpdateImage, setCanUpdateImage] = useState(true);

  const [profiledetails1, setProfileDetails] = useState({
    first_name:profiledetails?.first_name,
    last_name:profiledetails?.last_name,
    email:profiledetails?.email,
    phone:profiledetails?.phone,
    address:profiledetails?.address,
    img:profiledetails?.img
  });

  const { user, setUser } = useContext(UserContext);
  const updateprofile = async () => {
    
    try {
      const res = await FetchData(`updateuser/${profiledetails?._id}`, 'POST', JSON.stringify(profiledetails1), true,false );
      if (res.success) {
        const jsonLoginData = JSON.stringify(res.data);
        localStorage.setItem("email",jsonLoginData);
        window.location.reload();
      } else {
        console.error("Please Check Crediential")
      }
      return null;
    } catch (error) {
      console.error('Error:', error);
      return null;
    }
  }

  const handlelogout = () => {
    window.location.href = "/";
    setUser(null);
    localStorage.clear();
  };

  const handleresetpassword = () => {
    window.location.href = "/forgotpassword";
    setUser(null);
    localStorage.clear();
  };

  const Cardimage = ({ value }) => {
    const name = `${profiledetails?.first_name} ${profiledetails?.last_name}`;
    const splitStockname = (name) => {
      if (!name) return '';
      const lettersOnlyRegexvalue = /^[A-Za-z]/;
      const wordsvalue = name.split(' ');
      const firstletter = wordsvalue[0]?.charAt(0).match(lettersOnlyRegexvalue) ? wordsvalue[0].charAt(0) : '';
      const secondletter = wordsvalue[1]?.charAt(0).match(lettersOnlyRegexvalue) ? wordsvalue[1].charAt(0) : '';
      return `${firstletter}${secondletter}`.toUpperCase(); // Convert to uppercase if needed
    };

    return (
      <div className="d-flex justify-content-center">
          <div
          style={{
            height: "80px",
            // marginTop:'-5%',
            width: "80px",
            borderRadius: "5px",
            border: theme ? "2.5px solid #7E66BC" : "2.5px solid #5b298c",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: 'black',
            fontWeight:500,
            backgroundColor:"#f0edf7",
            fontSize: "35px",
            // fontWeight: "bold",
          }}
        >
          {splitStockname(name)}
        </div>
      </div>
    );
  };

const Handlethemeset = (e) =>{
  setTheme(!theme)
}
  
  return (

    <>
    <Navbar  />
    <div className='d-flex justify-content-center '>
    <div className={`card  d-flex ${theme ?'text-white':'text-black '}`} style={{width:'95%',borderTopRightRadius:"10px",borderTopLeftRadius:'10px',backgroundColor:theme?'#1B1B1B':'#fff'}}>
        <div className='d-flex p-5'>
            <div><Cardimage /></div>
            {/* <p><span className='text-success'><i className="fa fa-clock text-secondary me-1" />online</span><span className='ms-4'><i className="fa fa-calendar text-secondary me-1" />Joined an hour ago</span></p>
                <p><span className='fw-bold'>100 Followers</span><span className='fw-bold ms-5'>500 Following</span> </p> */}
            <div className="ms-3">
  <h4 className="fw-bold">
    {profiledetails.first_name} 
    <span className="ms-2">{profiledetails.last_name}</span>
    <i className="fa fa-edit ms-2 " style={{ cursor: "pointer" }} data-bs-toggle="modal"
        data-bs-target="#editProfileModal" aria-hidden="true"></i>
  </h4>
</div>

        </div>
<div style={{marginTop:'1%'}}>
    <div className='d-flex justify-content-around p-2' style={{borderTop:'1px solid #484848',borderBottom:'1px solid #484848'}}>
        <div onClick={()=>{setpages('Ideas')}} style={{borderBottom: pages == 'Ideas'?'2px solid green':''}}>Ideas </div>
        <div onClick={()=>{setpages('Followers')}} style={{borderBottom: pages == 'Followers'?'2px solid green':''}}>Followers </div>
        <div onClick={()=>{setpages('Following')}} style={{borderBottom: pages == 'Following'?'2px solid green':''}}>Following</div>
        <div className='' onClick={()=>{setpages('Setting')}} style={{borderBottom: pages == 'Setting'?'2px solid green':''}}>Settings</div>
    </div>
    {pages == 'Setting' &&
<div className='row'>


<div  className='col-12 col-md-9 mt-3'>
<div>
<div className='ms-3' style={{borderBottom:'1px solid #484848'}}>
<div className="row px-5 mx-4 fw-bold" >
  <p className='col-6 text-start' style={{color:'#7E66BC'}}>Profile Details</p>
  </div>
</div>

<div className=" ms-3 mt-2" style={{ borderBottom: "1px solid #484848" }}>
  <div className="row mt-3 mb-3 px-5 mx-4" >
    <div className="col-6 text-start">First Name</div>
    <div className="col-6 text-start ps-5">{profiledetails.first_name}</div>
  </div>
</div>

<div className="ms-3 mt-2" style={{ borderBottom: "1px solid #484848" }}>
  <div className="row mt-3 mb-3 px-5 mx-4">
    <div className="col-6 text-start">Last Name</div>
    <div className="col-6 text-start ps-5">{profiledetails.last_name}</div>
  </div>
</div>

<div className="ms-3 mt-2" style={{ borderBottom: "1px solid #484848" }}>
  <div className="row mt-3 mb-3 px-5 mx-4">
    <div className="col-6 text-start">Email Address</div>
    <div className="col-6 text-start ps-5">{profiledetails.email}</div>
  </div>
</div>

<div className="ms-3 mt-2" style={{ borderBottom: "1px solid #484848" }}>
  <div className="row mt-3 mb-3 px-5 mx-4">
    <div className="col-6 text-start">Phone Number</div>
    <div className="col-6 text-start ps-5">{profiledetails.phone}</div>
  </div>
</div>

<div className="ms-3 mt-2" style={{ borderBottom: "1px solid #484848" }}>
  <div className="row mt-3 mb-3 px-5 mx-4">
    <div className="col-6 text-start">Gender</div>
    <div className="col-6 text-start ps-5">Male</div>
  </div>
</div>

<div className="ms-3 mt-2" style={{ borderBottom: "1px solid #484848" }}>
  <div className="row mt-3 mb-3 px-5 mx-4">
    <div className="col-6 text-start">Age Group</div>
    <div className="col-6 text-start ps-5 ">28</div>
  </div>
</div>


</div>
</div>
<div className='col-12 col-md-3' >
    <div className='m-3'>
        <div className='card d-flex justify-content-center w-100 text-center'style={{borderRadius:'10PX',backgroundColor:theme?"#1B1B1B":'#fff',color:theme?'#fff':'black'}} >
            <div className='text-center' >
      
                <p className='text-white p-2 ' style={{backgroundColor:'#7E66BC',borderTopLeftRadius:'10px',borderTopRightRadius:'10px'}}><span>      <i className="fa fa-user text-white me-2" /></span>Profile Details</p>

            </div>
            <div  className='text-start ms-3'>
                <p className="pointer" onClick={() => {handleresetpassword() }}><span>  <i className="fa fa-unlock-alt me-3 " /></span>Reset Password</p>
                <p className='pointer' onClick={() => {navigate("/plan"); }} ><span><i className={`fa fa-credit-card me-2 ${theme?"text-white":"text-dark"}`} /> </span>Subscription</p>
                <p><span>  <i className="fa fa-star me-3 text-warning" /></span>Premium Membership</p>
                <p><span>  <i className="fa fa-file-text me-3" /></span>Refer a friend</p>
            </div>
            <div className='p-1 text-start ms-3' style={{borderTop:'1px solid #484848',borderBottom:'1px solid #484848'}}>
            {/* <p>
  <span>
    <i className="fa fa-adjust me-3 text-white"></i>
  </span>
  Theme
  <span>
    <button className="btn theme-btn me-3">Dark</button>
    <button className="btn theme-btn-light">Light</button>
  </span>
</p> */}
              
              <div class="dongle-container mb-3 mt-3">
    <span class={`dongle-label ${theme ?'text-white':'text-black'}`}>  <span>
    <i className={`fa fa-adjust me-3 ${theme?"text-white":'text-black'} `}></i>
  </span>Theme</span>
    <div class="dongle-toggle">
        <input type="checkbox" id="themeToggle" value={theme}  onChange={(e)=>{Handlethemeset(e)}} />
        <label for="themeToggle" class="dongle-slider"></label>
    </div>
</div>
  <p> <span>  <i className={`fa fa-question-circle me-3 ${theme?'text-white':'text-black'}`} /></span>Help Center</p>
    <p> <span>  <i className={`fa fa-bell me-3 ${theme ?'text-white':'text-black'}`} /></span>Notification</p>
            </div>
        <div className='mt-2 text-start ms-4'>
        <p className='text-danger pointer'  onClick={()=>{handlelogout()}}> <span>  <i className="fa fa-power-off me-3 text-danger" /></span>logout</p>
        </div>

        </div>
    </div>


    <div className="modal fade" id="editProfileModal" tabIndex="-1" aria-labelledby="editProfileModalLabel" aria-hidden="true" data-bs-backdrop="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content" style={{
      backgroundColor: theme ? '#1B1B1B' : '#ffffff',
      borderRadius: '0.5rem'
    }}>
      <div className="modal-header" style={{
        background: theme ? "linear-gradient(90deg, rgba(126,102,188,1) 0%, rgba(154,123,187,1) 100%)" : '#f0f0f0',
        borderBottom: "none",
        borderRadius: "0.5rem 0.5rem 0 0",
        color: theme ? '#ffffff' : '#000000'
      }}>
        <h5 className="modal-title" id="editProfileModalLabel">Update Profile</h5>
      </div>
      <div className="modal-body" style={{
        padding: "2rem",
        backgroundColor: theme ? '#1B1B1B' : '#F9F9F9'
      }}>
        <form id="profileForm">
          {/* Profile Photo Upload */}
          <div className="mb-3 text-center">
            <div style={{
              position: 'relative',
              cursor: canUpdateImage ? 'pointer' : 'not-allowed',
              display: 'inline-block'
            }}>
           
           <Cardimage
      item={profiledetails}
      width={"80px"}
      height={"80px"}
      font={"30px"}
      value={true}
    />
            </div>
          </div>
          
          {/* Other Form Fields */}
          <div className="mb-3">
  <label htmlFor="firstName" className={`form-label ${theme ? 'text-white' : 'text-black'}`}>First Name</label>
  <input
    type="text"
    className={`form-control ${theme ? 'bg-dark text-white' : 'bg-light text-dark'}`}
    id="firstName"
    value={profiledetails1.first_name}
    onChange={(e) => {
      const value = e.target.value;
      // Validate: only letters and length between 3 and 20
      if (/^[A-Za-z]*$/.test(value) && value.length <= 20) {
        setProfileDetails({ ...profiledetails1, first_name: value });
      }
    }}
  />
</div>
<div className="mb-3">
  <label htmlFor="lastName" className={`form-label ${theme ? 'text-white' : 'text-black'}`}>Last Name</label>
  <input
    type="text"
    className={`form-control ${theme ? 'bg-dark text-white' : 'bg-light text-dark'}`}
    id="lastName"
    value={profiledetails1.last_name}
    onChange={(e) => {
      const value = e.target.value;
      // Validate: only letters and length between 3 and 20
      if (/^[A-Za-z]*$/.test(value) && value.length <= 20) {
        setProfileDetails({ ...profiledetails1, last_name: value });
      }
    }}
  />
</div>

          <div className="mb-3">
            <label htmlFor="email" className={`form-label ${theme ? 'text-white' : 'text-black'}`}>Email Address</label>
            <p style={{color:'gray'}}>{profiledetails1.email}</p>
            {/* <input
              type="email"
              className={`form-control ${theme ? 'bg-dark text-white' : 'bg-light text-dark'}`}
              id="email"
              value={profiledetails1.email}
              onChange={(e) => setProfileDetails({ ...profiledetails1, email: e.target.value })}
            /> */}
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className={`form-label ${theme ? 'text-white' : 'text-black'}`}>Phone Number</label>
            <input
  type="text"
  className={`form-control ${theme ? 'bg-dark text-white' : 'bg-light text-dark'}`}
  id="phone"
  value={profiledetails1.phone}
  onChange={(e) => {
    // Only allow numbers
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // RegEx: only allow digits (0-9)
      setProfileDetails({ ...profiledetails1, phone: value });
    }
  }}
  placeholder="Enter your phone number"
/>

          </div>
          <div className="mb-3">
            <label htmlFor="address" className={`form-label ${theme ? 'text-white' : 'text-black'}`}>Address</label>
            <input
              type="text"
              className={`form-control ${theme ? 'bg-dark text-white' : 'bg-light text-dark'}`}
              id="address"
              value={profiledetails1.address}
              onChange={(e) => setProfileDetails({ ...profiledetails1, address: e.target.value })}
            />
          </div>
          
        </form>
      </div>
      <div className="modal-footer" style={{
        borderTop: "none",
        backgroundColor: theme ? '#1B1B1B' : '#ffffff'
      }}>
        <div className="d-flex justify-content-end gap-2 w-100">
          <button type="button" className={`btn ${theme ? 'btn-outline-secondary text-white' : 'btn-outline-dark'}`} data-bs-dismiss="modal" style={{ borderRadius: "0.3rem" }}>Cancel</button>
          <button type="button" className='btn' onClick={() => { updateprofile() }} style={{ borderRadius: "0.3rem",backgroundColor:"rgb(126, 102, 188)",color:"#fff" }}>Update</button>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
</div>
}
</div>

    </div>
</div>
<Footer />

    </>
  )
}

export default Setting