import React, { useContext } from 'react';
import Footer from '../footer/Footer';
import Navbar from '../Navbar/Navbar';
import "../Purificationcalculator/purify.css"
import { UserContext } from '../../Utills/UserContext';
import "./comingsoon.css"
const Zakathcomingsoon = () => {
  const { theme } = useContext(UserContext);

  return (
    <div>
      <Navbar />

      {/* Main Content */}
      <div className="home-header padding-header" style={{ width: '95%', margin: 'auto' }}>
        <div className="row mobilemarign" >
          <div className="d-flex justify-content-center align-items-center" >
            <div className="text-center">
              <img src='/images/comingsoon.png' alt='commingsoon'  style={{width:'40%'}}  />
              <p className={`mt-3 ${theme ? "text-white" :'text-dark'} mx-auto`} style={{ fontSize: '16px', width: '80%', textAlign: 'center' }}>
              We’re excited to introduce our new Zakat Calculator, launching in the next phase. Stay tuned for this valuable tool to help you easily calculate and manage your Zakat obligations!

              </p>
              
              {/* Input Field with "Notify Me" Button */}
              <div className="mt-4 d-flex justify-content-center">
                <div className="input-group" style={{ maxWidth: '400px' }}>
                  <input 
                    type="text" 
                    className={`form-control text-center ${theme?'placeholder-dark':'placeholder-light'}`} 
                    placeholder="Please enter your email address"
                    style={{
                      backgroundColor:theme?'#424242':'',
                      color:theme?"#fff":'black',
                    }}
                  />
                  <button className="btn" type="button" style={{backgroundColor:theme?'#fff':'#424242',color:theme?'black':'#fff'}}>
                    Notify Me
                  </button>
                </div>
              </div>
              <div className="contact-section text-center py-4 d-flex justify-content-center align-items-center">
        <span className={`me-3 ${theme?'text-white':'text-dark'}`}>Contact Us</span>

        {/* Icon with Background Circle */}
        <div className='d-flex justify-content-center align-items-center' style={{ backgroundColor: '#424242', width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}>
          <i className="fas fa-envelope" style={{ color: '#fff' }}></i>
        </div>  

        {/* Instagram Icon */}
        <div className='d-flex justify-content-center align-items-center' style={{ backgroundColor: '#424242', width: '30px', height: '30px', borderRadius: '50%', marginRight: '10px' }}>
          <i className="fab fa-instagram" style={{ color: '#fff' }}></i>
        </div>  

        {/* Facebook Icon */}
        <div className='d-flex justify-content-center align-items-center' style={{ backgroundColor: '#424242', width: '30px', height: '30px', borderRadius: '50%' }}>
          <i className="fab fa-facebook" style={{ color: '#fff' }}></i>
        </div>  
      </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Us Section */}
 

      {/* Optional Footer */}
      <Footer />
    </div>
  );
}

export default Zakathcomingsoon;
