import React, { useState, useContext, useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../footer/Footer';
import './style.css';
import { UserContext } from '../../Utills/UserContext';
import FetchData from '../../fetchapi/Apifetch';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Plan = () => {
  const [screen, setScreen] = useState('Quartely');
  const [isChecked, setIsChecked] = useState(false);
  const [backscreen, setBackscreen] = useState('');
  const [isChecked1, setIsChecked1] = useState(true);
  const [subscription, setSubscription] = useState('');
  const { theme, setTheme } = useContext(UserContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 700);
  const handleCheckboxChange = () => {
    setIsChecked1(false);
    setIsChecked(!isChecked);

  };
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountPrice, setdiscountPrice] = useState(0);
  const [Price, setPrice] = useState(0);
  const [offerPrice, setofferPrice] = useState(0);
  const [Gst, setGst] = useState(0);
  const handleCheckboxChang1 = () => {
    setIsChecked(false);
    setIsChecked1(!isChecked1);
  };
  const [isLoading, setIsLoading] = useState(true); // State for loading

  const [receiptNumber, setReceiptNumber] = useState('');

  // Function to generate a unique receipt number
  const generateReceiptNumber = () => {
    const prefix = 'REC'; // You can use any prefix you like
    const timestamp = Date.now(); // Get the current timestamp (milliseconds since 1970)
    const randomNumber = Math.floor(1000 + Math.random() * 9000); // Generate a random 4-digit number
    return `${prefix}-${timestamp}-${randomNumber}`; // Combine them to form the receipt number
  };

  useEffect(() => {
    const newReceiptNumber = generateReceiptNumber();
    setReceiptNumber(newReceiptNumber);
    console.log('newReceiptNumber', newReceiptNumber)
    // Set the receipt number when the component mounts
  }, []);


  const [selectedAmount, setSelectedAmount] = useState(null);

  // Handle the Subscribe Now button click
  const handleSubscribe = (amount, screenvalue) => {
    setScreen("conformation");
    setSelectedAmount(amount); // Store the amount in state
    setBackscreen(screenvalue); // Store the backscreen value in state
  };

  // Use useEffect to recalculate when selectedAmount and backscreen change
  useEffect(() => {
    if (selectedAmount && backscreen) {
      const basePrice = backscreen === 'Yearly' ? parseFloat(selectedAmount?.subscription_info?.price.replace(/\/annually$/, '')) : parseFloat(selectedAmount?.subscription_info?.price.replace(/\/quarterly$/, ''));
      const offerPrice = parseFloat(selectedAmount?.subscription_info?.offer_price);
      const months = backscreen === 'Yearly' ? 12 : 6;

      // Total price for the period (6 months or 12 months)
      const totalPrice = basePrice * months;
      setPrice(totalPrice)
      // Offer price for the same period (6 or 12 months)
      const totalOfferPrice = offerPrice * months;
      setofferPrice(totalOfferPrice)
      // Calculate the price after discount (cannot be less than 0)
      const pricevalue = Math.max(totalPrice - totalOfferPrice, 0);
      setdiscountPrice(pricevalue);
      // Calculate GST on the discounted price (pricevalue)
      const GSTvalue = Math.round((totalOfferPrice / 100) * 18);
      setGst(GSTvalue)
      // Calculate the total amount to be paid (price + GST)
      const totalAmountpayment = totalOfferPrice + GSTvalue;

      console.log('totalAmountpayment', totalAmountpayment)
      setTotalAmount(totalAmountpayment);
    }
  }, [selectedAmount, backscreen]);


  // const Addpayment = async () => {
  //   // setconnectiondata((prevFormdata) => ({
  //   //   ...prevFormdata,
  //   //   GroupId: item,
  //   // }));

  //   const dataObject = {
  //     ...connectiondata,
  //     GroupId: item,
  //   };
  //   try {
  //     const res = await FetchData("addconnection", 'POST', JSON.stringify(dataObject), false, false);
  //     if (res.success) {
  //       console.log('User connected successfully')
  //     } else {
  //       console.log(res.error);
  //     }
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }
  // };



  const listsubscription = async () => {
    // Check if Groupvalue1 is valid
    try {
      const res = await FetchData(
        `listPreimumfeatures`,
        "GET",
        null,
        true,
        false
      );
      if (res) {
        setSubscription(res?.data)
        console.log('res', res);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
    } finally {
      // Reset the message text regardless of the outcome
    }
  };

  useEffect(() => {
    listsubscription();
  }, []);

  const handleback = () => {
    setScreen(backscreen)
  }




  // const paymentHandler = async (e) => {
  //   const response = await fetch("http://localhost:5000/order", {
  //     method: "POST",
  //     body: JSON.stringify({
  //       amount,
  //       currency,
  //       receipt: receiptId,
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   const order = await response.json();
  //   console.log(order);

  //   var options = {
  //     key: "rzp_test_ghTeekIY3ZvfG3", // Enter the Key ID generated from the Dashboard
  //     amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
  //     currency,
  //     name: "IslamicTijarat", //your business name
  //     description: "Test Transaction",
  //     image: "https://example.com/your_logo",
  //     order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
  //     handler: async function (response) {
  //       const body = {
  //         ...response,
  //       };

  //       const validateRes = await fetch(
  //         "http://localhost:5000/order/validate",
  //         {
  //           method: "POST",
  //           body: JSON.stringify(body),
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       const jsonRes = await validateRes.json();
  //       console.log(jsonRes);
  //     },
  //     prefill: {
  //       //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
  //       name: "Web Dev Matrix", //your customer's name
  //       email: "webdevmatrix@example.com",
  //       contact: "9000000000", //Provide the customer's phone number for better conversion rates
  //     },
  //     notes: {
  //       address: "Razorpay Corporate Office",
  //     },
  //     theme: {
  //       color: "#3399cc",
  //     },
  //   };
  //   var rzp1 = new window.Razorpay(options);
  //   rzp1.on("payment.failed", function (response) {
  //     alert(response.error.code);
  //     alert(response.error.description);
  //     alert(response.error.source);
  //     alert(response.error.step);
  //     alert(response.error.reason);
  //     alert(response.error.metadata.order_id);
  //     alert(response.error.metadata.payment_id);
  //   });
  //   rzp1.open();
  //   e.preventDefault();
  // };

  return (
    <>
      <Navbar />
      <div className='container-fluid text-center align-items-center ' style={{ width: "90%" }}>
        <div className={`card p-4  ${theme ? 'color-dark-card' : "color-card"} align-items-center`} >
          {screen != "conformation" &&
            <div>
              <h3 style={{ color: theme ? "#fff" : '#000' }}>Choose your Subscription plan</h3>
              <div className='d-flex justify-content-center text-center'>
                <div className={`${theme ? 'toggle-buttons' : 'toggle-buttons-light'} mt-2 `} style={{ cursor: 'pointer' }}>
                  <div
                    className={`${screen === 'Quartely' ? theme ? 'selectedbtn' : 'selectedbtn-light' : theme ? 'notselected' : 'notselected-light'}`}
                    onClick={() => setScreen('Quartely')}
                  >
                    Quartely
                    <p className='downfont m-0 p-0'>Save upto 50%</p>
                  </div>
                  <div
                    className={`${screen === 'Yearly' ? theme ? 'selectedbtn' : 'selectedbtn-light' : theme ? 'notselected' : 'notselected-light'}`}

                    onClick={() => setScreen('Yearly')}
                  >
                    Yearly
                    <p className='downfont  m-0 p-0'>Save upto 50%</p>

                  </div>
                </div>
              </div>
            </div>
          }
          {screen == "Quartely" ?
            isLoading ? (
              <div className='row'>
                {/* Render 3 skeleton placeholders */}
                <div className='col-lg-4 col-sm-12'>
                  <Skeleton height={200} />
                </div>
                <div className='col-lg-4 col-sm-12'>
                  <Skeleton height={200} />
                </div>
                <div className='col-lg-4 col-sm-12'>
                  <Skeleton height={200} />
                </div>
              </div>
            ) : (
              <div>
                <div className='mx-auto w-100 d-flex flex-column mt-4 mb-4'>
                  <h4 className={`font-para mx-auto header-text ${theme ? "text-white" : "text-dark"}`}>Choose from the three plan options below</h4>
                </div>
                <div className='mt-3 text-center'>
                  <div className='mt-3 d-flex justify-content-center  flex-wrap text-center row'>

                    {subscription && subscription
                      .filter(item => item.subscription_info.price.includes("quarterly"))// Filter items where title is "Quartely"
                      .map((item, index) => (
                        <div className={`align-items-center col-lg-4 col-sm-12 ${theme ? 'plan-box' : 'plan-box-light'}`} style={{ width: isMobile ? '100%' : "30%", padding: "10px" }}>
                          {item.subscription_info.price.includes("quarterly") ? <p className='mostpopular'>Most Popular</p> : ""}
                          <h4 className={`mt-3 ${theme ? 'text-white' : "text-dark"}`}>{item.subscription_info.title}</h4>
                          <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-2`}>
                            <span style={{ textDecoration: 'line-through' }}>
                              ₹{item.subscription_info.price.replace(/\/quarterly$/, '')}
                            </span>
                            <span className='ms-1' style={{ color: '#7E66BC' }}>{item.subscription_info.offer_price}</span>/pm
                          </p>                       <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-2`}>Total Amt: ₹{item.subscription_info.offer_price * 6}</p>
                          <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-4 w-100`} >
                            <span className='w-100 p-1 text-white' style={{ backgroundColor: "gray", borderRadius: '3px' }}>Save 40%</span>
                          </p>
                          <div className='w-100 d-flex justify-content-center align-items-center mb-3 mt-4' style={{ cursor: 'pointer' }}>
                            <div className='subscrip-btn p-1 w-50 text-center' onClick={() => { handleSubscribe(item, 'Quartely') }}>Subscribe Now</div>
                          </div>

                          <p className={`mt-3 text-start ${theme ? 'subheadersize' : "subheadersize-light"}`}>Key Offerings :</p>
                          <div className='row mt-4'>
                            <div className='col-2'>
                              <img src='images/checklist.png' alt='img' height={20} width={20} /></div>
                            <div className='col-10 text-start'>
                              <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} ><span className='me-3'>Watchlist</span>{item.Preimum_data.watchlist}</p>
                            </div>
                          </div>
                          <div className='row mt-4'>
                            <div className='col-2'>
                              {item.Preimum_data.stock_screener == true ?
                                <img src='images/checklist.png' alt='img' height={20} width={20} />
                                : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                            <div className='col-10 text-start'>
                              <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >Stock Screener</p>
                            </div>
                          </div>
                          <div className='row mt-4'>
                            <div className='col-2'>
                              {item.Preimum_data.zakat_Calculator == true ?
                                <img src='images/checklist.png' alt='img' height={20} width={20} />
                                : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                            <div className='col-10 text-start'>
                              <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >Zakat Calculator</p>
                            </div>
                          </div>
                          <div className='row mt-4'>
                            <div className='col-2'>
                              {item.Preimum_data.ipo_screener == true ?
                                <img src='images/checklist.png' alt='img' height={20} width={20} />
                                : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                            <div className='col-10 text-start'>
                              <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >IPO Screener</p>
                            </div>
                          </div>
                          <div className='row mt-4'>
                            <div className='col-2'>
                              {item.Preimum_data.Purification_Calculator == true ?
                                <img src='images/checklist.png' alt='img' height={20} width={20} />
                                : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                            <div className='col-10 text-start'>
                              <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >Purification Calculator</p>
                            </div>
                          </div>

                          <div className='row mt-4'>
                            <div className='col-2'>
                              {item.Preimum_data.itg_shariah_rating == true ?
                                <img src='images/checklist.png' alt='img' height={20} width={20} />
                                : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                            <div className='col-10 text-start'>
                              <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >ITG Shariah Rating</p>
                            </div>
                          </div>
                          <div className='row mt-4'>
                            <div className='col-2'>
                              {item.Preimum_data.itg_shariah_rating == true ?
                                <img src='images/checklist.png' alt='img' height={20} width={20} />
                                : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                            <div className='col-10 text-start'>
                              <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >Portfolio Purification tracker - Coming Soon</p>
                            </div>
                          </div>


                        </div>

                      ))
                    }
                    <div className={`align-items-center col-lg-4 col-sm-12 ${theme ? 'plan-box' : 'plan-box-light'}`} style={{ width: isMobile ? '100%' : "30%", padding: "10px", position: 'relative' }}>
                      {/* Blurred content */}
                      <div style={{ filter: 'blur(5px)' }}>  {/* Adjust blur intensity */}
                        <h4 className={`mt-3 ${theme ? 'text-white' : "text-dark"}`}>Premium Plus</h4>
                        <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-2`}>
                          <span style={{ textDecoration: 'line-through' }}>₹00000</span>
                          <span className='ms-1' style={{ color: '#7E66BC' }}></span>/pm
                        </p>
                        <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-2`}>Total Amt: ₹</p>
                        <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-4 w-100`} >
                          <span className='w-100 p-1 text-white' style={{ backgroundColor: "gray", borderRadius: '3px' }}>Save 40%</span>
                        </p>
                        <div className='w-100 d-flex justify-content-center align-items-center mb-3 mt-4' style={{ cursor: 'pointer' }}>
                          <div className='subscrip-btn p-1 w-50 text-center'>Subscribe Now</div>
                        </div>

                        {/* The rest of your offerings */}
                        <p className={`mt-3 text-start ${theme ? 'subheadersize' : "subheadersize-light"}`}>Key Offerings :</p>
                        <div className='row mt-4'>
                          <div className='col-2'>
                            <img src='images/checklist.png' alt='img' height={20} width={20} />
                          </div>
                          <div className='col-10 text-start'>
                            <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`}><span className='me-3'>Watchlist</span></p>
                          </div>
                        </div>
                        {/* ... More offerings here ... */}
                      </div>

                      {/* Centered "Coming Soon" text */}
                      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, textAlign: 'center' }}>
                        <img src="/images/coming.png" width={120} />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            )
            : ""
          }
          {screen == "Yearly" &&
            <div>
              <div className='mx-auto w-100 d-flex flex-column mt-4 mb-4'>
                <h4 className={`font-para mx-auto header-text ${theme ? "text-white" : "text-dark"}`}>Choose from the three plan options below</h4>
              </div>
              <div className='mt-3 text-center'>
                <div className='mt-3 d-flex justify-content-center  flex-wrap text-center row'>
                  {subscription && subscription
                    .filter(item => item.subscription_info.price.includes("annually"))// Filter items where title is "Quartely"
                    .map((item, index) => (
                      <div className={`align-items-center col-lg-4 col-sm-12 ${theme ? 'plan-box' : 'plan-box-light'}`} style={{ width: isMobile ? '100%' : "30%", padding: "10px" }}>
                        {item.subscription_info.price.includes("annually") ? <p className='mostpopular'>Most Popular</p> : ""}
                        <h4 className={`mt-3 ${theme ? 'text-white' : "text-dark"}`}>{item.subscription_info.title}</h4>
                        <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-2`}>
                          <span style={{ textDecoration: 'line-through' }}>
                            ₹{item.subscription_info.price.replace(/\/annually$/, '')}
                          </span>
                          <span className='ms-1' style={{ color: '#7E66BC' }}>{item.subscription_info.offer_price}</span>/pm
                        </p>                       <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-2`}>Total Amt: ₹{item.subscription_info.offer_price * 12}</p>
                        <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-4 w-100`} >
                          <span className='w-100 p-1 text-white' style={{ backgroundColor: "gray", borderRadius: '3px' }}>Save 40%</span>
                        </p>
                        <div className='w-100 d-flex justify-content-center align-items-center mb-3 mt-4' style={{ cursor: 'pointer' }}>
                          <div className='subscrip-btn p-1 w-50 text-center' onClick={() => { handleSubscribe(item, 'Yearly') }}>Subscribe Now</div>
                        </div>
                        <p className={`mt-3 text-start ${theme ? 'subheadersize' : "subheadersize-light"}`}>Key Offerings :</p>
                        <div className='row mt-4'>
                          <div className='col-2'>
                            <img src='images/checklist.png' alt='img' height={20} width={20} /></div>
                          <div className='col-10 text-start'>
                            <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} ><span className='me-3'>Watchlist</span>{item.Preimum_data.watchlist}</p>
                          </div>
                        </div>
                        <div className='row mt-4'>
                          <div className='col-2'>
                            {item.Preimum_data.stock_screener == true ?
                              <img src='images/checklist.png' alt='img' height={20} width={20} />
                              : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                          <div className='col-10 text-start'>
                            <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >Stock Screener</p>
                          </div>
                        </div>
                        <div className='row mt-4'>
                          <div className='col-2'>
                            {item.Preimum_data.zakat_Calculator == true ?
                              <img src='images/checklist.png' alt='img' height={20} width={20} />
                              : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                          <div className='col-10 text-start'>
                            <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >Zakat Calculator</p>
                          </div>
                        </div>
                        <div className='row mt-4'>
                          <div className='col-2'>
                            {item.Preimum_data.ipo_screener == true ?
                              <img src='images/checklist.png' alt='img' height={20} width={20} />
                              : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                          <div className='col-10 text-start'>
                            <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >IPO Screener</p>
                          </div>
                        </div>
                        <div className='row mt-4'>
                          <div className='col-2'>
                            {item.Preimum_data.Purification_Calculator == true ?
                              <img src='images/checklist.png' alt='img' height={20} width={20} />
                              : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                          <div className='col-10 text-start'>
                            <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >Purification Calculator</p>
                          </div>
                        </div>

                        <div className='row mt-4'>
                          <div className='col-2'>
                            {item.Preimum_data.itg_shariah_rating == true ?
                              <img src='images/checklist.png' alt='img' height={20} width={20} />
                              : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                          <div className='col-10 text-start'>
                            <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >ITG Shariah Rating</p>
                          </div>
                        </div>
                        <div className='row mt-4'>
                          <div className='col-2'>
                            {item.Preimum_data.itg_shariah_rating == true ?
                              <img src='images/checklist.png' alt='img' height={20} width={20} />
                              : <img src='images/islamic/close.png' alt='img' height={20} width={20} />}</div>
                          <div className='col-10 text-start'>
                            <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`} >Portfolio Purification tracker - Coming Soon</p>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                  <div className={`align-items-center col-lg-4 col-sm-12 ${theme ? 'plan-box' : 'plan-box-light'}`} style={{ width: isMobile ? '100%' : "30%", padding: "10px", position: 'relative' }}>
                    {/* Blurred content */}
                    <div style={{ filter: 'blur(5px)' }}>  {/* Adjust blur intensity */}
                      <h4 className={`mt-3 ${theme ? 'text-white' : "text-dark"}`}>Premium Plus</h4>
                      <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-2`}>
                        <span style={{ textDecoration: 'line-through' }}>₹00000</span>
                        <span className='ms-1' style={{ color: '#7E66BC' }}></span>/pm
                      </p>
                      <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-2`}>Total Amt: ₹</p>
                      <p className={`${!theme ? 'font-para' : 'font-para-dark'} mt-4 w-100`} >
                        <span className='w-100 p-1 text-white' style={{ backgroundColor: "gray", borderRadius: '3px' }}>Save 40%</span>
                      </p>
                      <div className='w-100 d-flex justify-content-center align-items-center mb-3 mt-4' style={{ cursor: 'pointer' }}>
                        <div className='subscrip-btn p-1 w-50 text-center'>Subscribe Now</div>
                      </div>

                      {/* The rest of your offerings */}
                      <p className={`mt-3 text-start ${theme ? 'subheadersize' : "subheadersize-light"}`}>Key Offerings :</p>
                      <div className='row mt-4'>
                        <div className='col-2'>
                          <img src='images/checklist.png' alt='img' height={20} width={20} />
                        </div>
                        <div className='col-10 text-start'>
                          <p className={`${theme ? 'subheadersize' : "subheadersize-light"} mb-1`}><span className='me-3'>Watchlist</span></p>
                        </div>
                      </div>
                      {/* ... More offerings here ... */}
                    </div>

                    {/* Centered "Coming Soon" text */}
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999, textAlign: 'center' }}>
                      <img src="/images/coming.png" width={120} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {screen == "conformation" &&
            <div className="container mt-3" style={{ maxWidth: "100%", fontFamily: "Arial, sans-serif" }}>
              <div className="d-flex justify-content-start align-items-center mb-4">
                <button
                  className="btn btn-outline-secondary me-2"
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    fontSize: "16px",
                    border: "1px solid #7E66BC",
                    backgroundColor: "white",
                    color: "#7E66BC",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"
                  }}
                  onClick={() => { handleback() }} // Back button functionality
                >
                  <i class="fa fa-arrow-left me-2" aria-hidden="true"></i>
                  Back
                </button>
              </div>
              <h4 className="text-start mb-4">Confirm Details</h4>
              <h6 className="text-start text-secondary mb-3">Combo{backscreen}</h6>
              <div className="pricing-box p-4 shadow-sm" style={{ borderRadius: "12px", backgroundColor: "#f9f9f9" }}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p className="mb-0 text-dark">Plan Price</p>
                  <p className="mb-0 text-dark">₹{Price}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p className="mb-0 text-dark">Discount @40%</p>
                  <p className="mb-0" style={{ color: "green" }}>
                    - ₹{discountPrice}
                  </p>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p className="mb-0 text-dark">Gross</p>
                  <p className="mb-0 text-dark">
                    ₹{offerPrice}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p className="mb-0 text-dark">GST (18%)</p>
                  <p className="mb-0 text-dark">+ ₹{Gst}</p>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p className="mb-0 text-dark">Total Pay</p>
                  <p className="mb-0 text-dark">₹{totalAmount}</p>
                </div>
              </div>

              <div className="mt-4 mb-4">
                <h6 className="text-start text-secondary mb-3">Apply Coupon</h6>
                <div className="input-group mb-3" style={{ maxWidth: "400px", margin: "0 auto" }}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Coupon Code"
                    style={{ borderRadius: "8px 0 0 8px", borderColor: "#ced4da" }}
                  />
                  <button className="btn text-white" style={{ borderRadius: "0 8px 8px 0", backgroundColor: '#7E66BC' }}>Apply</button>
                </div>
              </div>

              <div className="mt-5 text-start">
                <h6 className="text-start text-secondary mb-3">FAQS</h6>

                <div className="accordion accordion-flush" id="accordionFlushExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                      <button
                        className="accordion-button collapsed text-dark"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                        style={{ borderColor: "#ced4da" }}
                      >
                        What types of payment do you accept?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body text-secondary">
                        We accept various payment methods including Credit/Debit cards, UPI, and Net Banking.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                      <button
                        className="accordion-button collapsed text-dark"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseTwo"
                        aria-expanded="false"
                        aria-controls="flush-collapseTwo"
                        style={{ borderColor: "#ced4da" }}
                      >
                        Can I transfer the subscription to someone else?
                      </button>
                    </h2>
                    <div
                      id="flush-collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingTwo"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body text-secondary">
                        Subscription transfer is not allowed. It is tied to the account holder.
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 text-start d-flex align-items-center">
                <input type="checkbox" id="terms" className="me-2" />
                <label htmlFor="terms" className="text-dark">
                  I accept Terms & Conditions
                </label>
              </div>

              <div className="mt-4 d-flex justify-content-center">
                <button
                  className="btn btn-primary w-100"
                  style={{
                    maxWidth: "400px",
                    padding: "12px 20px",
                    borderRadius: "8px",
                    fontSize: "16px",
                    backgroundColor: "#7E66BC",
                    border: "none",
                    fontWeight: "bold",
                    color: "#fff",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                  }}
                >
                  Proceed to Pay ₹{totalAmount}.00
                </button>
              </div>
            </div>

          }
        </div>

      </div>
      <Footer />
    </>
  )
}

export default Plan